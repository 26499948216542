import React, { useState, useContext } from 'react'
import { Text, Box, Radio, Stack, Button, FormControl, RadioGroup } from '@chakra-ui/react'
import { AuthContext } from 'src/utils/auth'
import { CareerSwipeContext } from 'src/utils/providers/useCareerSwipe'

const missionNumber = 2

export default function One() {
  const { user } = useContext(AuthContext)
  const { setCareerSwipeStep, setError, submitCheckpoint } = useContext(CareerSwipeContext)
  const [answer, setAnswer] = useState('')
  const question = 'Were there any new job titles you learned of?'

  const handleChange = (value: any) => {
    setAnswer(value)
  }

  const submitAnswer = (e: any) => {
    e.preventDefault()
    setError('')
    if (!answer) {
      setError('Please select an answer below.')
    } else if (answer === 'Yes') {
      setCareerSwipeStep(5)
    } else {
      setCareerSwipeStep(6)
    }
    submitCheckpoint({
      variables: {
        missionNumber: missionNumber,
        question: question,
        answer: answer,
        responseId: user?.responseId,
      },
    })
  }

  return (
    <>
      <Text
        textTransform={'none'}
        fontFamily={'sans-serif'}
        color="white"
        fontSize={'2xl'}
        fontWeight="700"
        mt={'30px'}
      >
        {question}
      </Text>

      <Box mt={'30px'} h={'425px'}>
        <form onSubmit={submitAnswer}>
          <FormControl as="fieldset">
            <RadioGroup onChange={(val) => handleChange(val)}>
              <Stack>
                <Radio size="lg" value="Yes" colorScheme="blue">
                  Yes
                </Radio>
                <Radio size="lg" value="No" colorScheme="blue">
                  No
                </Radio>
              </Stack>
            </RadioGroup>

            <Button
              disabled={answer ? false : true}
              mt={'50px'}
              variant="solid"
              w={'111px'}
              bg={answer ? 'brand.blue' : 'brand.neutral'}
              color={'white'}
              title={'Next'}
              type="submit"
              onSubmit={(e) => submitAnswer(e)}
            >
              Next
            </Button>
          </FormControl>
        </form>
      </Box>
    </>
  )
}
