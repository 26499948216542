import { forwardRef, Image, ImageProps, Link } from '@chakra-ui/react'
import * as React from 'react'
import basta_logo_white from 'src/assets/images/basta_logo_white.png'

const Logo = forwardRef<ImageProps, 'img'>((props, ref) => {
  return (
    <Link href="/dashboard">
      <Image mt={25} ml={5} w="135px" {...props} src={basta_logo_white} />
    </Link>
  )
})

export default Logo
