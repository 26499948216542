import { useMutation } from '@apollo/client'
import { Box, Button, Flex, HStack, Text, VStack, Link, Image } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState, FC, useContext, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Loading, Header, ExitSurveyModal } from 'src/components'
import { useGetEmailFromParams } from 'src/components/Survey/hooks'
import Dropdown from 'src/components/Survey/ui/Dropdown'
import Autocomplete from 'src/components/Survey/ui/Autocomplete'
import { SurveyListSingleOption } from 'src/components/Survey/ui/SurveyListOptionInput'
import TextInput from 'src/components/Survey/ui/TextInput'
import { SurveyListMultipleOption } from '../../components/Survey/ui/SurveyListOptionInput'
import { SurveyQuestion, SurveyQuestionOption, useGetSurvey } from '../../utils/hooks/useSurvey'
import {
  RETRIEVE_PREVIOUS_QUESTION,
  SUBMIT_RESPONSE_AND_NEXT_QUESTION,
} from '../../utils/mutations'

import { SurveyContext } from 'src/utils/providers/useSurvey'


export const SeekrSurvey: FC<{ isAdmin: boolean; surveyId: string; cursor?: any }> = ({
  isAdmin,
  surveyId,
  cursor,
}) => {
  //TODO: will use this to pass it to the retrieve current survey status so that we can check what the current position of the survey is.

  const {
    currentQuestion,
    setCurrentQuestion,
    responseValue,
    setResponseValue,
    responseValueArray,
    setResponseValueArray,
    currentSurveySection,
    setCurrentSurveySection,
    handleIncomingResponse,
    progress,
    setProgress,
    singleAnswer,
    setSingleAnswer,
    getComponent

  } = useContext(SurveyContext)

  const email = useGetEmailFromParams()
  const [globalFirst, setGlobalFirst] = useState<SurveyQuestion | null>(null)
  const [globalLast, setGlobalLast] = useState<SurveyQuestion | null>(null)
  const [sectionId, setSectionId] = useState<string | null>(null)
  const [validationError, setValidationError] = useState('')
  const [showExitSurveyModal, setShowExitSurveyModal] = useState(false)
 
  const location = useLocation()

  const preProcessedResponseValue = useMemo(() => {
    const isMultiple = currentQuestion?.questionType === 'MULTIPLE_OPTION'
    if (isMultiple) {
      return responseValueArray
    }
    return responseValue
  }, [currentQuestion?.questionType, responseValue, responseValueArray])

  const { loading: loadingMainSurvey, surveySection: mainSurvey } = useGetSurvey(surveyId)
  const { loading, surveySection } = useGetSurvey(sectionId ?? undefined)
  const navigate = useNavigate()

  //EFFECTS
  useEffect(() => {
    if (!isAdmin && mainSurvey && !sectionId) {
      const found = mainSurvey?.seekrSurveyRetrieve?.sections?.find((s) => s.order === 1)
      found && setSectionId(found.id)
      return
    }
    if (mainSurvey && sectionId && surveySection) {
      setCurrentSurveySection(surveySection)
    }
  }, [currentQuestion, isAdmin, mainSurvey, sectionId, surveySection])

  useEffect(() => {
    if (mainSurvey) {
      setGlobalFirst(mainSurvey?.seekrSurveyRetrieve?.firstQuestion)
    }
  }, [mainSurvey])

  useEffect(() => {
    if (mainSurvey) {
      setGlobalLast(mainSurvey?.seekrSurveyRetrieve?.lastQuestion)
    }
  }, [mainSurvey])

  useEffect(() => {
    if (cursor?.currentSection || cursor?.currentQuestion) {
      setCurrentSurveySection(cursor.currentSection)
      setCurrentQuestion(cursor.currentQuestion)
      setProgress(cursor.progress)
      handleIncomingResponse({
        questionResponse: cursor?.currentResponse,
        question: cursor.currentQuestion,
      })
    }
  }, [cursor])

  const [submitSurveyResponseAndNext, { loading: nextQuestionLoading }] = useMutation(
    SUBMIT_RESPONSE_AND_NEXT_QUESTION,
    {
      variables: {
        email: email?.toLowerCase(),
        questionId: currentQuestion?.id,
        responseValue: JSON.stringify(preProcessedResponseValue),
      },
      onCompleted: (data) => {
        if (data.submitSurveyResponseAndNext.errors) {
          setValidationError(data.submitSurveyResponseAndNext.errors.join(', '))
          return
        }
        if (data.submitSurveyResponseAndNext.nextQuestion) {
          handleIncomingResponse({
            questionResponse: data.submitSurveyResponseAndNext.nextResponse,
            question: data.submitSurveyResponseAndNext.nextQuestion,
          })
          setProgress(data.submitSurveyResponseAndNext.progress)
          setCurrentQuestion(data.submitSurveyResponseAndNext.nextQuestion)
        }
      },
      onError: (error) => {
        console.error('submitSurveyResponseAndNext', error)
      },
    },
  )

  const [retrievePreviousQuestion, { loading: prevQuestionLoading }] = useMutation(
    RETRIEVE_PREVIOUS_QUESTION,
    {
      variables: {
        email: email?.toLowerCase(),
        questionId: currentQuestion?.id,
        responseValue: responseValue,
      },
      onCompleted: (data: any) => {
        setCurrentQuestion(data.retrievePreviousQuestion.previousQuestion)
        setProgress(data.retrievePreviousQuestion.progress)
        handleIncomingResponse({
          questionResponse: data.retrievePreviousQuestion.previousResponse,
          question: data.retrievePreviousQuestion.previousQuestion,
        })
      },
      onError: (error) => {
        console.error('retrievePreviousQuestion', error)
      },
    },
  )

  const disableForm = useMemo(() => {
    return nextQuestionLoading || prevQuestionLoading
  }, [nextQuestionLoading, prevQuestionLoading])

  const onSubmit = async () => {
    setValidationError('')
    submitSurveyResponseAndNext()
  }

  useEffect(() => {
    if (singleAnswer) {
      setValidationError('')
      submitSurveyResponseAndNext({
        variables: {
          email: email?.toLowerCase(),
          questionId: currentQuestion?.id,
          responseValue: JSON.stringify(responseValue),
        },
      })
    }
    setSingleAnswer(false)
  }, [currentQuestion?.id, email, responseValue, singleAnswer, submitSurveyResponseAndNext])

  const onFinish = async () => {
    submitSurveyResponseAndNext()
    setCurrentQuestion(null)
    setSectionId(null)
    setCurrentSurveySection(null)
    setProgress(0)
    navigate('/survey/complete')
  }
  const clearSelections = () => {
    setCurrentQuestion(null)
    setCurrentSurveySection(null)
    setSectionId(null)
    setResponseValue('')
    setResponseValueArray([])
    setProgress(0)
  }

  const onBack = async () => {
    setValidationError('')
    retrievePreviousQuestion()
  }

  const orderedOptions = useCallback((): SurveyQuestionOption[] => {
    if (currentQuestion?.options) {
      return [...currentQuestion.options].sort(
        (a: SurveyQuestionOption, b: SurveyQuestionOption) => a.order - b.order,
      )
    }
    return []
  }, [currentQuestion])

  if (loadingMainSurvey || loading || !mainSurvey) {
    return <Loading />
  }

  return (
    <VStack as="main" px={3} alignItems="center">
      <Header />
      <Flex direction="column" w="full" maxW="4xl" h="100%" px={10} gap={4}>
        {currentSurveySection && currentQuestion ? (
          <>
            {isAdmin ? (
              <Button
                onClick={() => {
                  clearSelections()
                }}
              >
                Close
              </Button>
            ) : null}
            <Box w="full">
              <HStack w="full" justifyContent="center">
                {Array.from({ length: mainSurvey?.seekrSurveyRetrieve?.sections?.length ?? 0 }).map(
                  (_, i) => {
                    return (
                      <span
                        key={i}
                        style={{
                          backgroundColor: progress && i + 1 == progress * 10 ? 'white' : 'gray',
                          height: '10px',
                          width: '10px',
                        }}
                      ></span>
                    )
                  },
                )}
              </HStack>
            </Box>
            <Text
              data-testid="current-question-content"
              dangerouslySetInnerHTML={{ __html: currentQuestion?.question ?? '' }}
            ></Text>
            {currentQuestion ? (
              <VStack w="full" justifyContent="center" style={{ display: 'block' }}>
                <div style={{ position: 'relative' }}>
                  {disableForm && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(26, 32, 44, 0.5)',
                        zIndex: 10,
                      }}
                    >
                      <Loading data-testid="loading-spinner" />
                    </div>
                  )}
                  {getComponent({
                    questionType: currentQuestion?.questionType,
                    questionDisplay: currentQuestion?.questionDisplay,
                    key: currentQuestion?.exportKey,
                    options: orderedOptions(),
                  })}
                </div>
              </VStack>
            ) : null}

            <HStack w="full" justifyContent="center" h="8" data-testid="survey-validation-errors">
              {validationError ? (
                <Text color="red.400" fontWeight="bold">
                  {validationError}
                </Text>
              ) : null}
            </HStack>

            <HStack w="full" justifyContent="space-between">
              {!(globalFirst?.id == currentQuestion?.id) ? (
                <Button isLoading={disableForm} disabled={disableForm} onClick={onBack}>
                  Back
                </Button>
              ) : (
                <div></div>
              )}
              {globalLast?.id == currentQuestion?.id ? (
                <Button isLoading={disableForm} disabled={disableForm} onClick={onFinish}>
                  Finish
                </Button>
              ) : (
                <Button
                  data-testid="next-button"
                  isLoading={disableForm}
                  disabled={disableForm}
                  onClick={onSubmit}
                >
                  Next
                </Button>
              )}
            </HStack>
          </>
        ) : isAdmin ? (
          <VStack>
            <VStack>
              <Text>Select A survey</Text>

              <Dropdown
                placeholder="Select survey"
                onChange={(e) => setSectionId(e.target.value)}
                value={currentSurveySection ?? ''}
                options={
                  mainSurvey?.seekrSurveyRetrieve?.sections?.map((o) => ({
                    display: o.title,
                    value: o.id,
                  })) ?? []
                }
              />
            </VStack>
          </VStack>
        ) : null}
      </Flex>
    </VStack>
  )
}
