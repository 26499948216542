import React, { useState } from 'react'
import { Heading, Textarea, Button, VStack, Text, Spinner } from '@chakra-ui/react'
import { PARSE_JOB_DESCRIPTION } from 'src/utils/mutations'
import { useMutation } from '@apollo/client'
import Error from 'src/components/Alerts/Error'

export default function MatchScore() {
  const [jobDescription, setJobDescription] = useState('')
  const [jobFunction, setJobFunction] = useState('')
  const [jobIndustry, setJobIndustry] = useState('')
  const [error, setError] = useState<string | null>(null)

  const [jobParseMutation, { loading }] = useMutation(PARSE_JOB_DESCRIPTION, {
    onCompleted: (data) => {
      setJobFunction(data.jobParse.jobFunction)
      setJobIndustry(data.jobParse.jobIndustry)
      setError(null)
    },
    onError: (error) => {
      setError(error.message)
      setJobFunction('')
      setJobIndustry('')
      // setTimeout(() => setError(null), 7000)
    },
  })
  let handleInputChange = (e: any) => {
    let inputValue = e.target.value
    setJobDescription(inputValue)
  }
  const sendJobParseMutation = (jobDescription: string) => {
    jobParseMutation({
      variables: {
        jobDescription,
      },
    })
  }

  return (
    <VStack mx={20} spacing={5} mt={20}>
      {error && <Error errorMessage={error} setError={setError} />}

      <Heading>Enter Job Description</Heading>
      <Textarea
        value={jobDescription}
        onChange={handleInputChange}
        placeholder="Job description"
        size="sm"
      />
      {loading ? (
        <Spinner />
      ) : (
        <Button onClick={() => sendJobParseMutation(jobDescription)}>Submit</Button>
      )}
      <Text>Job Function:</Text>
      <Text>{jobFunction}</Text>
      <Text>Job Industry:</Text>
      <Text>{jobIndustry}</Text>
    </VStack>
  )
}
