import React from 'react'
import { RSVPProvider } from 'src/utils/providers/useWelcome'
import { AuthProvider } from 'src/utils/auth'
import { CareerSwipeProvider } from './useCareerSwipe'
import { SurveyProvider } from './useSurvey'
import { MatchScoreProvider } from './useMatchScore'

export default function Providers({ routes }: any) {
  return (
    <SurveyProvider>
      <MatchScoreProvider>
        <AuthProvider>
          <RSVPProvider>
            <CareerSwipeProvider>{routes}</CareerSwipeProvider>
          </RSVPProvider>
        </AuthProvider>
      </MatchScoreProvider>
    </SurveyProvider>
  )
}
