import React from 'react'
import {
  Text,
  Box,
  Flex,
  Image,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Button,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import alignmentCircle from 'src/assets/images/alignment_circle.svg'
import { Data } from 'src/utils/types'
import salaryRanges from 'src/assets/images/salary_ranges.svg'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'

export default function Preferences({ reportData }: Data) {
  const navigate = useNavigate()
  let { responseId } = useParams()

  function goToNextSteps() {
    navigate(`/view-report/${responseId}/next-steps`)
    window.scrollTo(0, 0)
  }

  function convertEnergyScore(energyScore: string | undefined) {
    switch (energyScore) {
      case '0':
      case '1':
      case '2':
        return 10
      case '3':
      case '4':
        return 30
      case '5':
      case '6':
        return 50
      case '7':
      case '8':
        return 70
      case '9':
      case '10':
        return 90
      default:
        return 50
    }
  }

  function convertPredictabilityScore(predictabiltiyScore: string | undefined) {
    switch (predictabiltiyScore) {
      case '1':
        return 10
      case '2':
        return 30
      case '3':
        return 50
      case '4':
        return 70
      case '5':
        return 90
      default:
        return 50
    }
  }

  return (
    <Box>
      <Flex>
        <Text fontSize={20} fontWeight={'bold'} pr={2}>
          Energy Style, Structure, and Salary
        </Text>

        <Image w="30px" src={alignmentCircle} alt={'Alignment circle icon'} />
      </Flex>
      <Text my={5}>
        Your <span style={{ fontWeight: 'bold' }}>Energy Style</span>
      </Text>
      <Text>{reportData?.A8}</Text>

      <Grid gridTemplateColumns={{ base: '20% 60% 20%', xl: '25% 50% 25%' }} mt={5}>
        <GridItem colStart={1} colEnd={2}>
          <Text
            alignSelf={'center'}
            fontSize={{ base: 10, xl: 16, lg: 14 }}
            fontWeight="bold"
            ml={2}
          >
            WORKING ALONE
          </Text>
        </GridItem>
        <GridItem colStart={2} colEnd={3}>
          <Slider isDisabled={true} pointerEvents="none" _disabled={{ opacity: '1' }}>
            <SliderMark
              top={{ base: '24px', xl: '40px', lg: '32px' }}
              fontSize={{ base: 12, xl: 'lg', lg: 'lg' }}
              fontWeight="bold"
              value={convertEnergyScore(reportData?.EnergyScore)}
            >
              YOU
            </SliderMark>
            <SliderTrack bg="#BDBDBD" height={{ base: 1, xl: 2, lg: 2 }}>
              <SliderFilledTrack bg="#BDBDBD" />
            </SliderTrack>
            <SliderThumb
              bg="brand.200"
              _disabled={{ bg: 'brand.200' }}
              boxSize={{ base: 5, xl: 8, lg: 8 }}
              left={convertEnergyScore(reportData?.EnergyScore) + '% !important'}
            />
          </Slider>
        </GridItem>
        <GridItem colStart={3} colEnd={4}>
          <Text fontSize={{ base: 10, xl: 16, lg: 14 }} fontWeight="bold" textAlign={'right'}>
            WORKING <br /> WITH OTHERS
          </Text>
        </GridItem>
      </Grid>
      <Text my={5}>
        Your Preference for <span style={{ fontWeight: 'bold' }}>Predictability</span>
      </Text>
      <Text my={5}>{reportData?.A11}</Text>
      <Grid gridTemplateColumns={{ base: '20% 60% 20%', xl: '25% 50% 25%' }}>
        <GridItem colStart={1} colEnd={2}>
          {' '}
          <Text alignSelf={'center'} fontSize={{ base: 10, xl: 16, lg: 14 }} fontWeight="bold">
            FLEXIBLE, AMBIGUOUS
          </Text>
        </GridItem>
        <GridItem colStart={2} colEnd={3}>
          <Slider isDisabled={true} pointerEvents="none" _disabled={{ opacity: '1' }}>
            <SliderMark
              top={{ base: '24px', xl: '40px', lg: '32px' }}
              fontSize={{ base: 12, xl: 'lg', lg: 'lg' }}
              fontWeight="bold"
              value={convertPredictabilityScore(reportData?.PredictabilityScore)}
            >
              YOU
            </SliderMark>
            <SliderTrack bg="#BDBDBD" height={{ base: 1, xl: 2, lg: 2 }}>
              <SliderFilledTrack bg="#BDBDBD" />
            </SliderTrack>
            <SliderThumb
              bg="brand.200"
              _disabled={{ bg: 'brand.200' }}
              boxSize={{ base: 5, xl: 8, lg: 8 }}
              left={convertPredictabilityScore(reportData?.PredictabilityScore) + '% !important'}
            />
          </Slider>
        </GridItem>
        <GridItem colStart={3} colEnd={4}>
          <Text
            fontSize={{ base: 10, xl: 16, lg: 14 }}
            fontWeight="bold"
            textAlign={'right'}
            mr={-1}
          >
            PREDICTABLE, STRUCTURED
          </Text>
        </GridItem>
      </Grid>

      <Text my={5}>
        Your <span style={{ fontWeight: 'bold' }}>Salary Expectations</span>
      </Text>
      <Text mb={5}>{reportData?.A14}</Text>
      <Image mx="auto" src={salaryRanges} alt={'Salary expectations ranges'} />
      <Button
        mt={5}
        w={'100%'}
        fontFamily={'Intro'}
        bgColor="brand.200"
        size={'sm'}
        textAlign="center"
        onClick={() => goToNextSteps()}
      >
        GO TO NEXT STEPS
      </Button>
    </Box>
  )
}
