import React from 'react'
import { Icon, Text, Stack } from '@chakra-ui/react'
import { RiComputerLine } from 'react-icons/ri'

export default function SeekrSurveyLogo() {
  return (
    <Stack direction={'column'} align={'center'}>
      <Icon
        as={RiComputerLine}
        background={'white'}
        borderRadius={'50%'}
        color={'brand.navy'}
        width={'30px'}
        height={'30px'}
        p={'10px'}
      />
      <Text
        textTransform={'none'}
        fontFamily={'sans-serif'}
        color="white"
        fontSize={'3xl'}
        fontWeight="700"
      >
        Seekr Survey
      </Text>
    </Stack>
  )
}
