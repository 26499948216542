import React, { useState, useContext } from 'react'
import { Box, Heading, Flex, Image } from '@chakra-ui/react'
import diagnalDownArrow from 'src/assets/images/diagnal_down_arrow.svg'
import { useNavigate } from 'react-router-dom'
import { ReportData } from 'src/utils/types'
import { AuthContext, logout } from 'src/utils/auth'

export default function NavBar(props: {
  responseId: string | undefined
  page: string
  reportData: ReportData | undefined
}) {
  const navigate = useNavigate()
  const { updateToken, token } = useContext(AuthContext)
  const [currentPage, setCurrentPage] = useState(props.page)

  function clickAboutYou() {
    setCurrentPage('about you')
    navigate(`/view-report/${props.responseId}`)
  }

  function clickNextSteps() {
    setCurrentPage('next steps')
    navigate(`/view-report/${props.responseId}/next-steps`)
  }

  function clickSocialCapital() {
    setCurrentPage('social capital')
    navigate(`/view-report/${props.responseId}/social-capital`)
  }

  function clickExplore() {
    setCurrentPage('explore')
    navigate(`/view-report/${props.responseId}/explore`)
  }

  const logUserOut = () => {
    logout()
    updateToken(null)
    navigate('/log-in')
  }

  return (
    <Box
      border={'none'}
      bg={'rgba(0, 0, 0, 0.24)'}
      px={5}
      py={3}
      backdropFilter={'blur(15px)'}
      boxShadow={'0px 4px 4px rgba(28, 28, 0.13)'}
      mt={5}
    >
      <Flex
        cursor={'pointer'}
        aria-label={'Go to next steps section'}
        onClick={() => clickAboutYou()}
        justifyContent={'space-between'}
      >
        <Heading
          letterSpacing={'2px'}
          fontFamily={'Intro'}
          fontSize={12}
          color={currentPage === 'about you' ? 'white' : '#BBBBBB'}
          textTransform={'uppercase'}
        >
          About You
        </Heading>
        <Image w="17px" pl={2} src={diagnalDownArrow} alt={'Diagonal down arrow'} />
      </Flex>
      <Flex
        my={5}
        cursor={'pointer'}
        aria-label={'Go to next steps section'}
        onClick={() => clickNextSteps()}
        justifyContent={'space-between'}
      >
        <Heading
          letterSpacing={'2px'}
          fontFamily={'Intro'}
          fontSize={12}
          color={currentPage === 'next steps' ? 'white' : '#BBBBBB'}
          textTransform={'uppercase'}
        >
          Next Steps
        </Heading>
        <Image w="17px" pl={2} src={diagnalDownArrow} alt={'Diagonal down arrow'} />
      </Flex>
      {props.reportData?.InstanceName === 'SP23 Fellowship' ||
      props.reportData?.InstanceName === 'Winter23 Fellowship Applicants' ? (
        <></>
      ) : (
        <Flex
          my={5}
          cursor={'pointer'}
          aria-label={'Go to social capital section'}
          onClick={() => clickSocialCapital()}
          justifyContent={'space-between'}
        >
          <Heading
            letterSpacing={'2px'}
            fontFamily={'Intro'}
            fontSize={12}
            color={currentPage === 'social capital' ? 'white' : '#BBBBBB'}
            textTransform={'uppercase'}
          >
            Social Capital
          </Heading>
          <Image w="17px" pl={2} src={diagnalDownArrow} alt={'Diagonal down arrow'} />
        </Flex>
      )}

      {token && (
        <Flex
          my={5}
          cursor={'pointer'}
          aria-label={'Logout'}
          onClick={() => logUserOut()}
          justifyContent={'space-between'}
        >
          <Heading
            letterSpacing={'2px'}
            fontFamily={'Intro'}
            fontSize={12}
            color={currentPage === 'logout' ? 'white' : '#BBBBBB'}
            textTransform={'uppercase'}
          >
            Logout
          </Heading>
          <Image w="17px" pl={2} src={diagnalDownArrow} alt={'Diagonal down arrow'} />
        </Flex>
      )}
    </Box>
  )
}
