import React, { useContext, useState } from 'react'
import DataWarehouseExport from 'src/components/DataWarehouse'
import { Survey } from './Surveys'
import { Center, Flex } from '@chakra-ui/react'
import { Loading } from 'src/components'
import { GET_SURVEYS } from 'src/utils/queries'
import { useQuery } from '@apollo/client'
import Error from 'src/components/Alerts/Error'
import Success from 'src/components/Alerts/Success'
import { MatchScoreContext } from 'src/utils/providers/useMatchScore'

export default function DataWarehouse() {
  const [surveys, setSurveys] = useState<Survey[]>([])
  const [error, setError] = useState<string | null>(null)
  const { success, setSuccess } = useContext(MatchScoreContext)

  const { loading } = useQuery(GET_SURVEYS, {
    onCompleted: (data) => {
      setSurveys(data.qualtricsData.surveys)
    },
    onError: (error) => {
      setError(error.message)
    },
  })

  if (loading) {
    return (
      <Center mt={100}>
        <Loading />
      </Center>
    )
  }
  return (
    <>
      <Center mt={100} ml={5}>
        <Flex direction="column">
          {error && <Error errorMessage={error} setError={setError} />}
          {success && <Success />}
          <DataWarehouseExport surveys={surveys} setSuccess={setSuccess} setError={setError} />
        </Flex>
      </Center>
    </>
  )
}
