import { useQuery } from '@tanstack/react-query'
import { jobActivityApi } from './api'

interface JobActivitiesProps {
  interested?: boolean
  dateApplied__isnull?: boolean
  job__active?: boolean
}

export const useListJobActivities = ({
  interested,
  dateApplied__isnull,
  job__active,
}: JobActivitiesProps) => {
  const { data } = useQuery({
    queryKey: ['jobActivity'],
    queryFn: async () => {
      return await jobActivityApi.list({
        filters: { interested, dateApplied__isnull, job__active },
      })
    },
    enabled: true,
  })
  return { data }
}
