import React, { useState } from 'react'
import { Box, Grid, GridItem } from '@chakra-ui/react'
import { Layout, Intro, SocialCapitalContent, NavBar, MiniDashboard } from 'src/components'
import { ReportData } from 'src/utils/types'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import { GET_NEXT_STEPS_DATA } from 'src/utils/queries'

export default function SocialCapital() {
  let { responseId } = useParams()
  const [reportData, setReportData] = useState<ReportData>()

  const { loading } = useQuery(GET_NEXT_STEPS_DATA, {
    variables: { responseId: responseId },
    onCompleted: (data: any) => {
      setReportData(data.reportData)
    },
    onError: (error: any) => {
      console.error(error)
    },
  })
  return (
    <Layout reportData={reportData}>
      <Box m="auto" position="relative" zIndex={3}>
        <Intro />
        <Grid
          templateColumns={{ base: '1fr', md: '1fr 3fr 1fr' }}
          templateRows={{ base: 'auto auto 1fr', md: '1fr' }}
          columnGap={'10px'}
        >
          <GridItem rowStart={1} rowEnd={2} ml={2}>
            <NavBar reportData={reportData} responseId={responseId} page="social capital" />
          </GridItem>
          <GridItem colStart={{ base: 1, md: 2 }} colEnd={3} rowStart={{ base: 2, md: 1 }} mx={2}>
            <SocialCapitalContent reportData={reportData} />
          </GridItem>
          <GridItem rowStart={{ base: 0, md: 1 }} rowEnd={1} ml={2}>
            <MiniDashboard reportData={reportData} />
          </GridItem>
        </Grid>
      </Box>
    </Layout>
  )
}
