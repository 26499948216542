import { Flex, Button, Text, Center, Container, Link } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { CareerSwipeContext } from 'src/utils/providers/useCareerSwipe'

export default function Instructions() {
  const { setCareerSwipeStep } = useContext(CareerSwipeContext)
  return (
    <Center textAlign={'center'} maxW={'525px'}>
      <Flex direction={'column'} justify="center" align="center">
        <Container>
          <Text>
            Welcome to Career Swipe. Compare jobs against one another to expand your knowledge of
            new industries. Play to unlock new job titles and discover more career paths.
          </Text>
        </Container>
        <Button
          variant="solid"
          bg={'brand.blue'}
          color={'white'}
          title={'RSVP'}
          mt={'30px'}
          px={'25px'}
          onClick={() => setCareerSwipeStep(2)}
        >
          Start Mission
        </Button>
        <Link href="/dashboard">
          <Text color={'white'} mt={'10px'} fontSize={'sm'}>
            Back
          </Text>
        </Link>
      </Flex>
    </Center>
  )
}
