import { useQuery } from '@tanstack/react-query'
import { industryApi } from './api'

export const useListIndustries = () => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['industries'],
    queryFn: async () => {
      return await industryApi.list()
    },
    enabled: true,
  })

  return { data, refetch, isLoading }
}
