import React, { useContext } from 'react'
import { Alert, AlertIcon, Text, CloseButton } from '@chakra-ui/react'
import { MatchScoreContext } from 'src/utils/providers/useMatchScore'

export default function Success() {
  const { setSuccess } = useContext(MatchScoreContext)
  return (
    <Alert position="fixed" status="success">
      <AlertIcon />
      <Text>Data successfully sent!</Text>
      <CloseButton
        alignSelf="flex-end"
        position="relative"
        right={-1}
        top={-1}
        onClick={() => setSuccess(false)}
      />
    </Alert>
  )
}
