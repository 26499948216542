export const alignmentPreferencesContent = {
  alignmentPreferencesSummary:
    "Alignment includes preferences - these are the factors outside of your interest in the work that will impact your happiness, satisfaction, and success in the role. Read on for what 'preferences' most commonly impact job satisfaction.",
  driversSummary: ` Drivers are the values, outcomes, or rewards that you seek through
  your work. The drivers are ranked below in the order in which you
  prioritize them in the assessment. Your results suggest that the
  following is/are the most important motivator(s) for you:`,
  creativityDescription: 'Motivated by innovation and creating original work.',
  expertiseDescription: 'Motivated by seeking a high level of accomplishment in specialized field.',
  incomeDescription: 'Motivated by seeking a high income to support self and others.',
  relationshipDescription: 'Motivated by nourishing relationships at work.',
  meaningDescription: 'Motivated by higher meaning, things valuable for their own sake.',
  statusDescription: 'Motivated by recognition, admiration and respect from your work.',
  //to do: all asides can be deleted
  aside1: {
    header: 'Motivators, not aptitudes',
    body: ' Your driver does not mean you are “bad” or  “good” at it. Instead your score indicates how much that driver motivates you to work. For example, if you score “low” on income, it doesn’t mean you don’t care about income, only that you consistently prioritize other motivators ABOVE income.',
  },
  driverPopover1: {
    header: "Doesn't seem right?",
    body: ' If your highest scoring driver doesn’t “feel right”, it’s worth considering whether others’ opinions (or what you think your drivers “should be”) are influencing your thought process? There’s no right answer when it comes to drivers - you might, for example, decide that factoring in the needs or goals of someone close to you is more important than your desire to express creativity through your work. Self-reflection will always be the best tool, so consider your results a jumping off point!',
  },
  driverPopover2: {
    header: 'Drivers can change',
    body: 'These drivers can and do change over time, so what rises to the top for you now may not be the same in a few years. For example, income may increase as a motivator if & when you decide to make a big investment, like saving for your own home. But thinking about your current drivers is critical as you prepare to enter the workforce.',
  },
  preferencesPopover1: {
    header: 'Preferences',
    body: "Alignment includes preferences - these are the factors outside of your interest in the work that will impact your happiness, satisfaction, and success in the role. Read on for what 'preferences' most commonly impact job satisfaction.",
  },
  aside4: {
    header: "'Energy Style' means",
    body: 'Some people are energized at work from getting time alone and being able to focus, while others get energy from meeting with coworkers and clients. Energy style isn’t necessarily about personality traits, like being outgoing, reserved, friendly, shy, etc.  It’s about how you derive energy through the day, getting ready to tackle the next challenge.',
  },
  aside5: {
    header: "'Predictability' means",
    body: 'Preference for predictability is a factor that can play a big role in the fit between a job seeker and a specific opportunity. Some people thrive in work environments that are structured, while others excel in more flexible work environments (like, for example, a start-up company).',
  },
  aside6: {
    header: 'Starting salary affects lifetime earning potential',
    body: "Salaries in a first job have an impact on your earning potential over the long-term, as it forms the starting point for future salary negotiations. It's worth considering how important salary is to you over the long-term.",
  },
  aside7: {
    header: 'Factors that contribute to differences in salaries:',
    body: 'Geography, industry, company size (based on number of employees, revenue etc.), the degree to which a  job requires a “hard” skill (eg, coding, statistical modeling)',
  },
}
//this is new content
export const popoverContent = {
  driver1: {
    header: "Doesn't seem right?",
    body: ' If your highest scoring driver doesn’t “feel right”, it’s worth considering whether others’ opinions (or what you think your drivers “should be”) are influencing your thought process? There’s no right answer when it comes to drivers - you might, for example, decide that factoring in the needs or goals of someone close to you is more important than your desire to express creativity through your work. Self-reflection will always be the best tool, so consider your results a jumping off point!',
  },
  driver2: {
    header: 'Drivers can change',
    body: 'These drivers can and do change over time, so what rises to the top for you now may not be the same in a few years. For example, income may increase as a motivator if & when you decide to make a big investment, like saving for your own home. But thinking about your current drivers is critical as you prepare to enter the workforce.',
  },
  preferences1: {
    header: 'Preferences',
    body: "Alignment includes preferences - these are the factors outside of your interest in the work that will impact your happiness, satisfaction, and success in the role. Read on for what 'preferences' most commonly impact job satisfaction.",
  },
  preferences2: {
    header: 'Energy Style',
    body: 'Some people are energized at work from getting time alone and being able to focus, while others get energy from meeting with coworkers and clients. Energy style isn’t necessarily about personality traits, like being outgoing, reserved, friendly, shy, etc.  It’s about how you derive energy through the day, getting ready to tackle the next challenge.',
  },
  preferences3: {
    header: "'Predictability' means",
    body: 'Preference for predictability is a factor that can play a big role in the fit between a job seeker and a specific opportunity. Some people thrive in work environments that are structured, while others excel in more flexible work environments (like, for example, a start-up company).',
  },
  preferences4: {
    header: 'Starting salary affects lifetime earning potential',
    body: "Salaries in a first job have an impact on your earning potential over the long-term, as it forms the starting point for future salary negotiations. It's worth considering how important salary is to you over the long-term.",
  },
  preferences5: {
    header: 'Factors that contribute to differences in salaries',
    body: 'Geography, industry, company size (based on number of employees, revenue etc.), the degree to which a  job requires a “hard” skill (eg, coding, statistical modeling)',
  },
  qualifications1: {
    header: 'How much do major and GPA matter?',
    body: 'The extent to which major and GPA are significant qualification factors depends on the types of roles you plan to pursue. In most cases, your major does not limit which careers you can pursue (exceptions include software engineering, accounting and other majors in which you’re learning “hard” skills). GPA is still used across many companies and jobs to assess candidates for first jobs.',
  },
  qualifications2: {
    header: 'Qualifications',
    body: 'Qualifications are about how well your profile aligns with what employers typically seek in candidates.',
  },
  clarity1: {
    header: 'Clarity is...',
  },
  clarity2: {
    header: 'STRONG CLARITY',
    body: 'Emanuel wants to be in marketing after meeting with 15 companies at a career fair and feeling most energized learning about the daily work at the marketing firms he met.',
  },
}

export const reportHeaderWithAsideContent = {
  alignmentIntro: {
    header: "'Alignment' means",
    body: 'Alignment is having a strong match between the requirements and characteristics of a particular job and your own qualifications and preferences. Here are some examples:',
  },
  alignmentContent: [
    {
      header: 'Strong Alignment',
      body: 'Ruby understands that accounting jobs mean mostly working by herself, a work style which she prefers in college as well. Accounting requires her to build a deep expertise over time and the classes she is most enjoying in college are highly technical accounting courses.',
    },
    {
      header: 'Weak Alignment',
      body: 'Atif is looking for an accounting role, but has a degree in communications and enjoys highly collaborative team work.',
    },
  ],
}

export const increaseAwarenessContent = {
  introParagraph:
    'The gears below represent ways to broaden and deepen your exposure. Increasing your Exposure will improve your Clarity. Click on any gear to read more about why the activity improves exposure.',
}

export const NewGearInfo = [
  {
    peersExperienceTitle: "Peer's Experiences",
    peersExperienceDescription:
      'Ask friends who interned in a field of interest about the work they did, the company culture, etc.',
  },
  {
    informationalInterviewsTitle: 'Informational Interviews',
    informationalInterviewsDescription:
      'Conversations with professionals to learn about their career/field, also called coffee chats',
  },
  {
    socialMediaTitle: 'Company Social Media',
    socialMediaDescription:
      'Follow companies that interest you to learn about what they value, industry news and opportunities',
  },
  {
    newslettersTitle: 'Newsletters & Listservs',
    newslettersDescription:
      'Great ways to learn about companies, events, jobs and trending issues in the field',
  },
  {
    jobDescriptionsTitle: 'Job Descriptions',
    jobDescriptionsDescription:
      'Get familiar with the responsibilities, qualifications, and even salary ranges for different titles',
  },
  {
    externshipsTitle: 'Externships or Events',
    externshipsDescription:
      'Short opportunities to learn about a company while also making connections for the future',
  },
  {
    internshipsTitle: 'Internships',
    internshipsDescription:
      'The more diverse your internship experiences the better, from the perspective of getting clarity',
  },
  {
    publicationsTitle: 'Industry Publications',
    publicationsDescription:
      'Learn about the field and its culture, while also gaining exposure to trends/news for interview prep',
  },
  {
    blogsTitle: 'Industry Blogs',
    blogsDescription:
      'If you find topics here that interest you, that’s a good sign that the industry could be a good fit.',
  },
  {
    conferencesTitle: 'Conferences & Panels',
    conferencesDescription:
      'Hear from many professionals at once and network with those whose careers interest you',
  },
]

export const GearInfo = [
  {
    title: "Peer's Experiences",
    description:
      'Ask friends who interned in a field of interest about the work they did, the company culture, etc.',
  },
  {
    title: 'Informational Interviews',
    description:
      'Conversations with professionals to learn about their career/field, also called coffee chats',
  },
  {
    title: 'Company Social Media',
    description:
      'Follow companies that interest you to learn about what they value, industry news and opportunities',
  },
  {
    title: 'Newsletters & Listservs',
    description:
      'Great ways to learn about companies, events, jobs and trending issues in the field',
  },
  {
    title: 'Job Descriptions',
    description:
      'Get familiar with the responsibilities, qualifications, and even salary ranges for different titles',
  },
  {
    title: 'Externships or Events',
    description:
      'Short opportunities to learn about a company while also making connections for the future',
  },
  {
    title: 'Internships',
    description:
      'The more diverse your internship experiences the better, from the perspective of getting clarity',
  },
  {
    title: 'Industry Publications',
    description:
      'Learn about the field and its culture, while also gaining exposure to trends/news for interview prep',
  },
  {
    title: 'Industry Blogs',
    description:
      'If you find topics here that interest you, that’s a good sign that the industry could be a good fit.',
  },
  {
    title: 'Conferences & Panels',
    description:
      'Hear from many professionals at once and network with those whose careers interest you',
  },
]

export const alignmentQualificationContent = {
  qualificationsSummary:
    'Qualifications are about how well your profile aligns with what employers typically seek in candidates.',
  aside1: {
    header: 'How much do major and GPA matter?',
    body: 'The extent to which major and GPA are significant qualification factors depends on the types of roles you plan to pursue. In most cases, your major does not limit which careers you can pursue (exceptions include software engineering, accounting and other majors in which you’re learning “hard” skills). GPA is still used across many companies and jobs to assess candidates for first jobs.',
  },
  strengthsChartHeader:
    'https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/102349/ck/images/Strengths%20Table%20Header.png',
}

export const alignmentStrengths = {
  strength1: {
    strength: 'Working with numbers',
    keyword: 'Data Analysis, Quantitative skills',
  },
  strength2: {
    strength: 'Writing summaries and reports',
    keyword: 'Synthesize information, Presentation skills',
  },
  strength3: {
    strength: 'Paying attention to details',
    keyword: 'Attention to detail, Accurate, Precise',
  },
  strength4: {
    strength: 'Being dependable when assigned a task',
    keyword: 'Dependable, Reliable, Consistent',
  },
  strength5: {
    strength: 'Staying persistent with a difficult task',
    keyword: 'Resilient, Adaptable, Consistent',
  },
  strength6: {
    strength: 'Making decisions in stressful situations',
    keyword: 'Decision making skills, Managing high stakes situations, Strong judgment',
  },
  strength7: {
    strength: 'Building relationships with teammates and colleagues',
    keyword: 'Relationship management, Stakeholder management, Interpersonal skills',
  },
  strength8: {
    strength: 'Organizing or analyzing information',
    keyword: 'Analytical skills, Critical thinking, Organizational skills',
  },
  strength9: {
    strength: 'Making information easy to understand',
    keyword: 'Storytelling, Presentation skills',
  },
  strength10: {
    strength: 'Open to learning new things',
    keyword: 'Continuous learning, Curious mindset, Growth mindset',
  },
  strength11: {
    strength: 'Adapting to fast-paced',
    keyword: 'Adaptable, Flexible, Comfort with ambiguity',
  },
  strength12: {
    strength: 'Working alone on a project',
    keyword: 'Self-starter, Independent worker',
  },
  strength13: {
    strength: 'Interacting with clients',
    keyword: 'Customer service oriented, Relationship management, Stakeholder management',
  },
  strength14: {
    strength: 'Managing time well',
    keyword: 'Managing multiple priorities, Time management, Prioritization skills',
  },
}

export const interviewingContent = {
  aside1: {
    header: '“Why am I not at this stage?  I already have an interview.”',
    body: "Getting that first interview booked is awesome! Congrats! Use these strategies to help you prepare. But it’s best not to take your foot off the gas with applications until you have an offer in hand. Aim to get one interview for every 10 applications and you'll have an offer soon enough! ",
  },
  research:
    " Thoroughly research any company that invites you to interview. Discover what is unique about the company and make sure you know enough to show them you're a great fit!",
  prepare:
    'Prepare the key points you want to share about yourself and stories to back up your strengths, skills and experiences. Develop a list of insightful questions to ask your interviewers.',
  practice:
    "Practice answering questions OUT LOUD and get feedback from a coach or advisor. Don't try to memorize answers - focus on the key points you want to communicate so you don't sound rehearsed.",
  followUp:
    'After each interview, send everyone you met a thoughtful thank you email within 24 hours. Reference specific details from your conversation and reiterate your excitement about the role.',
}

export const introContent = {
  introHeading: 'WELCOME TO SEEKR',
  introSummary: {
    p1: 'Welcome to Seekr, a tool to help you determine your next steps. We compared your results to thousands of data points to make this one-of-a-kind dashboard. For years, job seekers have used Basta’s coaching to launch successful careers.',
    p2: 'We won’t tell you what program to study or industry to pursue. Instead, we’ll prompt you to approach your career strategically. Below you’ll see some ideas to consider, proven job search tips, and interview advice. We tailored each recommendation to get you another step closer to your career goals.',
  },
}

export const circleNav = [
  { sectionName: 'Clarity', sectionColor: 'brand.100' },
  { sectionName: 'Alignment', sectionColor: 'brand.200' },
  { sectionName: 'Search', sectionColor: 'brand.300' },
  { sectionName: 'Advance', sectionColor: 'brand.400' },
]
