import React, { useState, useContext } from 'react'
import { jobDescriptions } from '../constants'
import { useMutation } from '@apollo/client'
import { SUBMIT_MISSION_ANSWER } from '../mutations'
import { AuthContext } from 'src/utils/auth'
import { SUBMIT_CHECKPOINT_ANSWER } from 'src/utils/mutations'

interface CareerSwipeContextProps {
  jobIndex: any
  setJobIndex: (Job: any) => void
  careerSwipeStep: any
  setCareerSwipeStep: (step: any) => void
  advanceRound: (job: string, left: string, right: string) => void
  completeCareerSwipe: () => void
  chosenJobs: any
  error: any
  setError: (msg: any) => void
  pairs: any
  setPairs: (jobs: any) => void
  submitCheckpoint: (step: any) => void
}

export const CareerSwipeContext = React.createContext<CareerSwipeContextProps>({
  careerSwipeStep: null,
  setCareerSwipeStep: (step: any) => {},
  jobIndex: null,
  setJobIndex: (step: any) => {},
  advanceRound: () => {},
  completeCareerSwipe: () => {},
  chosenJobs: null,
  error: null,
  setError: (msg: any) => {},
  pairs: null,
  setPairs: (jobs: any) => {},
  submitCheckpoint: () => {},
})

export function CareerSwipeProvider({ children }: { children: any }) {
  const { user } = useContext(AuthContext)
  const [careerSwipeStep, setCareerSwipeStep] = useState(1)
  const [chosenJobs, setChosenJobs] = useState<string[]>([])
  const [jobIndex, setJobIndex] = useState(0)
  const [error, setError] = useState('')
  const [pairs, setPairs] = useState<object[]>([])
  const missionNumber = 2
  const question = 'Career Swipe'

  const [submitMissionAnswer] = useMutation(SUBMIT_MISSION_ANSWER, {
    onCompleted: (data) => {},
    onError: (error) => {
      console.error(error)
      setError(error.message)
    },
  })

  const [submitCheckpoint] = useMutation(SUBMIT_CHECKPOINT_ANSWER, {
    onCompleted: (data) => {},
    onError: (error) => {
      console.error(error)
      setError('Unable to submit your answer right now.')
    },
  })

  const completeCareerSwipe = () => {
    submitMissionAnswer({
      variables: {
        missionNumber: missionNumber,
        question: question,
        answer: Object.keys(chosenJobs),
        responseId: user?.responseId,
      },
    })
    setCareerSwipeStep(4)
  }

  const advanceRound = (job: string, left: string, right: string) => {
    setPairs([...pairs, [left, right]])
    setChosenJobs({ ...chosenJobs, [job]: true })
    if (jobIndex === jobDescriptions.length - 2) {
      setCareerSwipeStep(3)
    } else {
      setJobIndex(jobIndex + 2)
    }
  }

  return (
    <CareerSwipeContext.Provider
      value={{
        completeCareerSwipe,
        careerSwipeStep,
        setCareerSwipeStep,
        jobIndex,
        setJobIndex,
        advanceRound,
        error,
        setError,
        chosenJobs,
        pairs,
        setPairs,
        submitCheckpoint,
      }}
    >
      {children}
    </CareerSwipeContext.Provider>
  )
}
