import { Flex, Text, Center, Box, VStack } from '@chakra-ui/react'
import React from 'react'
import { Header } from 'src/components'

export default function SurveyComplete() {
  return (
    <Box>
      <Header />
      <Center m="auto" textAlign={'center'} maxW={'100vw'} h={'100vh'}>
        <Flex direction={'column'} justify="center" align="center">
          <VStack maxW={'800px'} p={'20px'}>
            <Text textTransform={'none'} color="white" fontSize={'3xl'} fontWeight="700">
              Success! 🎉
            </Text>
            <span>
              <Text color="white" fontSize={'2xl'}>
                Thank you for completing Seekr! We’re now using your results to craft a personalized
                journey.
              </Text>{' '}
            </span>
            <span>
              <Text color="white" fontSize={'2xl'}>
                Your results and next steps will be sent to your email. Be sure to check your inbox
                to access your report and sign up for your first session.{' '}
              </Text>
            </span>
            <Box mt={'10px'}>
              <Text textTransform={'none'} color="white" fontSize={'xl'} fontWeight="700">
                Haven’t received your email within 5 minutes?
              </Text>

              <Text color="white" fontSize={'lg'}>
                Check your spam folder for an email from “seekr@noreply.projectbasta.com”, and if
                you still can’t find it, reach out to us at info@projectbasta.com.
              </Text>
            </Box>
          </VStack>
        </Flex>
      </Center>
    </Box>
  )
}
