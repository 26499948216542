import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Text,
} from '@chakra-ui/react'
import { ExportLog, ExportInput } from 'src/pages/Surveys'
import { Loading } from 'src/components'

interface Props {
  isOpen: boolean
  onClose: () => void
  selectedSurvey: any
  exports: ExportLog[] | undefined
  startCalculation: (sheetName: string) => void
}
interface SheetName {
  fileName: string | undefined
}
export default function CalculateSurveyModal({
  isOpen,
  onClose,
  selectedSurvey,
  exports,
  startCalculation,
}: Props) {
  const [sheetName, setSheetName] = useState<any>()

  const renderFileNames = () => {
    const exportsMap = exports?.map((item, index) => {
      if (selectedSurvey && selectedSurvey.surveyId === item.surveyId) {
        return (
          <option value={item.outputFileName} key={index}>
            {item.outputFileName}
          </option>
        )
      } else {
        return <Text> Not Working</Text>
      }
    })
    return exportsMap
  }

  const renderSheetOptions = () => {
    if (!exports || !selectedSurvey) {
      return <Loading />
    }

    return (
      <>
        <ModalBody>
          <Select
            onChange={(e: any) => setSheetName(e.target.value)}
            placeholder="Select a spreadsheet name"
          >
            {renderFileNames()}
          </Select>
        </ModalBody>
      </>
    )
  }

  const calculateSurveyClick = () => {
    startCalculation(sheetName)
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Available Sheets</ModalHeader>
          <ModalCloseButton />

          {renderSheetOptions()}

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() => {
                calculateSurveyClick()
              }}
              variant="ghost"
            >
              Calculate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
