import React from 'react'
import { Box, Accordion } from '@chakra-ui/react'
import FileFolderAccordion from '../FileFolderAccordion'
import {
  MilestoneSection,
  IncreaseAwareness,
  NewSearchStrategy,
  Interviews,
  ExperienceTypes,
} from 'src/components'
import { Data } from 'src/utils/types'

export default function NextSteps({ reportData }: Data) {
  return (
    <Box mt={5}>
      <Accordion defaultIndex={0}>
        <FileFolderAccordion title="Do this next" page="Next Steps">
          <MilestoneSection
            title="Do this next"
            milestone={reportData?.MilestoneFromMilestoneLink}
            milestoneImage={reportData?.DMS3AttachmentsFromMilestoneLink}
            description={reportData?.DoThisNext}
          />
        </FileFolderAccordion>
        <FileFolderAccordion title="Increase your awareness" page="Next Steps">
          <IncreaseAwareness title="Increase your awareness" />
        </FileFolderAccordion>
        <FileFolderAccordion title="Explore roles that might fit" page="Next Steps">
          <ExperienceTypes reportData={reportData} />
        </FileFolderAccordion>
        <FileFolderAccordion title="Develop a search strategy" page="Next Steps">
          <NewSearchStrategy reportData={reportData} />
        </FileFolderAccordion>
        <FileFolderAccordion title="Ace your interviews" page="Next Steps">
          <Interviews reportData={reportData} />
        </FileFolderAccordion>
      </Accordion>
    </Box>
  )
}
