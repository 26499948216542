import React from 'react'
import { GridItem, Text, Grid, Box, Heading } from '@chakra-ui/react'
import { introContent } from 'src/utils/reportContent'

export default function Intro(): JSX.Element {
  return (
    <Box zIndex={0} position="relative" maxW={'756px'} m="auto">
      <Grid
        gridTemplateColumns={'repeat(2, 1fr)'}
        gridAutoFlow={'row dense'}
        gap="1"
        px={{ base: 5, md: 0 }}
        pt={5}
        mb={5}
      >
        <GridItem textAlign={'center'} colSpan={2} mb={10}>
          <Heading fontFamily={'Intro'} fontWeight={'bold'} fontSize={30}>
            {introContent.introHeading}
          </Heading>
        </GridItem>
      </Grid>
    </Box>
  )
}
