import { useQuery } from '@apollo/client'
import { Box, Container, Text, VStack, Heading, Flex, Button, Icon } from '@chakra-ui/react'
import { FaArrowLeft } from 'react-icons/fa6'
import React from 'react'
import { Header } from 'src/components'
import { GET_USER_PROFILE_INFO } from 'src/utils/queries'
import { useNavigate } from 'react-router-dom'

export const Profile = () => {
  const { data } = useQuery(GET_USER_PROFILE_INFO)

  const navigate = useNavigate()

  const firstName = data?.me?.firstName
  const lastName = data?.me?.lastName
  const fullName = `${firstName} ${lastName}`
  const initials = !firstName && !lastName ? '?' : `${firstName?.[0]}${lastName?.[0]}`
  const handleResetPassword = () => {
    navigate('/forgot-password')
  }

  return (
    <Box height={'100dvh'}>
      <VStack h="full">
        <Header />
        <Box px={3} w="full">
          <Icon
            as={FaArrowLeft}
            background={'white'}
            borderRadius={'50%'}
            color={'brand.navy'}
            width={'30px'}
            height={'30px'}
            p={'10px'}
            onClick={() => navigate('/dashboard')}
          />
          <Heading textAlign="center">Your profile</Heading>
        </Box>
        <Box bg="brand.white" flexGrow={1} w="full">
          <Container maxW="6xl" h="full">
            <VStack flexGrow={1} spacing={4} w="full" alignItems="center" h="full" pt={3}>
              <Flex
                rounded={'full'}
                w={'20'}
                h={'20'}
                alignItems={'center'}
                justify="center"
                bg={'brand.neutral'}
              >
                <Text
                  color="brand.text"
                  fontFamily="sans-serif"
                  fontWeight="bold"
                  textTransform="uppercase"
                  fontSize={'3xl'}
                  textAlign={'center'}
                >
                  {initials}
                </Text>
              </Flex>
              <Box>
                <Text color="brand.text" fontSize={'xl'}>
                  {fullName}
                </Text>
              </Box>
              <Button
                variant="solid"
                _hover={{
                  bg: 'gray.200',
                }}
                w="full"
                rounded="lg"
                bg="brand.neutral"
                onClick={handleResetPassword}
              >
                <Text color="brand.text">Reset password</Text>
              </Button>
            </VStack>
          </Container>
        </Box>
      </VStack>
    </Box>
  )
}
