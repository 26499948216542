import {
  Grid,
  GridItem,
  Input,
  Button,
  Text,
  Heading,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react'
import React, { useState, Dispatch, SetStateAction } from 'react'
import { useMutation } from '@apollo/client'
import { CALCULATE_SURVEY_RESULTS } from 'src/utils/mutations'

export interface DwProps {
  setError: Dispatch<SetStateAction<string | null>>
  setSuccess: Dispatch<SetStateAction<Boolean>>
}

export default function CalculateSheets({ setError, setSuccess }: DwProps) {
  const [gSheetName, setGSheetName] = useState('')
  const [surveyID, setSurveyID] = useState('')
  const [surveyName, setSurveyName] = useState('')

  const { onClose: onCalculateSurveyModalClose } = useDisclosure()

  const [calculateSurvey, { loading }] = useMutation(CALCULATE_SURVEY_RESULTS, {
    onCompleted: (data) => {
      setSuccess(true)
    },
    onError: (error) => {
      setError(error.message)
    },
  })

  const startCalculationForDW = (
    sheetName: string | undefined,
    surveyId: string | undefined,
    surveyName: string | undefined,
    isDw: boolean | undefined,
  ) => {
    if (sheetName) {
      const exportData = {
        surveyId,
        exportName: surveyName,
        outputFileName: sheetName,
        isDw,
      }
      calculateSurvey({
        variables: {
          exportData,
        },
      })

      onCalculateSurveyModalClose()
    } else {
      setError('Please select a sheet to calculate survey results')
    }
  }

  return (
    <div>
      <Heading>Calculate Sheet</Heading>
      <Text mb={5}>Use this function to calculate an already exported survey GSheet.</Text>
      <Grid mb={10} templateColumns="repeat(5, 1fr)" gap={10}>
        <GridItem>
          <Input
            htmlSize={20}
            width="auto"
            placeholder="google sheet name"
            size="sm"
            onChange={(e) => setGSheetName(e.target.value)}
          />
        </GridItem>
        <GridItem>
          <Input
            htmlSize={20}
            width="auto"
            placeholder="survey id"
            size="sm"
            onChange={(e) => setSurveyID(e.target.value)}
          />
        </GridItem>
        <GridItem>
          <Input
            htmlSize={20}
            width="auto"
            placeholder="survey name"
            size="sm"
            onChange={(e) => setSurveyName(e.target.value)}
          />
        </GridItem>
        <GridItem>
          {loading ? (
            <Spinner />
          ) : (
            <Button
              onClick={() => startCalculationForDW(gSheetName, surveyID, surveyName, true)}
              colorScheme="blue"
            >
              Process Reports
            </Button>
          )}
        </GridItem>
      </Grid>
    </div>
  )
}
