import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Icon,
  Text,
  Flex,
} from '@chakra-ui/react'
import React, { Dispatch, SetStateAction, useContext } from 'react'
import { RsvpProps } from 'src/utils/types'
import { FiAlertTriangle } from 'react-icons/fi'
import { useMutation } from '@apollo/client'
import { CANCEL_RSVP_FOR_SESSION } from 'src/utils/mutations'
import { GET_USER_RSVPS } from 'src/utils/queries'
import { AuthContext } from 'src/utils/auth'

interface CancelModalProps {
  setIsCancelModalOpen: Dispatch<SetStateAction<boolean>>
  isCancelModalOpen: boolean
  rsvp: RsvpProps
  toggleCloseModal: () => void
}

export default function CancelModal({
  isCancelModalOpen,
  setIsCancelModalOpen,
  rsvp,
  toggleCloseModal,
}: CancelModalProps) {
  const { user } = useContext(AuthContext)
  const toggleCloseCancelModal = () => {
    setIsCancelModalOpen(false)
  }

  const [cancelRsvpForSession] = useMutation(CANCEL_RSVP_FOR_SESSION, {
    onCompleted: (data) => {
      toggleCloseModal()
      toggleCloseCancelModal()
    },
    onError: (error) => {
      console.error(error)
    },
    refetchQueries: [{ query: GET_USER_RSVPS, variables: { email: user?.email } }, 'userRsvpData'],
  })

  const submitCancelation = (e: any) => {
    e.preventDefault()

    cancelRsvpForSession({
      variables: {
        recordId: rsvp?.rsvpRecordId,
      },
    })
  }
  return (
    <Modal isOpen={isCancelModalOpen} onClose={toggleCloseCancelModal} isCentered size={'lg'}>
      {' '}
      <ModalOverlay />
      <ModalContent p={'50px'} backgroundColor={'white'} borderRadius={'20px'} maxW={'400px'}>
        <Flex
          m={'auto'}
          justifyContent={'center'}
          alignItems={'center'}
          w={'60px'}
          h={'60px'}
          borderRadius={'37px'}
          bg={'#D14F2810'}
        >
          <Icon w={'24px'} h={'24px'} as={FiAlertTriangle} color={'brand.orange'} zIndex={3} />
        </Flex>
        <ModalHeader m={'auto'} color={'black'}>
          Cancel RSVP Confirmation
        </ModalHeader>
        <Divider orientation="horizontal" color={'brand.darkBlue'} w={'90px'} h={'3px'} />

        <ModalBody textAlign={'center'}>
          <Text textColor="brand.lightGray" fontFamily={'Inter'} fontSize={14}>
            Are you sure you want to cancel your RSVP for this event?
          </Text>
        </ModalBody>

        <Stack mt={'30px'} justifyContent={'space-between'} direction={'row'}>
          <Button
            border={'1px solid #DFE4EA'}
            variant="solid"
            _hover={{ bg: 'brand.white' }}
            w={'100%'}
            bg={'white'}
            color={'black'}
            title={'RSVP'}
            fontWeight={500}
            onClick={toggleCloseCancelModal}
          >
            Keep RSVP
          </Button>
          <Button
            variant="solid"
            w={'100%'}
            bg={'brand.orange'}
            _hover={{ bg: 'brand.hoverOrange' }}
            color={'white'}
            title={'RSVP'}
            fontWeight={500}
            onClick={submitCancelation}
          >
            Cancel RSVP
          </Button>
        </Stack>
      </ModalContent>{' '}
    </Modal>
  )
}
