import { Button, Icon, SimpleGrid, VStack, Text } from '@chakra-ui/react'
import { IoDocumentOutline } from 'react-icons/io5'
import React from 'react'
import JobCard from './JobCard'
import { useListJobActivities } from 'src/services/jobActivity/hooks'

interface ApplicationsTabProps {
  toggleOpenJobModal: any
  handleTabsChange: (index: number) => void
}

export default function ApplicationsTab({
  toggleOpenJobModal,
  handleTabsChange,
}: ApplicationsTabProps) {
  const { data: applicationsData } = useListJobActivities({
    dateApplied__isnull: false,
  })

  const ApplicationsEmptyTab = () => {
    return (
      <VStack h={'100%'} w={'full'} justifyContent={'center'}>
        <Icon as={IoDocumentOutline} boxSize={16} />
        <Text
          textTransform={'none'}
          fontFamily={'sans-serif'}
          color="black"
          fontSize={'xl'}
          fontWeight="500"
        >
          You have not applied to any jobs yet
        </Text>
        <Text>Take a look at Basta's recommendations to start apply to your first job!</Text>
        <Button
          variant="solid"
          _hover={{ bg: '#3764b4' }}
          bg={'brand.blue'}
          color={'white'}
          title={'RSVP'}
          fontWeight={500}
          width={'231px'}
          h={'56px'}
          borderRadius={'100px'}
          mt={'20px'}
          onClick={() => handleTabsChange(0)}
        >
          View All Jobs
        </Button>
      </VStack>
    )
  }
  return (
    <>
      {applicationsData?.results.length === 0 ? (
        <ApplicationsEmptyTab />
      ) : (
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacingX={{ md: '25px', lg: '25px' }}
          spacingY={'50px'}
        >
          {applicationsData?.results.map((jobActivity: any, i: number) => (
            <JobCard job={jobActivity?.jobRef} key={i} toggleOpenJobModal={toggleOpenJobModal} />
          ))}
        </SimpleGrid>
      )}
    </>
  )
}
