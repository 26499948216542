import React, { useContext } from 'react'
import { Box, Text, Stack, Center, Link } from '@chakra-ui/react'
import BracketCard from './Card'
import { CareerSwipeContext } from 'src/utils/providers/useCareerSwipe'
import { jobDescriptions } from 'src/utils/constants'

export default function BracketModal() {
  const { jobIndex } = useContext(CareerSwipeContext)

  return (
    <Center>
      <Stack>
        <Stack gap={5} justify="center" align="center" direction={{ sm: 'row', base: 'column' }}>
          <Box>
            <BracketCard
              job={jobDescriptions[jobIndex]}
              left={jobDescriptions[jobIndex].name}
              right={jobDescriptions[jobIndex + 1].name}
            />
          </Box>

          <Box>
            <Text>OR</Text>
          </Box>

          <Box>
            <BracketCard
              job={jobDescriptions[jobIndex + 1]}
              left={jobDescriptions[jobIndex].name}
              right={jobDescriptions[jobIndex + 1].name}
            />
          </Box>
        </Stack>
      </Stack>
    </Center>
  )
}
