import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { EXPORT_RESPONSES } from 'src/utils/mutations'
import { GET_SURVEYS, GET_EXPORTS } from 'src/utils/queries'
import { Button, FormControl, Grid, Heading, Select, Spinner, Text } from '@chakra-ui/react'
import { Survey } from 'src/pages/Surveys'

export interface ExportInput {
  fileName: string
  templateName?: string
  responseId?: string
}

export interface AutoExportProps {
  surveys: Survey[]
  setError: (...args: any[]) => void
  setSuccess: (...args: any[]) => void
}

export default function AutoExport({ surveys, setError, setSuccess }: AutoExportProps) {
  const [selectedSurvey, setSelectedSurvey] = useState<Survey>()

  const [requestExport, { loading }] = useMutation(EXPORT_RESPONSES, {
    onCompleted: (data) => {
      setSuccess(true)
    },
    onError: (error) => {
      setError(error.message)
    },
    refetchQueries: [{ query: GET_SURVEYS }, { query: GET_EXPORTS }],
  })

  const startExport = async () => {
    try {
      if (selectedSurvey?.surveyId) {
        let currentDate = new Date()
        let gsheetName = `${
          selectedSurvey.surveyId
        } ${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`
        const requestExportData = {
          surveyId: selectedSurvey?.surveyId,
          templateName: 'DW (Diagnostic v1.5 External) TEMPLATE', // number format is set to plain text to avoid unintended date formatting
          exportName: selectedSurvey?.name,
          outputFileName: gsheetName,
          responseId: '',
          isDw: true,
        }
        await requestExport({
          variables: {
            exportData: requestExportData,
          },
        })
      }
    } catch (error) {
      setError(JSON.stringify(error))
    }
  }

  const renderInstanceOptions = () => {
    return (
      <FormControl isRequired>
        <Select
          onChange={(e: any) => {
            setSelectedSurvey(JSON.parse(e.target.value))
          }}
          placeholder="Select Survey"
        >
          {surveys?.map((survey: any, index: number) => {
            return (
              <option value={JSON.stringify(survey)} key={index}>
                {survey.name}
              </option>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  return (
    <div>
      <Heading>Warehouse Automations</Heading>
      <Text mb={5}>
        Use this function to export and calculate an active survey then send it to Airtable.
      </Text>
      <Grid mb={20} templateColumns="repeat(5, 1fr)" gap={10}>
        {renderInstanceOptions()}

        {loading ? (
          <Spinner />
        ) : (
          <Button colorScheme="blue" onClick={() => startExport()}>
            Export, Calculate, & Send Data
          </Button>
        )}
      </Grid>
    </div>
  )
}
