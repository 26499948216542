import React, { useState } from 'react'
import { SurveyQuestion, SurveyQuestionOption } from '../hooks/useSurvey'
import Autocomplete from 'src/components/Survey/ui/Autocomplete'
import TextInput from 'src/components/Survey/ui/TextInput'
import { SurveyListMultipleOption, SurveyListSingleOption } from 'src/components/Survey'
import Dropdown from 'src/components/Survey/ui/Dropdown'
interface SurveyContextProps {
  currentSurveySection: any
  setCurrentSurveySection: (section: any) => void
  currentQuestion: any
  setCurrentQuestion: (question: any) => void
  progress: any
  setProgress: (progress: any) => void
  responseValue: any
  setResponseValue: (response: any) => void
  handleIncomingResponse: (response: any) => void
  responseValueArray: any
  setResponseValueArray: (responseArray: any) => void
  singleAnswer: boolean
  setSingleAnswer: (singleAnswer: boolean) => void
  handleResponseValue: (value: string | string[]) => void
  handleResponseValueSingle: (value: string) => void
  getComponent: (question: any) => any
}

export const SurveyContext = React.createContext<SurveyContextProps>({
  currentSurveySection: null,
  setCurrentSurveySection: (step: any) => {},
  currentQuestion: null,
  setCurrentQuestion: (question: any) => {},
  progress: null,
  setProgress: (step: any) => {},
  responseValue: null,
  setResponseValue: (response: any) => {},
  responseValueArray: null,
  setResponseValueArray: (response: any) => {},
  handleIncomingResponse: (step: any) => {},
  singleAnswer: false,
  setSingleAnswer: (singleAnswer: boolean) => {},
  handleResponseValue: (value: string | string[]) => {},
  handleResponseValueSingle: (value: string) => {},
  getComponent: (question: any) => {},
})

export function SurveyProvider({ children }: { children: any }) {
  const [currentQuestion, setCurrentQuestion] = useState<SurveyQuestion | null>(null)
  const [currentSurveySection, setCurrentSurveySection] = useState<any>()
  const [progress, setProgress] = useState(0)
  const [responseValue, setResponseValue] = useState<string>('')
  const [responseValueArray, setResponseValueArray] = useState<string[]>([])
  const [singleAnswer, setSingleAnswer] = useState(false)

  /**
   *  Centralize where the value changes happen
   */
  const handleResponseValue = (value: string | string[]) => {
    if (Array.isArray(value)) {
      setResponseValueArray(value)
      return
    }
    setResponseValue(value)
  }

  const handleResponseValueSingle = (value: string) => {
    setResponseValue(value)
    setSingleAnswer(true)
  }

  const getComponent = ({
    key,
    questionDisplay,
    questionType,
    options = [],
  }: {
    questionType: string
    questionDisplay: string
    key: string
    options?: SurveyQuestionOption[]
  }) => {
    switch (`${questionType}__${questionDisplay}`) {
      case 'SINGLE_OPTION__LIST_VERTICAL':
        return (
          <SurveyListSingleOption
            name={key}
            layout="column"
            options={options.map((x) => {
              return {
                label: x.display,
                value: x.value,
              }
            })}
            value={responseValue}
            onChange={handleResponseValueSingle}
          />
        )

      case 'SINGLE_OPTION__DROPDOWN':
        return (
          <Dropdown
            placeholder="Select option"
            onChange={(e) => handleResponseValue(e.target.value)}
            value={responseValue}
            options={options.map((o) => ({
              display: o.cleanDisplay,
              value: o.value,
            }))}
          />
        )

      case 'SINGLE_OPTION__GRID_LAYOUT':
        return (
          <SurveyListSingleOption
            name={key}
            layout="grid"
            options={options.map((x) => {
              return {
                label: x.display,
                value: x.value,
              }
            })}
            onChange={handleResponseValueSingle}
            value={responseValue}
          />
        )
      case 'SINGLE_OPTION__LIST_HORIZONTAL':
        return (
          <SurveyListSingleOption
            name={key}
            layout="row"
            options={options.map((x) => {
              return {
                label: x.display,
                value: x.value,
              }
            })}
            onChange={handleResponseValueSingle}
            value={responseValue}
          />
        )
      case 'MULTIPLE_OPTION__DROPDOWN':
        return null
      case 'MULTIPLE_OPTION__GRID_LAYOUT':
        return (
          <SurveyListMultipleOption
            name={key}
            layout="grid"
            options={options.map((x) => {
              return {
                label: x.display,
                value: x.value,
              }
            })}
            onChange={handleResponseValue}
            value={responseValueArray}
          />
        )
      case 'MULTIPLE_OPTION__LIST_HORIZONTAL':
        return (
          <SurveyListMultipleOption
            name={key}
            layout="row"
            options={options.map((x) => {
              return {
                label: x.display,
                value: x.value,
              }
            })}
            onChange={handleResponseValue}
            value={responseValueArray}
          />
        )
      case 'MULTIPLE_OPTION__LIST_VERTICAL':
        return (
          <SurveyListMultipleOption
            name={key}
            layout="column"
            options={options.map((x) => {
              return {
                label: x.display,
                value: x.value,
              }
            })}
            onChange={handleResponseValue}
            value={responseValueArray}
          />
        )

      case 'TEXT__OPEN_TEXT':
        return (
          <TextInput
            data-testid="question-type-open-text"
            placeholder="Enter text here"
            onChange={(e) => handleResponseValue(e.target.value)}
            value={responseValue}
          />
        )

      case 'TEXT__AUTOCOMPLETE':
        return (
          <Autocomplete
            data-testid="question-type-text-input-dropdown"
            value={responseValue}
            handleResponseValue={handleResponseValue}
          />
        )

      default:
        return null
    }
  }

  const MULTIPLE_OPTION_TYPE = 'MULTIPLE_OPTION'

  const jsonParse = (data: string) => {
    try {
      return JSON.parse(data)
    } catch {
      console.error('Error parsing JSON', data)
      throw Error('Error parsing JSON')
    }
  }
  const handleIncomingResponse = ({
    question,
    questionResponse,
  }: {
    question: SurveyQuestion
    questionResponse: string
  }) => {
    if (!questionResponse) {
      setResponseValue('')
      setResponseValueArray([])
      return
    }
    const isMultipleOption = question.questionType === MULTIPLE_OPTION_TYPE
    const parsed = jsonParse(questionResponse)
    isMultipleOption ? setResponseValueArray(parsed) : setResponseValue(parsed)
  }

  return (
    <SurveyContext.Provider
      value={{
        currentQuestion,
        setCurrentQuestion,
        currentSurveySection,
        setCurrentSurveySection,
        progress,
        setProgress,
        responseValue,
        setResponseValue,
        responseValueArray,
        handleIncomingResponse,
        setResponseValueArray,
        singleAnswer,
        setSingleAnswer,
        handleResponseValue,
        handleResponseValueSingle,
        getComponent,
      }}
    >
      {children}
    </SurveyContext.Provider>
  )
}
