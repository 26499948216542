import React, { useContext } from 'react'
import { Text, Stack, Button } from '@chakra-ui/react'
import { MatchScoreContext } from 'src/utils/providers/useMatchScore'
import { FaArrowRight } from 'react-icons/fa'

export default function Intro() {
  const { setStrengthsSelectStep } = useContext(MatchScoreContext)
  return (
    <Stack mb={'30px'} align={'center'} maxW={'700px'} textAlign={'center'}>
      <Text color="white">
        Welcome to Basta’s Match Score - you can use Match Score to see if a role would be a good
        match for you, given the field you're interested in and other preferences you’ve shared.{' '}
      </Text>
      <Text color="white" pt={'20px'}>
        For the most part, Match Score uses the information you provided when you completed the
        Seekr survey, and may ask follow-up questions over time.
      </Text>
      <Button
        variant="solid"
        bg={'brand.blue'}
        color={'white'}
        title={'RSVP'}
        mt={'50px'}
        px={'25px'}
        rightIcon={<FaArrowRight />}
        onClick={() => setStrengthsSelectStep(1)}
      >
        Get Started
      </Button>
    </Stack>
  )
}
