import React from 'react'
import { Text, Box, Flex, Image, Grid, GridItem } from '@chakra-ui/react'
import alignmentCircle from 'src/assets/images/alignment_circle.svg'
import { StrengthsList } from 'src/components'
import strenghtsArm from 'src/assets/images/strengths_arm.svg'
import strenghtsKey from 'src/assets/images/strengths_key.svg'
import doubleArrow from 'src/assets/images/double_arrow_down.svg'
import { Data } from 'src/utils/types'

export default function Qualifications({ reportData }: Data) {
  return (
    <Box px={5}>
      <Flex>
        <Text fontSize={20} fontWeight={'bold'} pr={2}>
          Major, GPA, and Strengths
        </Text>

        <Image w="30px" src={alignmentCircle} alt={'Alignment circle icon'} />
      </Flex>
      <Text my={5}>
        Your <span style={{ fontWeight: 'bold' }}>Major and GPA</span>
      </Text>
      <Text>{reportData?.A21}</Text>
      <Text my={5}>
        Your <span style={{ fontWeight: 'bold' }}>Strengths</span>
      </Text>
      <Text>{reportData?.A27}</Text>
      <Grid gridTemplateColumns={'40% 20% 40%'} mx={{ base: 0, md: 10 }} my={5}>
        <GridItem>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <Image src={strenghtsArm} alt={'Strengths arm'} />
            <Text pl={2} fontWeight={'bold'}>
              Strength You Identified
            </Text>
          </Flex>
        </GridItem>
        <GridItem margin={'auto'}>
          <Image transform={'rotate(270deg)'} src={doubleArrow} alt={'Double arrow'} />
        </GridItem>
        <GridItem colStart={3}>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <Image src={strenghtsKey} alt={'Strengths key'} />
            <Text pl={2} fontWeight={'bold'}>
              Job Description Keywords
            </Text>
          </Flex>
        </GridItem>
      </Grid>
      <StrengthsList strengthsList={reportData?.strengthLcol} />
    </Box>
  )
}
