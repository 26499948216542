import { createApi } from '@thinknimble/tn-models'
import { axiosInstance } from '../axios-instance'
import { IndustryShape } from './models'

export const industryApi = createApi({
  client: axiosInstance,
  baseUri: 'employment/industry/',
  models: {
    entity: IndustryShape,
  },
})
