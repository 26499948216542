import gql from 'graphql-tag'

/* add GraphQL client-side queries here */
export const GET_SURVEYS = gql`
  query QualtricsData {
    qualtricsData {
      surveys {
        surveyId
        name
        newResponsesCount
        isActive
      }
    }
  }
`

export const GET_EXPORT_STATUSES = gql`
  query ExportStatusUpdate($surveyId: String!) {
    me {
      exportStatusUpdate(surveyId: $surveyId) {
        datetimeCreated
        status
        surveyId
        templateName
      }
    }
  }
`

export const GET_TEMPLATES = gql`
  query SheetsTemplates {
    sheetsData {
      templates {
        id
        name
        createdTime
        modifiedTime
      }
    }
  }
`

export const GET_EXPORTS = gql`
  query Exports {
    responseExports {
      id
      surveyId
      outputFileName
      spreadsheetLink
      status
      templateName
      datetimeCreated
    }
  }
`

export const GET_INSTANCES = gql`
  query Instances {
    airtableData {
      instances {
        name
      }
    }
  }
`

export const GET_TEST_USERS = gql`
  query MatchScoreUsers {
    testUserData {
      name
      responseId
    }
  }
`

export const GET_TEST_USER = gql`
  query MatchScoreUsers($responseId: String!) {
    testUser(responseId: $responseId) {
      name
      responseId
      strengths
    }
  }
`

export const GET_STRENGTHS = gql`
  query getStrengths {
    strengths {
      id
      strengthShort
      strengthLarge
    }
  }
`

export const FIND_USER = gql`
  query findUser($email: String!) {
    user(email: $email) {
      email
    }
  }
`

export const GET_INTERVIEW_DATA = gql`
  query mongo($responseId: String!) {
    reportData(responseId: $responseId) {
      reportNo
      I2
    }
  }
`

export const GET_INTRO_DATA = gql`
  query mongo($responseId: String!) {
    reportData(responseId: $responseId) {
      reportNo
      Name
      CreatedTime
    }
  }
`

export const GET_ABOUT_YOU_DATA = gql`
  query mongo($responseId: String!) {
    reportData(responseId: $responseId) {
      Email
      Name
      CreatedTime
      C2
      DMS3AttachmentsFromMilestoneLink
      S3A9ImageFromA8EnergyStyleLink
      S3A12ImageFromA11PredictabilityLink2
      S3A9ImageFromA8EnergyStyleLink
      A4
      A5Expertise
      A5Creativity
      A5Income
      A5Meaning
      A5Relationships
      A5Status
      A8
      A11
      A14
      A21
      A27
      A33
      strengthLcol
      EnergyScore
      PredictabilityScore
      S3A15ImageFromA14SalaryExpectationOpentextlink
      InstanceName
      C2Part1FromUserProfile
      InterestPrimary
      C2Part2FromUserProfile
      C2Part3FromUserProfile
    }
  }
`

export const GET_NEXT_STEPS_DATA = gql`
  query mongo($responseId: String!) {
    reportData(responseId: $responseId) {
      Name
      CreatedTime
      MilestoneFromMilestoneLink
      DMS3AttachmentsFromMilestoneLink
      A5Expertise
      A5Creativity
      A5Income
      A5Meaning
      A5Relationships
      A5Status
      EnergyScore
      PredictabilityScore
      S2
      S7
      S10
      S14
      S17
      S25
      S22
      I2
      DMS3A26ImageFromA26ExperienceLink
      A26Text
      DoThisNextPart1FromUserProfile
      DoThisNext
      ApplicationVolumeTextFromApplicationVolume
      InstanceName
    }
  }
`
export const GET_SOCIAL_CAPITAL_DATA = gql`
  query mongo($responseId: String!) {
    reportData(responseId: $responseId) {
      ConnectionList
      CompositionText
      ActivatingNetworkText
      BuildingNetworkText
    }
  }
`

export const GET_USER_PROFILE_INFO = gql`
  query GetUserProfile {
    me {
      id
      isSuperuser
      isStaff
      isActive
      email
      responseId
      firstName
      lastName
      hasBeenWelcomed
      atUserRecordId
      path {
        courses
      }
      cursor {
        id
        reportGenerated
      }
      program {
        programType
      }
    }
  }
`
export const GET_SESSIONS = gql`
  query getSessions($courseId: String!, $email: String!) {
    sessionData(courseId: $courseId, email: $email) {
      sessions {
        session
        sessionId
        date
        course {
          id
        }
      }
    }
  }
`

export const GET_UPCOMING_SESSIONS = gql`
  query getUpcomingSessions($email: String!) {
    upcomingSessionData(email: $email) {
      sessions {
        date
        sessionId
        session
        course {
          name
          shortDescription
        }
      }
    }
  }
`

export const GET_MISSION = gql`
  query missionData($missionNumber: Int!, $responseId: String!) {
    missionData(missionNumber: $missionNumber, responseId: $responseId) {
      answer
    }
  }
`

export const GET_USER_RSVPS = gql`
  query userRsvpData($email: String!) {
    userRsvpData(email: $email) {
      rsvps {
        zoomLink
        time
        date
        rsvpRecordId
        course {
          name
          shortDescription
        }
      }
    }
  }
`

export const GET_SURVEY_RESPONSE = gql`
  query getSurveyResponse($email: String!) {
    surveyResponseData(email: $email) {
      device
      eduStatus
      activityLevel
    }
  }
`

export const GET_SURVEY_DATA = gql`
  query getSurveyData {
    seekrSurveyData {
      title
    }
  }
`

export const GET_QUESTIONS = gql`
  query getQuestionData {
    surveyQuestionData {
      question
      tag
    }
  }
`

export const RETRIEVE_SURVEY_SECTION = gql`
  query ($surveyId: String!) {
    seekrSurveyRetrieve(surveyId: $surveyId) {
      sections {
        id
        title
        order
      }
      questions {
        id
        question
        isFirst
        isCover
        isLast
        questionType
        questionDisplay
        exportKey
        nextQuestion {
          id
        }
      }

      firstQuestion {
        id
        question
        isFirst
        isCover
        isLast
        questionType
        questionDisplay
        exportKey
        nextQuestion {
          id
        }
      }
      lastQuestion {
        id
        question
        isFirst
        isCover
        isLast
        questionType
        questionDisplay
        exportKey
        nextQuestion {
          id
        }
      }
    }
  }
`

export const IS_USER_NOTIFIED = gql`
  query isUserNotified($email: String!, $courseId: String!) {
    isUserNotified(email: $email, courseId: $courseId) {
      webInquiries {
        createdTime
      }
    }
  }
`

export const GET_COURSES = gql`
  query courseData {
    courseData {
      id
      name
      shortDescription
      imageUrl
      courseRecordId
    }
  }
`

export const RETRIEVE_SURVEY_POSITION = gql`
  query retrieveSurveyPosition($email: String!) {
    retrieveCurrentSurveyPosition(email: $email) {
      progress
      currentSection {
        id
        title
        order
      }
      currentQuestion {
        id
        question
        isFirst
        isLast
        isCover
        cleanQuestion
        questionType
        questionDisplay
        exportKey
        options {
          cleanDisplay
          id
          value
          order
          display
          showAdditionalOpenText
        }
        responses {
          id
          value
        }
        logic {
          id
          question {
            id
          }
          responseValue
          logicalOperator
          nextQuestion {
            id
          }
        }
      }
      userId
      isStaff
      currentResponse
      program
      surveyId
      isComplete
    }
  }
`
export const RETRIEVE_SURVEY_QUESTION = gql`
  query retrieveSurveyQuestion($questionId: ID!, $email: String!) {
    retrieveSurveyQuestion(questionId: $questionId, email: $email) {
      question {
        id
        question
        isFirst
        isLast
        isCover
        cleanQuestion
        questionType
        questionDisplay
        exportKey

        surveySection {
        id
        title
        order
      }
        options {
          cleanDisplay
          id
          value
          order
          display
          showAdditionalOpenText
        }
      }

      questionResponse
    }
  }
`
