import React, {useContext} from 'react'
import { FlexProps, Box, Button } from '@chakra-ui/react'
import { AuthContext,logout } from 'src/utils/auth'
import {  useNavigate } from 'react-router-dom'

export default function LogOutButton(props: FlexProps): JSX.Element {
    const navigate = useNavigate()

    const { updateToken, token } = useContext(AuthContext)
    const logUserOut = () => {
        logout()
        updateToken(null)
        navigate('/log-in')
      }
  return (
    <Box {...props}>
    {token &&
      <Button onClick={logUserOut}>Log Out</Button>
    }
    </Box>

  )
}
