import React from 'react'
import {
  Box,
  Text,
  Image,
  Flex,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react'
import clarityCircle from 'src/assets/images/clarity_circle.svg'
import { increaseAwarenessContent } from 'src/utils/reportContent'
import gearIcon from 'src/assets/images/gear_icon.svg'
import { NewGearInfo } from 'src/utils/reportContent.js'

interface IncreaseAwarenessProps {
  title: string
}
export default function IncreaseAwareness({ title }: IncreaseAwarenessProps) {
  return (
    <Box mt={5} mr={5}>
      <Flex>
        <Text fontSize={20} fontWeight={'bold'} pr={2}>
          {title}
        </Text>
        <Image w="20px" src={clarityCircle} alt={'Clarity circle icon'} />
      </Flex>
      <Text my={5}>{increaseAwarenessContent.introParagraph}</Text>
      <Text>Please click on the gears to see more information:</Text>
      <Accordion mt={5}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Image w={5} src={gearIcon} alt={'Gear icon'} />
              <Text ml={5}>Meet people</Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <UnorderedList spacing={2} ml={10}>
              <ListItem>
                <span style={{ fontWeight: 'bold' }}>{NewGearInfo[0].peersExperienceTitle} -</span>{' '}
                {NewGearInfo[0].peersExperienceDescription}
              </ListItem>
              <ListItem>
                <span style={{ fontWeight: 'bold' }}>
                  {' '}
                  {NewGearInfo[1].informationalInterviewsTitle} -
                </span>{' '}
                {NewGearInfo[1].informationalInterviewsDescription}
              </ListItem>
              <ListItem>
                <span style={{ fontWeight: 'bold' }}>{NewGearInfo[9].conferencesTitle} - </span>
                {NewGearInfo[9].conferencesDescription}
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Image w={5} src={gearIcon} alt={'Gear icon'} />
              <Text ml={5}>Stay up to date</Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <UnorderedList spacing={2} ml={10}>
              <ListItem>
                <span style={{ fontWeight: 'bold' }}>{NewGearInfo[2].socialMediaTitle} - </span>
                {NewGearInfo[2].socialMediaDescription}
              </ListItem>
              <ListItem>
                <span style={{ fontWeight: 'bold' }}>{NewGearInfo[3].newslettersTitle} - </span>
                {NewGearInfo[3].newslettersDescription}
              </ListItem>
              <ListItem>
                <span style={{ fontWeight: 'bold' }}>{NewGearInfo[4].jobDescriptionsTitle} - </span>
                {NewGearInfo[4].jobDescriptionsDescription}
              </ListItem>
              <ListItem>
                <span style={{ fontWeight: 'bold' }}>{NewGearInfo[7].publicationsTitle} - </span>
                {NewGearInfo[7].publicationsDescription}
              </ListItem>
              <ListItem>
                <span style={{ fontWeight: 'bold' }}>{NewGearInfo[8].blogsTitle} - </span>
                {NewGearInfo[8].blogsDescription}
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Image w={5} src={gearIcon} alt={'Gear icon'} />
              <Text ml={5}>Gain experiences</Text>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            <UnorderedList spacing={2} ml={10}>
              <ListItem>
                <span style={{ fontWeight: 'bold' }}>{NewGearInfo[6].internshipsTitle} - </span>
                {NewGearInfo[6].internshipsDescription}
              </ListItem>
              <ListItem>
                <span style={{ fontWeight: 'bold' }}>{NewGearInfo[5].externshipsTitle} - </span>
                {NewGearInfo[5].externshipsDescription}
              </ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}
