import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Image,
  Divider,
  Stack,
  Text,
  VStack,
  Link,
  Button,
} from '@chakra-ui/react'

import { RsvpProps } from 'src/utils/types'
import CancelModal from './CancelModal'
import { formatDate, displaySessionPhoto, formatTime } from 'src/utils/globalFunctions'

interface RSVPedModalProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  rsvp: RsvpProps
}

export default function RSVPedModal({ setIsOpen, isOpen, rsvp }: RSVPedModalProps) {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const toggleCloseModal = () => {
    setIsOpen(false)
  }
  const toggleOpenCancelModal = () => {
    setIsCancelModalOpen(true)
  }

  return (
    <Modal isOpen={isOpen} onClose={toggleCloseModal} isCentered size={'lg'}>
      {' '}
      <ModalOverlay />
      <ModalContent p={'50px'} backgroundColor={'white'} w={'1000px'} borderRadius={'20px'}>
        <Image m={'auto'} src={displaySessionPhoto(rsvp.course.name)} />
        <ModalHeader m={'auto'} color={'black'}>
          {rsvp?.course.name}
        </ModalHeader>
        <Divider orientation="horizontal" color={'brand.darkBlue'} w={'90px'} h={'3px'} />

        <ModalBody textAlign={'center'}>
          <Text textColor="brand.lightGray" fontFamily={'Inter'} fontSize={14}>
            {rsvp?.course.shortDescription}
          </Text>
        </ModalBody>
        <Stack mt={'20px'} justifyContent={'space-between'} direction={'row'}>
          <VStack spacing={0} align={'flex-start'}>
            <Text fontFamily={'Inter'} fontWeight={'bold'} color={'black'}>
              Time
            </Text>
            <Text textColor="brand.lightGray" fontFamily={'Inter'} fontSize={14}>
              {formatTime(rsvp.time)}
            </Text>
          </VStack>
          <VStack spacing={0} align={'flex-end'}>
            <Text fontFamily={'Inter'} fontWeight={'bold'} color={'black'}>
              Date
            </Text>
            <Text textColor="brand.lightGray" fontFamily={'Inter'} fontSize={14}>
              {formatDate(rsvp.date, 'year')}
            </Text>
          </VStack>
        </Stack>

        <Stack mt={'30px'} justifyContent={'space-between'} direction={'row'}>
          <CancelModal
            toggleCloseModal={toggleCloseModal}
            isCancelModalOpen={isCancelModalOpen}
            setIsCancelModalOpen={setIsCancelModalOpen}
            rsvp={rsvp}
          />
          <Link w={'100%'} href={rsvp?.zoomLink} isExternal>
            <Button
              variant="solid"
              _hover={{ bg: '#3764b4' }}
              bg={'brand.blue'}
              color={'white'}
              title={'RSVP'}
              fontWeight={500}
              w={'100%'}
            >
              Join Zoom Call
            </Button>
          </Link>
          <Button
            border={'1px solid #DFE4EA'}
            variant="solid"
            _hover={{ bg: 'brand.white' }}
            w={'100%'}
            bg={'white'}
            color={'black'}
            title={'Cancel RSVP'}
            fontWeight={500}
            onClick={toggleOpenCancelModal}
            disabled={new Date(rsvp.date) < new Date()}
          >
            Cancel RSVP
          </Button>
        </Stack>
      </ModalContent>{' '}
    </Modal>
  )
}
