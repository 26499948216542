import { useQuery, useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Center,
  Card,
  CardBody,
  Checkbox,
  Container,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  List,
  ListItem,
  Spinner,
  Stack,
  Text,
  VStack,
  Flex,
  Link,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import React, { FC, useContext, useState } from 'react'
import { FaRegCalendarAlt, FaWindowClose } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Header, Loading } from 'src/components'
import { WelcomeContext } from 'src/utils/providers/useWelcome'
import {
  GET_USER_PROFILE_INFO,
  GET_NEXT_STEPS_DATA,
  GET_MISSION,
  GET_UPCOMING_SESSIONS,
} from 'src/utils/queries'
import {
  EnergyScore,
  StructureStyle,
  TopDrivers,
} from 'src/components/ReportComponents/MiniDashboard'
import SessionTime from 'src/components/Sessions/SessionTime'
import { SessionProps } from 'src/utils/types'
import { SUBMIT_SURVEY_FEEDBACK } from 'src/utils/mutations'

export const RSVConfirmationBanner: FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Box bg="brand.white" w="full" px="10" py="5" position="relative">
      <HStack w="full">
        <Icon as={FaRegCalendarAlt} color={'brand.100'} boxSize={6} />
        <VStack w="full" alignItems={'start'}>
          <Text fontFamily={'sans-serif'} fontSize={'lg'} fontWeight={'bold'} color="black">
            RSVP Confirmed
          </Text>
          <Text color="black">
            A confirmation email and calendar invite for your upcoming session are on their way to
            your inbox. We're excited to have you join us!
          </Text>
        </VStack>
      </HStack>
      <Button position="absolute" onClick={onClose} variant="unstyled" top="3" right="3">
        <Icon as={FaWindowClose} boxSize={'5'} color="brand.text" />
      </Button>
    </Box>
  )
}

const hasRSVPForASeekrDebriefSession = true
const hasReviewedSeekrReport = true
const hasTakenTakeSeekrSurvey = true

export const Dashboard = () => {
  const [hasCompletedExploreJobs, setHasCompletedExploreJobs] = useState(false)
  const [, setShowBanner] = useState(false)
  const [futureSessions, setFutureSessions] = useState<any>({})
  const [feedbackText, setFeedbackText] = useState('')

  const toast = useToast()
  const { hasRSVPed, setRSVPStatus } = useContext(WelcomeContext)
  const { data } = useQuery(GET_USER_PROFILE_INFO, {
    onCompleted: (data) => {
      if (!data?.me?.responseId) {
        navigate('/survey/seekr/?email=' + encodeURIComponent(data?.me?.email??''))
      }
    },
    onError: (error) => {
      console.error(error)
    },
  })
  const { data: report } = useQuery(GET_NEXT_STEPS_DATA, {
    variables: { responseId: data?.me?.responseId },
    skip: !data?.me?.responseId,
    onError: (error: any) => {
      console.error(error)
    },
  })
  const { loading: isMissionLoading, data: mission } = useQuery(GET_MISSION, {
    variables: { missionNumber: 2, responseId: data?.me?.responseId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (mission?.missionData?.answer) {
        setHasCompletedExploreJobs(true)
      } else {
        setHasCompletedExploreJobs(false)
      }
    },
    onError: (error: any) => {
      console.error(error)
    },
  })

  const [submitSurveyFeedback] = useMutation(SUBMIT_SURVEY_FEEDBACK, {
    variables: {
      responseId: data?.me?.responseId,
      feedbackText: feedbackText,
    },
    onCompleted: (data: any) => {
      if (data?.submitSurveyFeedback.success) {
        setFeedbackText('')
        toast({
          title: 'Feedback submitted.',
          description: 'We have received your feedback.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      } else {
        toast({
          title: 'Feedback could not be submitted.',
          description: data?.submitSurveyFeedback.errors,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
    },
    onError: (error) => {
      toast({
        title: 'Feedback could not be submitted.',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    },
  })

  const navigate = useNavigate()
  const onNavigateToReport = () => {
    navigate(`/view-report/${data?.me?.responseId}`)
  }
  const onHideBanner = () => {
    setShowBanner(false)
    setRSVPStatus(false)
  }

  const studentMileStone = report?.reportData?.MilestoneFromMilestoneLink

  const onStartExploreJobs = () => {
    if (data?.me.responseId) navigate('/career-swipe')
  }

  function sortSessionByCourse(sessions: SessionProps[]) {
    return sessions.reduce((acc: Record<string, any>, curSession: SessionProps) => {
      const courseName = curSession?.course.name

      if (!courseName) return acc

      if (!acc[courseName]) {
        acc[courseName] = {
          name: courseName,
          shortDescription: curSession?.course.shortDescription,
          sessions: [],
        }
      }

      acc[courseName].sessions.push(curSession)
      return acc
    }, {})
  }

  const { loading, refetch } = useQuery(GET_UPCOMING_SESSIONS, {
    variables: {
      email: data?.me?.email?.toLowerCase(),
    },
    onCompleted: (data: any) => {
      const sortedSessionsObj = sortSessionByCourse(data?.upcomingSessionData.sessions)
      setFutureSessions(sortedSessionsObj)
    },
    onError: (error: any) => {
      console.error(error)
    },
  })

  let handleInputChange = (e: any) => {
    let inputValue = e.target.value
    setFeedbackText(inputValue)
  }

  const submitFeedback = () => {
    submitSurveyFeedback()
  }

  return (
    <Box height={'100dvh'}>
      <VStack h="full">
        <Header />
        <Box px={3} w="full">
          <Container px={{ base: '10px', md: '80px' }} maxW="8xl" py={6}>
            <VStack flexGrow={1} spacing={4} w="full" alignItems="start">
              <Stack direction={{ base: 'column', md: 'row' }} w="full">
                <Box alignItems="start" w="full" justifyContent="space-evenly">
                  <Text
                    textTransform={'none'}
                    fontFamily={'sans-serif'}
                    color="white"
                    fontSize={'4xl'}
                    fontWeight="700"
                  >
                    Welcome {data?.me?.firstName}
                  </Text>
                  <Text>Find yourself. Plan your career. Discover your passion.</Text>
                </Box>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  width={{ md: 'full' }}
                  spacing={'8'}
                >
                  <HStack spacing={5}>
                    <EnergyScore score={report?.reportData.EnergyScore} />
                    <StructureStyle score={report?.reportData?.PredictabilityScore}>
                      Balanced
                    </StructureStyle>
                  </HStack>
                  <HStack spacing={2} justify={'center'}>
                    <TopDrivers reportData={report?.reportData} />
                  </HStack>
                </Stack>
              </Stack>
              <Box>
                <Button
                  variant="solid"
                  bg={'brand.blue'}
                  color={'white'}
                  onClick={onNavigateToReport}
                  disabled={!data?.me?.responseId}
                  cursor={!data?.me?.responseId ? 'not-allowed' : undefined}
                  title={
                    data?.me?.responseId
                      ? 'View Seekr Results'
                      : 'No response found so there are no results to show'
                  }
                >
                  View Seekr Results
                </Button>
              </Box>
            </VStack>
          </Container>
        </Box>
        {hasRSVPed ? <RSVConfirmationBanner onClose={onHideBanner} /> : null}

        <Center w="full" flexGrow={1} bg="white" py={'30px'}>
          <Grid
            maxW="7xl"
            templateColumns={{ base: '1fr', md: '2.8fr 1.2fr' }}
            columnGap={'30px'}
            textColor="brand.text"
            py={4}
          >
            <GridItem px={10}>
              <Heading fontFamily="sans-serif" data-testid="mission-header" textColor={'brand.text'}>
                Your {studentMileStone} Missions
              </Heading>
              <Text textColor="brand.text" fontSize={14} pb={4}>
                Check out the latest tasks for this milestone
              </Text>
              <Box shadow="lg" borderColor="brand.neutral" rounded="lg" p={3}>
                <List w="full" spacing={3} py={2}>
                  <ListItem w="full">
                    {isMissionLoading ? (
                      <Spinner />
                    ) : (
                      <MissionItem
                        title="Career Swipe"
                        checked={hasCompletedExploreJobs}
                        onStart={onStartExploreJobs}
                        disableStart={!data?.me?.responseId}
                        description={'Compare jobs to discover roles that fit you.'}
                      />
                    )}
                  </ListItem>
                  <MissionDivider />
                  <ListItem w="full">
                    <MissionItem
                      title="RSVP for your first Discovery Session"
                      checked={hasRSVPForASeekrDebriefSession}
                      description={'Talk with a live coach to develop an action plan.'}
                    />
                  </ListItem>
                  <MissionDivider />
                  <ListItem w="full">
                    <MissionItem
                      title="Review Seekr Report"
                      checked={hasReviewedSeekrReport}
                      description={'Your personalized mini-guide to a phenomenal job search.'}
                    />
                  </ListItem>
                  <MissionDivider />
                  <ListItem w="full">
                    <MissionItem title="Take Seekr Survey" checked={hasTakenTakeSeekrSurvey} />
                  </ListItem>
                  <MissionDivider />
                </List>
              </Box>
            </GridItem>
            <GridItem>
              <Flex
                mb={'15px'}
                mt={{ base: '30px', md: 0 }}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Text fontFamily={'Inter'} fontWeight={'600'} fontSize={'xl'}>
                  Upcoming sessions
                </Text>
                <Link
                  href="/sessions"
                  fontFamily={'Inter'}
                  fontSize={'md'}
                  textDecor={'underline'}
                  color={'brand.blue'}
                >
                  See all
                </Link>
              </Flex>

              {loading ? (
                <Loading />
              ) : (
                Object.keys(futureSessions)?.map((session: string, i: number) => (
                  <Card
                    key={i}
                    flexGrow={1}
                    maxW={'md'}
                    backgroundColor={'white'}
                    textColor="brand.text"
                    mb={'10px'}
                  >
                    <CardBody>
                      <Text fontFamily={'Inter'} fontWeight={'600'} fontSize={'xl'} mb={'15px'}>
                        {session}
                      </Text>
                      <Text
                        fontFamily={'Inter'}
                        fontWeight={'400'}
                        color={'brand.lightGray'}
                        fontSize={'14px'}
                      >
                        {futureSessions?.[session]?.shortDescription}
                      </Text>
                      {futureSessions?.[session]?.sessions?.map(
                        (session: SessionProps, i: number) => (
                          <SessionTime
                            keyInt={i}
                            key={i}
                            refetchSessions={refetch}
                            toggleCloseModal={() => null}
                            session={session}
                          />
                        ),
                      )}
                    </CardBody>
                  </Card>
                ))
              )}
            </GridItem>
            <GridItem p={10}>
              <Heading fontFamily="sans-serif" textColor={'brand.text'}>
                Ask a Question
              </Heading>
              <Text textColor="brand.text" fontSize={14} pb={4}>
                Fill out this form with any questions you have.
              </Text>
              <Box shadow="lg" p={5}>
                <Box>
                  <Textarea
                    onChange={handleInputChange}
                    placeholder="Type your question here"
                    borderColor="gray.100"
                    value={feedbackText}
                    _placeholder={{ opacity: 1, color: 'gray.500' }}
                  />
                  <HStack>
                    <Box />
                    <Button
                      ml={'auto'}
                      mt={'20px'}
                      variant="solid"
                      bg={'brand.blue'}
                      color={'white'}
                      onClick={submitFeedback}
                      size="sm"
                      _hover={{
                        background: 'brand.neutral',
                        textColor: 'brand.text',
                        _disabled: {
                          background: 'brand.blue',
                          textColor: 'white',
                        },
                      }}
                      disabled={!feedbackText.length}
                    >
                      Submit question
                    </Button>
                  </HStack>
                </Box>
              </Box>
            </GridItem>
          </Grid>
        </Center>
      </VStack>
    </Box>
  )
}

const MissionItem: FC<{
  title: string
  description?: string
  checked: boolean
  onStart?: () => void
  disableStart?: boolean
}> = ({ title, description, checked, onStart, disableStart }) => {
  return (
    <HStack w={'full'} justifyContent="space-between" alignItems="start">
      <Box>
        <Checkbox readOnly borderColor="brand.text" cursor="default" isChecked={checked} />
      </Box>
      <VStack alignItems="start" flexGrow={1}>
        <Heading
          as="h2"
          textColor={'brand.text'}
          size={'sm'}
          fontWeight={'bold'}
          fontFamily="sans-serif"
          textDecor={checked ? 'line-through' : undefined}
        >
          {title}
        </Heading>
        <Text textColor={'brand.text'} fontSize={14}>
          {description}
        </Text>
      </VStack>
      <Box>
        {!checked && onStart ? (
          <Button
            variant="solid"
            bg={'brand.blue'}
            color={'white'}
            onClick={onStart}
            size="sm"
            _hover={{
              background: 'brand.neutral',
              textColor: 'brand.text',
              _disabled: {
                background: 'brand.blue',
                textColor: 'white',
              },
            }}
            disabled={disableStart}
          >
            Start
          </Button>
        ) : null}
      </Box>
    </HStack>
  )
}

const MissionDivider = () => {
  return (
    <ListItem>
      <Divider variant="solid" backgroundColor="brand.neutral" />
    </ListItem>
  )
}
