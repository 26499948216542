import React from 'react'
import { ReportData } from 'src/utils/types'
import { alignmentPreferencesContent } from 'src/utils/reportContent'
import { AlignmentDriver } from 'src/components'
import { Text, Box, Flex, Image } from '@chakra-ui/react'
import alignmentCircle from 'src/assets/images/alignment_circle.svg'

interface DriversProps {
  reportData: ReportData | undefined
}

type Driver = {
  rank: number
  title: string
  description: string
}
type DriverArray = Driver[]
export default function Drivers({ reportData }: DriversProps) {
  const {
    creativityDescription,
    expertiseDescription,
    incomeDescription,
    relationshipDescription,
    meaningDescription,
    statusDescription,
  } = alignmentPreferencesContent

  function sortDrivers() {
    const rankNumber = (rank: string | undefined) => {
      return rank ? parseInt(rank) : 0
    }
    let drivers: DriverArray = [
      {
        rank: rankNumber(reportData?.A5Creativity),
        title: 'Creativity',
        description: creativityDescription,
      },
      {
        rank: rankNumber(reportData?.A5Expertise),
        title: 'Expertise',
        description: expertiseDescription,
      },
      {
        rank: rankNumber(reportData?.A5Income),
        title: 'Income',
        description: incomeDescription,
      },
      {
        rank: rankNumber(reportData?.A5Relationships),
        title: 'Relationships',
        description: relationshipDescription,
      },
      {
        rank: rankNumber(reportData?.A5Meaning),
        title: 'Meaning',
        description: meaningDescription,
      },
      {
        rank: rankNumber(reportData?.A5Status),
        title: 'Status',
        description: statusDescription,
      },
    ]
    let sortedDrivers = drivers.sort((a: Driver, b: Driver) => a.rank - b.rank)
    let driverMap = sortedDrivers.reverse().map((driver: Driver, index: number) => {
      return <AlignmentDriver key={index} header={driver.title} body={driver.description} />
    })
    return driverMap
  }
  return (
    <Box pr={5}>
      <Flex mb={10}>
        <Text fontSize={20} fontWeight={'bold'} pr={2}>
          Drivers
        </Text>
        <Image w="30px" src={alignmentCircle} alt={'Alignment circle icon'} />
      </Flex>
      <Text fontWeight={'bold'}>Your Ranked Drivers</Text>
      <Text my={5}>{alignmentPreferencesContent.driversSummary + ` ` + reportData?.A4}</Text>
      {sortDrivers()}
    </Box>
  )
}
