import React from 'react'
import { Box, Text, Image, Flex } from '@chakra-ui/react'
import clarityCircle from 'src/assets/images/clarity_circle.svg'
import alignmentCircle from 'src/assets/images/alignment_circle.svg'
import interviewIcon from 'src/assets/images/interview_icon.svg'
import searchIcon from 'src/assets/images/search_icon.svg'
interface MilestoneSectionProps {
  title: string
  description: string | undefined
  milestoneImage: string | undefined
  milestone?: string | undefined
}

export default function MilestoneSection({ title, description, milestone }: MilestoneSectionProps) {
  function switchIcon() {
    if (milestone === 'Clarity') {
      return <Image w="20px" src={clarityCircle} alt={'Clarity milestone icon'} />
    } else if (milestone === 'Alignment') {
      return <Image w="20px" src={alignmentCircle} alt={'Alignment milestone icon'} />
    } else if (milestone === 'Interviewing & Advancing') {
      return <Image w="20px" src={interviewIcon} alt={'Interview milestone icon'} />
    } else if (milestone === 'Search Strategy') {
      return <Image w="20px" src={searchIcon} alt={'Search milestone icon'} />
    } else {
      return <Image w="20px" src={clarityCircle} alt={'Clarity milestone icon'} />
    }
  }
  function switchContent() {
    let desc = description?.split('.')
    desc?.shift()
    let newDescription = desc?.join('.')

    if (title === 'Do this next') {
      return (
        <>
          <Text fontSize={{ base: 16, md: 14 }} w="100%">
            Your milestone is {milestone}.
          </Text>
          <Text fontSize={{ base: 16, md: 14 }} w="100%" my={5}>
            {newDescription}
          </Text>
        </>
      )
    } else {
      return (
        <>
          <Text fontSize={{ base: 16, md: 14 }} w="100%">
            {description}
          </Text>
        </>
      )
    }
  }
  return (
    <Box>
      <Flex>
        <Text fontSize={20} fontWeight={'bold'} pr={2}>
          {title}
        </Text>
        {switchIcon()}
      </Flex>
      <Flex flexDir={'column'}>{switchContent()}</Flex>
      <Text textAlign={{ base: 'left', md: 'center' }} fontWeight="bold" mt={5}>
        CLICK ON THE SECTIONS BELOW TO LEARN MORE
      </Text>
    </Box>
  )
}
