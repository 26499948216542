import { useQuery } from '@tanstack/react-query'
import { jobApi } from './api'

interface JobProps {
  industries?: string
  jobFunctions?: string
  search?: string
  pagination: any
}

export const useListJobs = ({ industries, jobFunctions, search, pagination }: JobProps) => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['jobs', pagination.page],
    queryFn: async () => {
      return await jobApi.list({
        pagination,
        filters: {
          organization__industry: industries,
          function: jobFunctions,
          search,
        },
      })
    },
    enabled: true,
  })
  return { data, refetch, isLoading }
}
