import React, { useState, PropsWithChildren } from 'react'
import {
  Box,
  Text,
  Image,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  UnorderedList,
  ListItem,
  IconButton,
  Portal,
} from '@chakra-ui/react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import { popoverContent } from 'src/utils/reportContent'
import doubleArrowDown from 'src/assets/images/double_arrow_down.svg'

interface FileFolderAccordionProps {
  title: string
  page?: string
}

export default function FileFolderAccordion(props: PropsWithChildren<FileFolderAccordionProps>) {
  const [expanded, setExpanded] = useState(false)
  const [driverPopoverPage, setDriverPopoverPage] = useState(1)
  const [preferencesPopoverPage, setPreferencesPopoverPage] = useState(1)
  const [qualificationsPopoverPage, setQualificationsPopoverPage] = useState(1)
  const [clarityPopoverPage, setClarityPopoverPage] = useState(1)

  function showAboutYouTab(expanded: boolean) {
    let tabTitle = ''

    if (props.title === 'Drivers' || props.title === 'Energy Style, Structure, and Salary') {
      tabTitle = 'PREFERENCES'
    } else if (props.title === 'Major, GPA, and Strengths') {
      tabTitle = 'QUALIFICATIONS'
    } else if (props.page === 'Next Steps') {
      tabTitle = 'NEXT STEPS'
    } else if (props.page === 'Social Capital') {
      tabTitle = 'SOCIAL CAPITAL'
    } else {
      tabTitle = 'ABOUT YOU'
    }
    if (expanded) {
      return (
        <Box
          pr={5}
          bg={'rgba(0, 0, 0, 0.24)'}
          clipPath="polygon(0% 0%, 75% 0%, 97% 94%, 100% 100%, 0% 100%)"
          w="170px"
        >
          <Text fontWeight={'bold'} fontSize={14} pl={5}>
            {tabTitle}
          </Text>
        </Box>
      )
    } else return <></>
  }

  function switchPopover() {
    if (props.title === 'Awareness') {
      return (
        <Popover placement="bottom-end" preventOverflow={true}>
          <PopoverTrigger>
            <Button variant={'ghost'}>
              {' '}
              <AiOutlineInfoCircle size={'20px'} />
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent w={{ base: '90vw', md: 'sm' }} bg="brand.100">
              <PopoverArrow bg="brand.100" />
              <PopoverCloseButton />
              <PopoverBody fontSize={13} textAlign="left">
                <Text fontSize={14} fontWeight={'bold'}>
                  {clarityPopoverPage === 1
                    ? popoverContent.clarity1.header
                    : popoverContent.clarity2.header}
                </Text>
                {clarityPopoverPage === 1 ? (
                  <UnorderedList pt={2}>
                    <ListItem>Having a broad knowledge of different industries</ListItem>
                    <ListItem>Being confident about your interests</ListItem>
                    <ListItem>Having strong reasons for your interests</ListItem>
                  </UnorderedList>
                ) : (
                  <>
                    <Text fontSize={13}>
                      Emanuel wants to be in marketing after meeting with 15 companies at a career
                      fair and feeling most energized learning about the daily work at the marketing
                      firms he met.
                    </Text>
                    <Text fontSize={14} fontWeight={'bold'}>
                      POOR CLARITY
                    </Text>
                    <Text fontSize={13}>
                      Jacqueline likes marketing because they love taking photos and scrolling on
                      social media. They haven’t met any social media marketers but think it could
                      be fun.
                    </Text>
                  </>
                )}
                <Box textAlign={'center'}>
                  <IconButton
                    onClick={() => {
                      setClarityPopoverPage(1)
                    }}
                    color="white"
                    aria-label="Go back"
                    variant={'ghost'}
                    icon={<MdKeyboardArrowLeft />}
                  />
                  <IconButton
                    onClick={() => {
                      setClarityPopoverPage(2)
                    }}
                    color="white"
                    aria-label="Go back"
                    variant={'ghost'}
                    icon={<MdKeyboardArrowRight />}
                  />
                </Box>
              </PopoverBody>
            </PopoverContent>{' '}
          </Portal>
        </Popover>
      )
    } else if (props.title === 'Drivers') {
      return (
        <Popover placement="bottom-end" boundary={'clippingParents'} preventOverflow={false}>
          <PopoverTrigger>
            <Button variant={'ghost'}>
              {' '}
              <AiOutlineInfoCircle size={'20px'} />
            </Button>
          </PopoverTrigger>
          <PopoverContent w={{ base: '90vw', md: 'sm' }} bg="brand.200">
            <PopoverArrow bg="brand.200" />
            <PopoverCloseButton color="black" />
            <PopoverBody fontSize={14} textAlign="left" color={'black'}>
              <Text fontWeight={'bold'}>
                {driverPopoverPage === 1
                  ? popoverContent.driver1.header
                  : popoverContent.driver2.header}
              </Text>

              <Text fontSize={14} py={2}>
                {driverPopoverPage === 1
                  ? popoverContent.driver1.body
                  : popoverContent.driver2.body}
              </Text>
              <Box textAlign={'center'}>
                <IconButton
                  onClick={() => {
                    setDriverPopoverPage(1)
                  }}
                  color="black"
                  aria-label="Go back"
                  variant={'ghost'}
                  icon={<MdKeyboardArrowLeft />}
                />
                <IconButton
                  onClick={() => {
                    setDriverPopoverPage(2)
                  }}
                  color="black"
                  aria-label="Go back"
                  variant={'ghost'}
                  icon={<MdKeyboardArrowRight />}
                />
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )
    } else if (props.title === 'Energy Style, Structure, and Salary') {
      const switchPopoverContent = function switchContent() {
        switch (preferencesPopoverPage) {
          case 1:
            return (
              <>
                <Text fontWeight={'bold'}>{popoverContent.preferences1.header}</Text>

                <Text fontSize={14} py={2}>
                  {popoverContent.preferences1.body}
                </Text>
              </>
            )
          case 2:
            return (
              <>
                <Text fontWeight={'bold'}>{popoverContent.preferences2.header}</Text>

                <Text fontSize={14} py={2}>
                  {popoverContent.preferences2.body}
                </Text>
              </>
            )
          case 3:
            return (
              <>
                <Text fontWeight={'bold'}>{popoverContent.preferences3.header}</Text>

                <Text fontSize={14} py={2}>
                  {popoverContent.preferences3.body}
                </Text>
              </>
            )
          case 4:
            return (
              <>
                <Text fontWeight={'bold'}>{popoverContent.preferences4.header}</Text>

                <Text fontSize={14} py={2}>
                  {popoverContent.preferences4.body}
                </Text>
              </>
            )
          case 5:
            return (
              <>
                <Text fontWeight={'bold'}>{popoverContent.preferences5.header}</Text>

                <Text fontSize={14} py={2}>
                  {popoverContent.preferences5.body}
                </Text>
              </>
            )
          default:
            return (
              <>
                <Text fontWeight={'bold'}></Text>

                <Text fontSize={14} py={2}></Text>
              </>
            )
        }
      }
      return (
        <Popover placement="bottom-end" boundary={'clippingParents'} preventOverflow={true}>
          <PopoverTrigger>
            <Button variant={'ghost'}>
              {' '}
              <AiOutlineInfoCircle size={'20px'} />
            </Button>
          </PopoverTrigger>
          <PopoverContent w={{ base: '90vw', md: 'sm' }} bg="brand.200">
            <PopoverArrow bg="brand.200" />
            <PopoverCloseButton color="black" />
            <PopoverBody pt={5} fontSize={14} textAlign="left" color={'black'}>
              {switchPopoverContent()}
              <Box textAlign={'center'}>
                <IconButton
                  onClick={() => {
                    setPreferencesPopoverPage(
                      preferencesPopoverPage === 1 ? 1 : preferencesPopoverPage - 1,
                    )
                  }}
                  color="black"
                  aria-label="Go back"
                  variant={'ghost'}
                  icon={<MdKeyboardArrowLeft />}
                />
                <IconButton
                  onClick={() => {
                    setPreferencesPopoverPage(
                      preferencesPopoverPage === 5 ? 5 : preferencesPopoverPage + 1,
                    )
                  }}
                  color="black"
                  aria-label="next page"
                  variant={'ghost'}
                  icon={<MdKeyboardArrowRight />}
                />
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )
    } else if (props.title === 'Major, GPA, and Strengths') {
      return (
        <Popover placement="bottom-end" boundary={'clippingParents'} preventOverflow={true}>
          <PopoverTrigger>
            <Button variant={'ghost'}>
              {' '}
              <AiOutlineInfoCircle size={'20px'} />
            </Button>
          </PopoverTrigger>
          <PopoverContent w={{ base: '90vw', md: 'sm' }} bg="brand.200">
            <PopoverArrow bg="brand.200" />
            <PopoverCloseButton color="black" />
            <PopoverBody fontSize={14} textAlign="left" color={'black'}>
              <Text fontWeight={'bold'}>
                {qualificationsPopoverPage === 1
                  ? popoverContent.qualifications1.header
                  : popoverContent.qualifications2.header}
              </Text>

              <Text fontSize={14} py={2}>
                {qualificationsPopoverPage === 1
                  ? popoverContent.qualifications1.body
                  : popoverContent.qualifications2.body}
              </Text>
              <Box textAlign={'center'}>
                <IconButton
                  onClick={() => {
                    setQualificationsPopoverPage(1)
                  }}
                  color="black"
                  aria-label="Go back"
                  variant={'ghost'}
                  icon={<MdKeyboardArrowLeft />}
                />
                <IconButton
                  onClick={() => {
                    setQualificationsPopoverPage(2)
                  }}
                  color="black"
                  aria-label="Go back"
                  variant={'ghost'}
                  icon={<MdKeyboardArrowRight />}
                />
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )
    }
  }

  function getExpanded({ isExpanded }: { isExpanded: boolean }) {
    if (isExpanded) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
    return (
      <>
        <AccordionButton h="60px">
          {isExpanded ? (
            <Flex w="100%" flexDir="row" justifyContent="end">
              {switchPopover()}
            </Flex>
          ) : (
            <Flex w="100%" flexDir="row" justifyContent="space-between">
              <Text fontSize={20} fontWeight={'bold'} pl={1}>
                {props.title}
              </Text>
              <Image w="28px" src={doubleArrowDown} alt={'Double arrow down'} />
            </Flex>
          )}
        </AccordionButton>
        <AccordionPanel>{props.children}</AccordionPanel>
      </>
    )
  }

  return (
    <>
      {showAboutYouTab(expanded)}

      <AccordionItem
        id={props.title}
        border={'none'}
        bg={'rgba(0, 0, 0, 0.24)'}
        mb={5}
        backdropFilter={'blur(15px)'}
        boxShadow={'0px 4px 4px rgba(28, 28, 0.13)'}
      >
        {getExpanded}
      </AccordionItem>
    </>
  )
}
