import { useQuery } from '@apollo/client'
import { RETRIEVE_SURVEY_SECTION } from '../queries'

export type SurveyQuestion = {
  id: string
  question: string
  cleanQuestion: string
  questionType: string
  questionDisplay: string
  exportKey: string
  isFirst: boolean
  isLast: boolean
  isCover: boolean
  options: SurveyQuestionOption[]
}

export type SurveyQuestionOption = {
  id: string
  cleanDisplay: string
  value: string
  order: number
  display: string
  showAdditionalOpenText: boolean
}

export const useGetSurvey = (surveyId?: string) => {
  const { loading, data: surveySection } = useQuery(RETRIEVE_SURVEY_SECTION, {
    variables: {
      surveyId: surveyId,
    },
    skip: !surveyId,
    onCompleted: (data) => {
      console.log(data)
    },
    onError: (error) => {},
  })
  return {
    loading,
    surveySection: surveySection as {
      seekrSurveyRetrieve: {
        questions: SurveyQuestion[]
        sections?: any[]
        firstQuestion: SurveyQuestion
        lastQuestion: SurveyQuestion
      }
    },
  }
}
