import React, { useContext } from 'react'
import { Flex, Box, Button, Text, Container } from '@chakra-ui/react'
import { CareerSwipeContext } from 'src/utils/providers/useCareerSwipe'

export default function Results() {
  const { completeCareerSwipe, chosenJobs, pairs } = useContext(CareerSwipeContext)
  return (
    <Flex w={'80vw'} textAlign={'center'} direction={'column'} justify="center" align="center">
      <Container>
        <Text fontFamily={'Inter'}>
          Congrats on completing Career Swipe! But this is just the start of your Seekr journey.
          Continue to explore career paths by learning from industry professionals and career coach
          experts.
        </Text>
      </Container>
      <Button
        variant="solid"
        bg={'brand.blue'}
        color={'white'}
        title={'RSVP'}
        mt={'30px'}
        mb={'30px'}
        px={'25px'}
        onClick={() => completeCareerSwipe()}
      >
        Finish
      </Button>

      {pairs.map((jobs: any, i: number) => {
        return (
          <Flex key={i} w={'100%'} justify={'center'} m={'0 10px 30px 10px'}>
            <Box
              mr={'10px'}
              minH={['108px', 'auto', 'auto']}
              minW={['154px', 'auto', 'auto']}
              w={{ sm: '100%', base: '145px' }}
              textAlign={'start'}
              borderRadius={'8px'}
              background={chosenJobs[jobs[0]] ? '#FFFFFF1A' : ''}
              border={chosenJobs[jobs[0]] ? '3px solid #2F80ED' : '1px solid white '}
              p={{ sm: '42px 36px 42px 36px', base: '16px' }}
              fontFamily={'Inter'}
              fontWeight={'bold'}
            >
              {jobs[0]}
            </Box>
            <Box mt={'35px'} textAlign={'center'}>
              <Text>vs</Text>
            </Box>
            <Box
              ml={'10px'}
              minH={['108px', 'auto', 'auto']}
              minW={['154px', 'auto', 'auto']}
              w={{ sm: '100%', base: '145px' }}
              textAlign={'start'}
              borderRadius={'8px'}
              background={chosenJobs[jobs[1]] ? '#FFFFFF1A' : ''}
              border={chosenJobs[jobs[1]] ? '3px solid #2F80ED' : '1px solid white '}
              p={{ sm: '42px 36px 42px 36px', base: '16px' }}
              fontFamily={'Inter'}
              fontWeight={'bold'}
            >
              {jobs[1]}
            </Box>
          </Flex>
        )
      })}

      <Box></Box>
    </Flex>
  )
}
