import React from 'react'
import {
  Text,
  Card,
  Button,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  Icon,
  Badge,
  Flex,
  Image,
} from '@chakra-ui/react'
import { FaStar } from 'react-icons/fa'
import { JobProps } from 'src/utils/types'

interface JobCardProps {
  toggleOpenJobModal: (job: JobProps) => void
  job: JobProps
}

export default function JobCard({ toggleOpenJobModal, job }: JobCardProps) {
  const PartnerBadge = () => (
    <Badge
      border={'1px solid #1119281A'}
      borderRadius={'36px'}
      bgColor={'white'}
      textTransform={'capitalize'}
      p={'11px'}
      h={'44px'}
      alignItems={'center'}
    >
      <Flex display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Icon mr={'5px'} as={FaStar} aria-label="bookmark" w={4} h={4} color={'#FFC507'} />
        <Text fontFamily={'Inter'} color={'#111928'} fontWeight={'400'} fontSize={'16px'}>
          Basta Partner
        </Text>
      </Flex>
    </Badge>
  )
  return (
    <Card maxW="md" textColor="brand.text" backgroundColor={'white'}>
      <CardBody>
        <Flex
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Flex alignItems={'center'}>
            <Image
              src={job?.organizationRef?.logo}
              borderRadius={'36px'}
              border={'1px solid #1119281A'}
              mr={2}
              width={'44px'}
              height={'44px'}
              objectFit="cover"
              p={'5px'}
            />
            {job?.organizationRef?.activePartner ? <PartnerBadge /> : <></>}
          </Flex>
          {/* {true ? (
            <IconButton
              icon={<CiBookmark />}
              aria-label="bookmark"
              size={'xl'}
              colorScheme={'black'}
            />
          ) : (
            <IconButton
              icon={<FaBookmark />}
              aria-label="bookmark"
              size={'xl'}
              colorScheme={'black'}
            />
          )} */}
        </Flex>
        <Stack mt="6" spacing="3">
          <Heading mb={'10px'} fontFamily={'Inter'} size="24px">
            {job?.title}
          </Heading>
          <Text noOfLines={4} fontFamily={'Inter'} fontSize={'14px'}>
            {job?.description}
          </Text>
        </Stack>
      </CardBody>

      <CardFooter>
        <Button
          variant="solid"
          _hover={{ bg: '#3764b4' }}
          bg={'brand.blue'}
          color={'white'}
          title={'RSVP'}
          fontWeight={500}
          width={'100%'}
          size={'lg'}
          borderRadius={'100px'}
          onClick={() => toggleOpenJobModal(job)}
        >
          Learn More
        </Button>
      </CardFooter>
    </Card>
  )
}
