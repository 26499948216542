import React, { useContext } from 'react'
import {
  Box,
  Card,
  CardBody,
  Text,
  Button,
  ListItem,
  List,
  CardHeader,
  CardFooter,
  HStack,
  Hide,
} from '@chakra-ui/react'
import { CareerSwipeContext } from 'src/utils/providers/useCareerSwipe'

interface JobProps {
  name: string
  description: string
  qualifications: string[]
}

interface BracketCardProps {
  job: JobProps
  left: string
  right: string
}

export default function BracketCard({ job, left, right }: BracketCardProps) {
  const { advanceRound } = useContext(CareerSwipeContext)
  return (
    <Card
      m={'4'}
      backgroundColor={{ base: 'brand.gray', sm: '#FFFFFF1A' }}
      direction={{ base: 'column' }}
      overflow="hidden"
      maxW={'xl'}
      boxShadow={'0px 3px  0px #00000040'}
    >
      <Box p={'20px'} boxShadow={{ sm: '0px 0px  10px 0px #00000040' }} border={'2px red'}>
        <CardHeader m={2} p={0}>
          <Text
            textTransform={'none'}
            fontFamily={'sans-serif'}
            color="white"
            fontSize={'2xl'}
            fontWeight="700"
          >
            {job.name}
          </Text>
        </CardHeader>
        <CardBody mx={2} p={0}>
          <Text fontFamily={'Inter'}>{job.description}</Text>
        </CardBody>
      </Box>
      <Box p={'20px 20px 0 20px'} background={{ sm: '#FFFFFF1A', base: 'initial' }}>
        <Hide below="md">
          <Text fontFamily={'Inter'} fontWeight={'bold'}>
            Signs of a good match:
          </Text>

          <List mt={'10px'}>
            {job.qualifications.map((q, i) => (
              <ListItem key={i}>
                <HStack alignItems={'center'}>
                  <Box
                    w={'26px'}
                    height={'26px'}
                    borderRadius={'50%'}
                    border={'1px solid #FFFFFF'}
                    mt={'5px'}
                    opacity={'75%'}
                    textAlign={'center'}
                  >
                    <Text fontFamily={'Inter'} m={'auto'}>
                      {i + 1}
                    </Text>
                  </Box>

                  <Text fontFamily={'Inter'}>{q}</Text>
                </HStack>
              </ListItem>
            ))}
          </List>
        </Hide>
        <CardFooter>
          <Button
            variant="solid"
            _hover={{ bg: '#3764b4' }}
            w={'100%'}
            bg={'brand.blue'}
            color={'white'}
            title={'RSVP'}
            my={'30px'}
            onClick={() => advanceRound(job.name, left, right)}
          >
            Select
          </Button>
        </CardFooter>{' '}
      </Box>
    </Card>
  )
}
