import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Heading, Box, Text, HStack, VStack } from '@chakra-ui/react'
import { GET_TEMPLATES } from 'src/utils/queries'

export interface Template {
  id: string
  name: string
  createdTime: string
  modifiedTime: string
}

export default function Templates() {
  const [templates, setTemplates] = useState<Template[]>([])

  useQuery(GET_TEMPLATES, {
    onCompleted: (data) => {
      setTemplates(data.sheetsData.templates)
    },
    onError: (error) => {
      console.error(error)
    },
  })

  return (
    <Box textAlign="center">
      <Heading>Your Templates</Heading>
      <VStack>
        {templates?.map((template: any, index: number) => {
          return (
            <HStack key={index}>
              <Text>{template.name}</Text>
            </HStack>
          )
        })}
      </VStack>
    </Box>
  )
}
