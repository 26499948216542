import React, { useState } from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { ReportData } from 'src/utils/types'

import consultingTile from 'src/assets/images/alignment-tiles/interest_area_tile_consulting.svg'
import technologyTile from 'src/assets/images/alignment-tiles/interest_area_tile_technology.svg'
import financeTile from 'src/assets/images/alignment-tiles/interest_area_tile_finance.svg'
import socialImpactTile from 'src/assets/images/alignment-tiles/interest_area_tile_social_impact.svg'
import marketingTile from 'src/assets/images/alignment-tiles/interest_area_tile_marketing.svg'
import educationTile from 'src/assets/images/alignment-tiles/interest_area_tile_education.svg'

import selectedConsultingTile from 'src/assets/images/alignment-tiles/interest_area_tile_consulting_selected.svg'
import selectedTechnologyTile from 'src/assets/images/alignment-tiles/interest_area_tile_technology_selected.svg'
import selectedFinanceTile from 'src/assets/images/alignment-tiles/interest_area_tile_finance_selected.svg'
import selectedSocialImpactTile from 'src/assets/images/alignment-tiles/interest_area_tile_social_impact_selected.svg'
import selectedMarketingTile from 'src/assets/images/alignment-tiles/interest_area_tile_marketing_selected.svg'
import selectedEducationTile from 'src/assets/images/alignment-tiles/interest_area_tile_education_selected.svg'

import creativityDriverTile from 'src/assets/images/driver-tiles/driver_tile_creativity.svg'
import expertiseDriverTile from 'src/assets/images/driver-tiles/driver_tile_expertise.svg'
import incomeDriverTile from 'src/assets/images/driver-tiles/driver_tile_income.svg'
import meaningDriverTile from 'src/assets/images/driver-tiles/driver_tile_meaning.svg'
import relationshipDriverTile from 'src/assets/images/driver-tiles/driver_tile_relationship.svg'
import statusDriverTile from 'src/assets/images/driver-tiles/driver_tile_status.svg'

import selectedCreativityDriverTile from 'src/assets/images/driver-tiles/driver_tile_creativity_selected.svg'
import selectedExpertiseDriverTile from 'src/assets/images/driver-tiles/driver_tile_expertise_selected.svg'
import selectedIncomeDriverTile from 'src/assets/images/driver-tiles/driver_tile_income_selected.svg'
import selectedMeaningDriverTile from 'src/assets/images/driver-tiles/driver_tile_meaning_selected.svg'
import selectedRelationshipDriverTile from 'src/assets/images/driver-tiles/driver_tile_relationship_selected.svg'
import selectedStatusDriverTile from 'src/assets/images/driver-tiles/driver_tile_status_selected.svg'

import removeTileIcon from 'src/assets/images/remove_tile_icon.svg'
import alignmentCheckmark from 'src/assets/images/alignment_checkmark.svg'
import alignmentXIcon from 'src/assets/images/alignment_x_icon.svg'

type Driver = {
  rank: number
  title: string
  icon: string
  selectedIcon: string
  id: string
}
type DriverArray = Driver[]

type InterestArea = {
  title: string
  icon: string
  selectedIcon: string
  id: string
}
type InterestAreaArray = InterestArea[]

export default function Explore(props: { reportData: ReportData | undefined }) {
  const [selectedDriver, setSelectedDriver] = useState<Driver>({
    rank: 0,
    title: '',
    icon: '',
    selectedIcon: '',
    id: '',
  })
  const [selectedInterestArea, setSelectedInterestArea] = useState<InterestArea>({
    title: '',
    icon: '',
    selectedIcon: '',
    id: '',
  })

  function getDrivers() {
    const rankNumber = (rank: string | undefined) => {
      return rank ? parseInt(rank) : 0
    }
    let drivers: DriverArray = [
      {
        rank: rankNumber(props.reportData?.A5Creativity),
        title: 'Creativity',
        icon: creativityDriverTile,
        selectedIcon: selectedCreativityDriverTile,
        id: 'creativity',
      },
      {
        rank: rankNumber(props.reportData?.A5Expertise),
        title: 'Expertise',
        icon: expertiseDriverTile,
        selectedIcon: selectedExpertiseDriverTile,
        id: 'expertise',
      },
      {
        rank: rankNumber(props.reportData?.A5Income),
        title: 'Income',
        icon: incomeDriverTile,
        selectedIcon: selectedIncomeDriverTile,
        id: 'income',
      },
      {
        rank: rankNumber(props.reportData?.A5Meaning),
        title: 'Meaning',
        icon: meaningDriverTile,
        selectedIcon: selectedMeaningDriverTile,
        id: 'meaning',
      },
      {
        rank: rankNumber(props.reportData?.A5Relationships),
        title: 'Relationships',
        icon: relationshipDriverTile,
        selectedIcon: selectedRelationshipDriverTile,
        id: 'relationships',
      },
      {
        rank: rankNumber(props.reportData?.A5Status),
        title: 'Status',
        icon: statusDriverTile,
        selectedIcon: selectedStatusDriverTile,
        id: 'status',
      },
    ]
    return drivers
  }

  function getDriverTiles() {
    let sortedDrivers = getDrivers().sort((a: Driver, b: Driver) => a.rank - b.rank)
    let driverTiles = sortedDrivers.reverse().map((driver: Driver) => {
      return (
        <Flex
          flex={'1 0 0px'}
          flexDirection={'column'}
          whiteSpace={{ base: 'normal', md: 'nowrap' }}
          alignItems={'center'}
          justifyContent={'flex-end'}
          onDragStart={(event) => dragHandler(event)}
          draggable="true"
          onClick={() => selectDriverTile(driver)}
          paddingBottom={'10px'}
        >
          {selectedDriver.title === driver.title ? (
            <>
              <Image
                id={driver.id + 'DriverTile'}
                src={driver.selectedIcon}
                alt={driver.title + ' driver tile icon'}
                maxW={'unset'}
                mb={1}
                h="45px"
                w="47px"
              />
              <Text textColor={'#737373'} fontWeight={'bold'} fontSize={12} textAlign={'center'}>
                {driver.title}
              </Text>
            </>
          ) : (
            <>
              <Image
                id={driver.id + 'DriverTile'}
                src={driver.icon}
                alt={driver.title + ' driver tile icon'}
                maxW={'unset'}
                mb={1}
                h="45px"
                w="47px"
              />
              <Text fontWeight={'bold'} fontSize={12} textAlign={'center'}>
                {driver.title}
              </Text>
            </>
          )}
        </Flex>
      )
    })
    return driverTiles
  }

  function getInterestAreas() {
    let interestAreas: InterestAreaArray = [
      {
        title: 'Marketing',
        icon: marketingTile,
        selectedIcon: selectedMarketingTile,
        id: 'marketing',
      },
      {
        title: 'Education',
        icon: educationTile,
        selectedIcon: selectedEducationTile,
        id: 'education',
      },
      {
        title: 'Technology',
        icon: technologyTile,
        selectedIcon: selectedTechnologyTile,
        id: 'technology',
      },
      {
        title: 'Social Impact',
        icon: socialImpactTile,
        selectedIcon: selectedSocialImpactTile,
        id: 'socialImpact',
      },
      {
        title: 'Consulting',
        icon: consultingTile,
        selectedIcon: selectedConsultingTile,
        id: 'consulting',
      },
      {
        title: 'Finance',
        icon: financeTile,
        selectedIcon: selectedFinanceTile,
        id: 'finance',
      },
    ]
    return interestAreas
  }

  function getInterestAreaTiles() {
    let interestAreaTiles = getInterestAreas().map((interestArea: InterestArea) => {
      return (
        <Flex
          flex={'1 0 0px'}
          flexDir={'column'}
          whiteSpace={{ base: 'normal', md: 'nowrap' }}
          alignItems={'center'}
          onDragStart={(event) => dragHandler(event)}
          draggable="true"
          onClick={() => selectInterestAreaTile(interestArea)}
          paddingBottom={'10px'}
        >
          {selectedInterestArea.title === interestArea.title ? (
            <>
              <Image
                id={interestArea.id + 'InterestAreaTile'}
                src={interestArea.selectedIcon}
                alt={interestArea.title + ' interest tile icon'}
                maxW={'unset'}
                mb={1}
                h="45px"
                w="47px"
              />
              <Text textColor={'#737373'} fontWeight={'bold'} fontSize={12} textAlign={'center'}>
                {interestArea.title}
              </Text>
            </>
          ) : (
            <>
              <Image
                id={interestArea.id + 'InterestAreaTile'}
                src={interestArea.icon}
                alt={interestArea.title + 'interest tile icon'}
                maxW={'unset'}
                mb={1}
                h="45px"
                w="47px"
              />
              <Text fontWeight={'bold'} fontSize={12} textAlign={'center'}>
                {interestArea.title}
              </Text>
            </>
          )}
        </Flex>
      )
    })
    return interestAreaTiles
  }

  // Allow elements to be dropped into the designated drop area(s)
  function allowDrop(event: any) {
    event.preventDefault()
  }

  // Handle passing the id of the tile being dragged through the drag + drop event data
  function dragHandler(event: any) {
    let tileID = event.target.id
    event.dataTransfer.setData('text', tileID)
  }

  // Handle getting the id of the tile being dropped through the drag + drop event data
  // and confirm it's being dropped into the correct associated drop zone
  function dropHandler(event: any) {
    event.preventDefault()
    // Grab the id from the icon tile being drag + dropped
    let tileID = event.dataTransfer.getData('text')

    // Check that the interest area tile is drag + dropped into an interest area tile zone
    if (
      tileID.includes('InterestAreaTile') &&
      event.target.id.includes('interestAreaTileDropZone')
    ) {
      // Grab the interest area object that matches the id of the icon being drag + dropped
      // and set it to selectedInterestArea
      let interestArea = getInterestAreas().find((interest) => tileID.includes(interest.id))
      setSelectedInterestArea(interestArea!)
    }

    // Check that the driver tile is drag + dropped into a driver tile zone
    else if (tileID.includes('DriverTile') && event.target.id.includes('driverTileDropZone')) {
      // Grab the driver object that matches the id of the icon being drag + dropped
      // and set it to selectedDriver
      let driver = getDrivers().find((driver) => tileID.includes(driver.id))
      setSelectedDriver(driver!)
    }
  }

  function selectDriverTile(driver: any) {
    setSelectedDriver(driver)
  }

  function selectInterestAreaTile(interest: any) {
    setSelectedInterestArea(interest)
  }

  function clearDriverTileSelection() {
    setSelectedDriver({
      rank: 0,
      title: '',
      icon: '',
      selectedIcon: '',
      id: '',
    })
  }

  function clearInterestAreaTileSelection() {
    setSelectedInterestArea({
      title: '',
      icon: '',
      selectedIcon: '',
      id: '',
    })
  }

  function checkAlignment() {
    if (
      (selectedDriver.title === 'Creativity' && selectedInterestArea.title === 'Marketing') ||
      (selectedDriver.title === 'Relationships' && selectedInterestArea.title === 'Education') ||
      (selectedDriver.title === 'Expertise' && selectedInterestArea.title === 'Technology') ||
      (selectedDriver.title === 'Meaning' && selectedInterestArea.title === 'Social Impact') ||
      (selectedDriver.title === 'Status' && selectedInterestArea.title === 'Consulting') ||
      (selectedDriver.title === 'Income' && selectedInterestArea.title === 'Finance')
    ) {
      return (
        <>
          <Image src={alignmentCheckmark} alt={'Green checkmark'} w={'25px'} />
          <Text fontWeight={'bold'} fontSize={20}>
            Aligned!
          </Text>
        </>
      )
    } else if (
      (selectedDriver.title === 'Expertise' && selectedInterestArea.title === 'Marketing') ||
      (selectedDriver.title === 'Status' && selectedInterestArea.title === 'Education') ||
      (selectedDriver.title === 'Relationships' && selectedInterestArea.title === 'Technology') ||
      (selectedDriver.title === 'Income' && selectedInterestArea.title === 'Social Impact') ||
      (selectedDriver.title === 'Meaning' && selectedInterestArea.title === 'Consulting') ||
      (selectedDriver.title === 'Creativity' && selectedInterestArea.title === 'Finance')
    ) {
      return (
        <>
          <Image src={alignmentXIcon} alt={'Red X cross mark'} w={'25px'} />
          <Text fontWeight={'bold'} fontSize={20}>
            Not aligned.
          </Text>
        </>
      )
    } else {
      return (
        <>
          <Text fontSize={24} lineHeight={'23px'}>
            👍
          </Text>
          <Text fontWeight={'bold'} fontSize={20}>
            Not bad!
          </Text>
        </>
      )
    }
  }

  return (
    <Box
      border={'none'}
      bg={'rgba(0, 0, 0, 0.24)'}
      px={5}
      py={3}
      mt={5}
      backdropFilter={'blur(15px)'}
      boxShadow={'0px 4px 4px rgba(28, 28, 0.13)'}
    >
      <Flex flexDir={'column'} alignItems={'center'} mb={5}>
        <Text fontWeight={'bold'} fontSize={20} textAlign="center">
          Find out what factors can give you alignment!
        </Text>
        <Text fontSize={15} textAlign="center">
          Select one interest area and one alignment factor below to see how aligned you are:
        </Text>
      </Flex>
      <Flex justifyContent={'space-between'} gap={{ base: 5, md: 0 }} mb={6} overflowX={'scroll'}>
        {getInterestAreaTiles()}
      </Flex>
      <Flex justifyContent={'space-between'} gap={{ base: 5, md: 0 }} mb={12} overflowX={'scroll'}>
        {getDriverTiles()}
      </Flex>
      <Flex justifyContent={'center'} mb={5}>
        {selectedDriver.title ? (
          <Flex
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            w={['160px', '200px']}
            id={'driverTileDropZoneSelected'}
            onDragOver={(event) => allowDrop(event)}
            onDrop={(event) => dropHandler(event)}
            position={'relative'}
          >
            <Image
              id={'driverTileDropZoneImageSelected'}
              src={selectedDriver.icon}
              alt={selectedDriver.title + ' driver tile icon'}
              maxW={'unset'}
              mb={1}
              w="85px"
            />
            <Image
              src={removeTileIcon}
              alt={'Remove selected driver tile X icon'}
              w={'20px'}
              position={'absolute'}
              top={-1}
              right={{ base: 30, md: 50 }}
              onClick={() => clearDriverTileSelection()}
            />
            <Text fontWeight={'bold'} fontSize={22}>
              {selectedDriver.title}
            </Text>
          </Flex>
        ) : (
          <Flex
            flexDir={'column'}
            justifyContent={'center'}
            border={'none'}
            bg={'#303434'}
            boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.13)'}
            borderRadius={'5px'}
            h={'118px'}
            w={'122px'}
            m={['0px 19px', '0px 39px']}
            id={'driverTileDropZone'}
            onDragOver={(event) => allowDrop(event)}
            onDrop={(event) => dropHandler(event)}
          >
            <Text fontSize={15} textAlign={'center'} pb={2} id={'driverTileDropZoneText'}>
              Add a <br />
              Driver
            </Text>
          </Flex>
        )}
        {selectedInterestArea.title ? (
          <Flex
            flexDir={'column'}
            alignItems={'center'}
            w={['160px', '200px']}
            id={'interestAreaTileDropZoneSelected'}
            onDragOver={(event) => allowDrop(event)}
            onDrop={(event) => dropHandler(event)}
            position={'relative'}
          >
            <Image
              id={'interestAreaTileDropZoneImageSelected'}
              src={selectedInterestArea.icon}
              alt={selectedInterestArea.title + ' interest tile icon'}
              maxW={'unset'}
              mb={1}
              w="85px"
            />
            <Image
              src={removeTileIcon}
              alt={'Remove selected interest area tile X icon'}
              w={'20px'}
              position={'absolute'}
              top={-1}
              right={{ base: 30, md: 50 }}
              onClick={() => clearInterestAreaTileSelection()}
            />
            <Text fontWeight={'bold'} fontSize={22}>
              {selectedInterestArea.title}
            </Text>
          </Flex>
        ) : (
          <Flex
            flexDir={'column'}
            justifyContent={'center'}
            border={'none'}
            bg={'#303434'}
            boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.13)'}
            borderRadius={'5px'}
            h={'118px'}
            w={'122px'}
            m={['0px 19px', '0px 39px']}
            id={'interestAreaTileDropZone'}
            onDragOver={(event) => allowDrop(event)}
            onDrop={(event) => dropHandler(event)}
          >
            <Text fontSize={15} textAlign={'center'} pb={2} id={'interestAreaTileDropZoneText'}>
              Add an <br />
              Interest Area
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex flexDir={'column'} alignItems={'center'} h={'50px'} mb={5}>
        {selectedDriver.title && selectedInterestArea.title ? checkAlignment() : null}
      </Flex>
    </Box>
  )
}
