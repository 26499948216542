import React, { Dispatch, SetStateAction } from 'react'
import { Alert, AlertIcon, Text, CloseButton } from '@chakra-ui/react'

export interface ErrorAlertProps {
  setError: Dispatch<SetStateAction<string | null>>
  errorMessage: string
}

export default function Error({ errorMessage, setError }: ErrorAlertProps) {
  return (
    <Alert position={'fixed'} status="error">
      <AlertIcon />
      <Text>{errorMessage}</Text>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={() => setError(null)}
      />
    </Alert>
  )
}
