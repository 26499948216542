import { Image, Box, Text, VStack, Stack, Hide } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import astronaut from 'src/assets/images/cp1-astronaut.png'
import { Header } from 'src/components'

interface CheckpointProps {
  children: ReactNode
  error: string
}

export default function Checkpoint({ children, error }: CheckpointProps) {
  return (
    <Box h="100dvh">
      <Header />

      <Stack
        direction={{
          base: 'column',
          sm: 'row',
        }}
        w="full"
      >
        <Hide below="md">
          <Box
            display="flex"
            alignItems="center"
            justifyContent={{
              base: 'center',
              sm: 'start',
            }}
            flexShrink={1}
          >
            <Image
              src={astronaut}
              objectFit={{
                base: 'cover',
                md: 'contain',
              }}
              h={'full'}
              loading="lazy"
            />
          </Box>
        </Hide>

        <Box pl={'5%'} pr={'5%'} maxW={'975px'} mt={'20px'}>
          <VStack
            gap={2}
            h="full"
            alignItems="start"
            justifyContent="center"
            pl={{
              base: 10,
              sm: 0,
            }}
            flexGrow={1}
            style={{
              marginInlineStart: 0,
            }}
          >
            <Text
              textTransform={'none'}
              fontFamily={'sans-serif'}
              color="#2F80ED"
              fontSize={'m'}
              fontWeight="700"
            >
              KNOWLEDGE CHECK-POINT
            </Text>
            {error && (
              <Text mt={'10px'} color="red">
                {error}
              </Text>
            )}
            {children}
          </VStack>
        </Box>
      </Stack>
    </Box>
  )
}
