import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { default as React, useEffect, useState, useContext } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import basta_logo_white from 'src/assets/images/basta_logo_white.png'
import { RESET_PASSWORD, LOG_IN } from 'src/utils/mutations'
import { AuthContext } from 'src/utils/auth'

export default function ResetPassword() {
  const { updateToken, setUser, user } = useContext(AuthContext)
  let { email, token } = useParams()
  const [urlFullName, setUrlFullName] = useState('')
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')
  const [showPass, setShowPass] = React.useState(false)
  const [showReenter, setShowReenter] = React.useState(false)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()

  const [login] = useMutation(LOG_IN, {
    onCompleted: (data) => {
      localStorage.setItem('auth-token', data.tokenAuth.token)
      localStorage.setItem('user', JSON.stringify(data.tokenAuth.user))
      updateToken(data.tokenAuth.token)
      setUser(data.tokenAuth.user)
      navigate('/welcome')
    },
    onError: (error) => {
      setError('Could not log in. Try again.')
      console.error(error.networkError)
    },
  })

  const [reset] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => {
      login({
        variables: {
          email: email?.toLowerCase(),
          password: password,
        },
      })
    },
    onError: (error) => {
      console.error(error)
      setError(
        'Something went wrong. Please contact an administrator if you are not able to reset and log in.',
      )
    },
  })

  const passwordIsValid = (input: string) => {
    if (input.length < 8) {
      setError('Password must be at least 8 characters.')
      return false
    }
    if (/^\d+$/.test(input)) {
      setError('Password cannot only be numerical values.')
      return false
    }
    return true
  }

  const handlePassClick = () => setShowPass(!showPass)
  const handleReenterClick = () => setShowReenter(!showReenter)

  const submitReset = (e: any) => {
    e.preventDefault()
    if (!passwordIsValid(password)) {
      return
    }
    if (password !== rePassword) {
      setError('Passwords do not match!')
    } else {
      reset({
        variables: {
          email: email?.toLowerCase(),
          password: password,
          rePassword: rePassword,
          token: token,
        },
      })
    }
  }

  useEffect(() => {
    const urlString = window.location.search
    const urlParams = new URLSearchParams(urlString)
    if (urlParams.get('fullName')) {
      const fullName = urlParams.get('fullName')
      if (fullName !== null) {
        setUrlFullName(fullName)
      } else {
        setError('Full name not retrieved.')
      }
    }
  }, [])

  useEffect(() => {
    if (user) {
      // redirect if there is already a user logged in
      if (user?.hasBeenWelcomed) {
        navigate('/dashboard')
      } else {
        navigate('/welcome')
      }
    }
  }, [navigate, user, user?.hasBeenWelcomed])

  return (
    <>
      <Box className="border-b-1 border-indigo-600">
        <Image mt={25} ml={5} w="135px" src={basta_logo_white} alt={'Basta logo'} />
      </Box>
      <Center>
        <Box mt={150} w={'md'}>
          <Center mb={3}>
            <Text fontFamily={'Inter'} fontWeight={'bold'} fontSize={'4xl'}>
              Reset Password
            </Text>
          </Center>

          <Box my={3}>
            <Divider my={8} orientation="horizontal" />
            <form onSubmit={submitReset}>
              <FormControl>
                {urlFullName && (
                  <Input
                    id="full-name"
                    mb={3}
                    isDisabled
                    overflow={'scroll'}
                    color={'#000000'}
                    _placeholder={{ opacity: 1, color: 'brand.lightBlue' }}
                    bg={'white'}
                    value={urlFullName}
                    resize={'horizontal'}
                  />
                )}

                <Input
                  id="email"
                  mb={3}
                  isDisabled
                  overflow={'scroll'}
                  color={'#000000'}
                  _placeholder={{ opacity: 1, color: 'brand.lightBlue' }}
                  bg={'white'}
                  value={email}
                  resize={'horizontal'}
                />

                <FormControl>
                  <InputGroup size="md">
                    <Input
                      type={showPass ? 'text' : 'password'}
                      id="password"
                      color={'#000000'}
                      _placeholder={{ opacity: 1, color: '#E2E8F0' }}
                      bg={'white'}
                      value={password}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      mb={3}
                    />

                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" color={'#000000'} size="sm" onClick={handlePassClick}>
                        {showPass ? <Icon as={FaEyeSlash} /> : <Icon as={FaEye} />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  <InputGroup size="md">
                    <Input
                      type={showReenter ? 'text' : 'password'}
                      id="reenter password"
                      color={'#000000'}
                      _placeholder={{ opacity: 1, color: '#E2E8F0' }}
                      bg={'white'}
                      value={rePassword}
                      placeholder="Re-enter Password"
                      onChange={(e) => setRePassword(e.target.value)}
                      mb={3}
                    />

                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" color={'#000000'} size="sm" onClick={handleReenterClick}>
                        {showReenter ? <Icon as={FaEyeSlash} /> : <Icon as={FaEye} />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                {error && <Text color="red">{error}</Text>}
                <Button
                  w={'100%'}
                  my={8}
                  backgroundColor={'#45A1DC'}
                  color={'white'}
                  type="submit"
                  colorScheme="blue"
                >
                  Reset Password
                </Button>
              </FormControl>
            </form>
          </Box>
        </Box>
      </Center>
    </>
  )
}
