import { createApi } from '@thinknimble/tn-models'
import { axiosInstance } from '../axios-instance'
import { FunctionShape } from './models'

export const functionApi = createApi({
  client: axiosInstance,
  baseUri: 'employment/function/',
  models: {
    entity: FunctionShape,
  },
})
