import React, { useContext, useState } from 'react'
import { SurveyListMultipleOption } from '../../components/Survey/ui/SurveyListOptionInput'
import { Button, Container, Flex, Text } from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa6'
import { useMutation, useQuery } from '@apollo/client'
import { SUBMIT_TEST_USER_STRENGTHS } from 'src/utils/mutations'
import Loading from '../Loading'
import { MatchScoreContext } from 'src/utils/providers/useMatchScore'
import { GET_STRENGTHS } from 'src/utils/queries'

interface StrengthsProps {
  strengthShort: string
  strengthLarge: string
}

export default function SelectStrengthGrid() {
  const [responseValueArray, setResponseValueArray] = useState<string[]>([])
  const [strengths, setStrengths] = useState<StrengthsProps[]>([])
  const { toggleCloseModal, responseId, setStrengthsError, strengthsError, setSuccess } =
    useContext(MatchScoreContext)
  const handleResponseValue = (value: string | string[]) => {
    if (Array.isArray(value)) {
      setResponseValueArray(value)
      return
    }
  }

  const { loading: strengthsLoading } = useQuery(GET_STRENGTHS, {
    onCompleted: (data: any) => {
      setStrengths(data.strengths)
    },
    onError: (error: any) => {
      console.error(error)
    },
  })

  const [submitTestUserStrengths, { loading }] = useMutation(SUBMIT_TEST_USER_STRENGTHS, {
    onCompleted: (data) => {
      toggleCloseModal()
      setSuccess(true)
    },
    onError: (error) => {
      setStrengthsError(error.message)
    },
  })

  const submitStrengths = () => {
    setStrengthsError('')
    submitTestUserStrengths({
      variables: { responseId: responseId, strengths: responseValueArray.join(',') },
    })
  }
  return (
    <Container textAlign={'center'} maxW={'100%'}>
      <Container py={'20px'}>
        <Text>
          To get started, please select your top three strengths from the list below, then you can
          score as many jobs as you’d like.
        </Text>
      </Container>
      <Container maxW={'90%'} py={'20px'}>
        {strengthsLoading ? (
          <Loading />
        ) : (
          <SurveyListMultipleOption
            name={'strengths-select'}
            layout="grid"
            options={strengths.map((x) => {
              return {
                label: x.strengthLarge,
                value: x.strengthShort,
              }
            })}
            optionsCapAmount={3}
            columnsAmount={3}
            onChange={handleResponseValue}
            value={responseValueArray}
          />
        )}
      </Container>
      <Container py={'20px'} maxW={'100%'}>
        <Text color={'#FFFFFFCC'} as={'i'}>
          Please keep in mind that Match Score is new and still in beta mode. As you use Match
          Score, be sure to talk with your coach about how other factors might influence whether to
          prioritize applying for a specific role. Your feedback will help us continue to improve!
        </Text>
      </Container>
      <Flex justifyContent={'space-between'}>
        <Container>
          <Text color={'red'}>{strengthsError}</Text>
        </Container>
        {loading ? (
          <Loading />
        ) : (
          <Button
            variant="solid"
            _hover={{ bg: '#3764b4' }}
            bg={'brand.blue'}
            color={'white'}
            title={'RSVP'}
            py={'24px'}
            px={'15px'}
            rightIcon={<FaArrowRight />}
            onClick={() => submitStrengths()}
          >
            Continue
          </Button>
        )}
      </Flex>
    </Container>
  )
}
