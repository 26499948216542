import React, { useContext, useEffect, useState } from 'react'
import {
  Heading,
  Textarea,
  Button,
  VStack,
  Text,
  Input,
  Spinner,
  Box,
  Select,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import Error from 'src/components/Alerts/Error'
import { GET_MATCH_SCORE, MATCH_SCORE_FEEDBACK } from 'src/utils/mutations'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { GET_TEST_USER, GET_TEST_USERS } from 'src/utils/queries'
import Success from 'src/components/Alerts/Success'
import SelectStrengthsModal from 'src/components/MatchScore/SelectStrengthsModal'
import { MatchScoreContext } from 'src/utils/providers/useMatchScore'
interface MatchScoreProps {
  error: boolean
  success: boolean
  finalMatchScore: string
  resultsString: string
  studentTopDriver: string
  jobMatchResultId: string
  studentInterest: string
  jobIndustry: string
  jobFunction: string
}

export default function MatchScore() {
  const [jobDescription, setJobDescription] = useState('')
  const [matchScore, setMatchScore] = useState<MatchScoreProps>({
    error: false,
    success: false,
    finalMatchScore: '',
    resultsString: '',
    studentTopDriver: '',
    jobMatchResultId: '',
    studentInterest: '',
    jobIndustry: '',
    jobFunction: '',
  })
  const [jobTitle, setJobTitle] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [comments, setComments] = useState('')
  const [agreeLevel, setAgreeLevel] = useState('')
  const [nextStep, setNextStep] = useState([])

  const { setIsStrengthsModalOpen, responseId, setResponseId, success, setSuccess } =
    useContext(MatchScoreContext)

  const [getTestUser, { loading: testUserLoading, error: fetchUserError, data: testUserData }] =
    useLazyQuery(GET_TEST_USER)

  const [matchScoreMutation, { loading }] = useMutation(GET_MATCH_SCORE, {
    onCompleted: (data) => {
      setMatchScore(data.matchScore)
    },
    onError: (error) => {
      setError(error.message)
    },
  })

  const [feedbackMutation, { loading: feedbackLoading }] = useMutation(MATCH_SCORE_FEEDBACK, {
    onCompleted: (data) => {
      setError(null)
      setSuccess(true)
      setTimeout(() => setSuccess(false), 5000)
    },
    onError: (error) => {
      setError(error.message)
      setSuccess(false)
      setTimeout(() => setError(null), 5000)
    },
  })

  const sendMatchScoreMutation = (jobDescription: string) => {
    if (!responseId) {
      setError('Please select a user.')
      return
    }
    if (!jobTitle || !jobDescription) {
      setError('Please enter a job title/description')
      return
    }
    matchScoreMutation({
      variables: {
        jobDescription,
        responseId,
        jobTitle,
      },
    })
  }

  const sendFeedback = () => {
    const nextStepString = nextStep.join()
    feedbackMutation({
      variables: {
        agreeLevel: agreeLevel,
        comments: comments,
        jobMatchId: matchScore.jobMatchResultId,
        nextStep: nextStepString,
      },
    })
  }
  const nextStepChecks = (values: []) => {
    setNextStep(values)
  }
  const { data } = useQuery(GET_TEST_USERS)

  const renderTestUsers = () => {
    const users = data?.testUserData
    return (
      <Select
        color={'#1D2433'}
        backgroundColor={'white'}
        placeholder="Select your name"
        onChange={(e) => {
          setResponseId(JSON.parse(e.target.value).responseId)
          getTestUser({ variables: { responseId: JSON.parse(e.target.value).responseId } })
        }}
      >
        {users?.map((user: any, index: number) => {
          return (
            <option value={JSON.stringify(user)} key={index}>
              {user.name}
            </option>
          )
        })}
      </Select>
    )
  }

  useEffect(() => {
    if (testUserData?.testUser?.strengths.split(',').length < 3) {
      setIsStrengthsModalOpen(true)
    }
  }, [setIsStrengthsModalOpen, testUserData])

  return (
    <VStack mx={14} spacing={5}>
      <SelectStrengthsModal />
      {error && <Error errorMessage={error} setError={setError} />}
      {success && <Success />}
      <Heading mt={20} size={'xl'}>
        Job Match Score
      </Heading>

      <Text align={'center'} width={'50%'}>
        Enter a job description below to generate your personal match score. Your match score will
        help determine if the job is a good fit for you based on your profile.
      </Text>
      <Box mb={'32px'} width={'50%'}>
        <Text mb={'16px'} fontSize="xl">
          Name
        </Text>
        {renderTestUsers()}
      </Box>

      <Box mb={'32px'} width={'50%'}>
        <Text mb={'16px'} fontSize="xl">
          Job Title
        </Text>
        <Input
          color={'#1D2433'}
          _placeholder={{ opacity: 1, color: '#1D2433' }}
          backgroundColor={'white'}
          value={jobTitle}
          onChange={(e) => {
            setJobTitle(e.target.value)
          }}
          placeholder="Enter job title"
        />
      </Box>

      <Box mb={'32px'} width={'50%'}>
        <Text mb={'16px'} fontSize="xl">
          Job Description
        </Text>
        <Textarea
          color={'#1D2433'}
          _placeholder={{ opacity: 1, color: '#1D2433' }}
          backgroundColor={'white'}
          height={'180px'}
          value={jobDescription}
          onChange={(e) => {
            setJobDescription(e.target.value)
          }}
          placeholder="Paste in the job description"
          size="md"
        />
      </Box>
      {loading ? (
        <Spinner />
      ) : (
        <Flex flexDirection={'column'} width={'50%'}>
          <Button
            backgroundColor={'#2F80ED'}
            mr={'20px'}
            mb={'30px'}
            w="full"
            color="white"
            onClick={() => sendMatchScoreMutation(jobDescription)}
          >
            Submit
          </Button>
          {matchScore.success ? (
            <Box mb={'30px'}>
              <Box border={'2px solid #A649FF'} color={'#A649FF'} p={2} mb={'30px'}>
                <Text>
                  Quick Reminder! This job matching feature is new and still in beta. Right now, it
                  uses AI to see if a role would be a good match for you, given the field you're
                  interested in &#40; {matchScore?.studentInterest} &#41; and your primary driver{' '}
                  &#40; {matchScore?.studentTopDriver} &#41;.
                </Text>
                <Text mt={5}>
                  This tool does not check whether the role is entry level, nor does it factor in
                  any other preferences or your qualifications yet, so be sure to talk with your
                  coach about how other attributes might influence whether this is a role to
                  prioritize applying for. And, if your interest or top driver doesn't look quite
                  right, or has changed since you last took Seekr, just let your coach know.
                </Text>
              </Box>
              <Text>
                Result: <b>{matchScore?.resultsString}</b>
              </Text>
              <Text mt={2}>
                Final Match Score: <b>{matchScore?.finalMatchScore}</b>
              </Text>
              <Text color={'brand.navy'} mt={2}>
                Job Industry: <b>{matchScore?.jobIndustry}</b>
              </Text>
              <Text color={'brand.navy'} mt={2}>
                Job Function: <b>{matchScore?.jobFunction}</b>
              </Text>

              <Box w="full">
                <Text textAlign={'center'} my={5} fontSize={'xl'} fontWeight={'bold'}>
                  Give us Feedback
                </Text>
                <FormControl isRequired={true}>
                  <Box mb={'32px'}>
                    <FormLabel mb={'16px'}>What is your next step?</FormLabel>
                    <CheckboxGroup value={nextStep} onChange={nextStepChecks}>
                      <Stack spacing={[1, 5]} direction={['column', 'column']}>
                        <Checkbox value="apply">I plan to apply</Checkbox>
                        <Checkbox value="notApply">I do not plan to apply</Checkbox>
                        <Checkbox value="discuss">I will discuss with my coach</Checkbox>
                        <Checkbox value="other">Other</Checkbox>
                      </Stack>
                    </CheckboxGroup>
                  </Box>
                </FormControl>
                <FormControl isRequired={true}>
                  <Box mb={'32px'}>
                    <FormLabel mb={'16px'}>
                      To what extent do you agree with this recommendation?
                    </FormLabel>
                    <RadioGroup onChange={setAgreeLevel} value={agreeLevel}>
                      <Stack direction="row">
                        <Text>Strongly Disagree</Text>
                        <Radio value="1">1</Radio>
                        <Radio value="2">2</Radio>
                        <Radio value="3">3</Radio>
                        <Radio value="4">4</Radio>
                        <Radio value="5">5</Radio>
                        <Text>Strongly Agree</Text>
                      </Stack>
                    </RadioGroup>
                  </Box>
                </FormControl>
                <Box mb={'32px'}>
                  <Text mb={'16px'}>Share any feedback or questions about this score.</Text>
                  <Textarea
                    color={'#1D2433'}
                    _placeholder={{ opacity: 1, color: '#1D2433' }}
                    backgroundColor={'white'}
                    height={'180px'}
                    value={comments}
                    onChange={(e) => {
                      setComments(e.target.value)
                    }}
                    size="md"
                  />
                </Box>
                <Button
                  backgroundColor={'#2F80ED'}
                  mr={'20px'}
                  mb={'50px'}
                  w="full"
                  color="white"
                  onClick={() => sendFeedback()}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Flex>
      )}
    </VStack>
  )
}
