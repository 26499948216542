import Text from './Text'

const components = {
  Text,
  Tabs: {
    variants: {
      line: {
        tab: {
          _selected: {
            borderBottom: '2px solid',
            borderColor: 'black',
          },
          _hover: {
            borderColor: 'black',
          },
        },
        tablist: {
          borderBottom: '1px solid',
          borderColor: 'gray.200',
        },
      },
    },
  },
}

export default components
