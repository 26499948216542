import { useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Image,
  keyframes,
  Text,
  Spinner,
} from '@chakra-ui/react'
import React, { ReactNode, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import grainOverlay from 'src/assets/images/grain_overlay.svg'
import greenCircles from 'src/assets/images/green_circles.svg'
import pinkCircles from 'src/assets/images/pink_circles.svg'
import sun from 'src/assets/images/sun.png'
import { AuthContext } from 'src/utils/auth'
import { GET_USER_PROFILE_INFO } from 'src/utils/queries'
import { ReportData } from 'src/utils/types'
import { LogOutButton } from 'src/components'

interface LayoutProps {
  children: ReactNode
  reportData: ReportData | undefined
}
export default function Layout({ children, reportData }: LayoutProps) {
  const { token, user } = useContext(AuthContext)
  const isLoggedIn = Boolean(token)
  const fadeIn = keyframes({
    '0%': { filter: 'brightness(0.1)', style: { zIndex: '0' } },
    '100%': { filter: 'brightness(1)', style: { zIndex: '0' } },
  })
  function convertDate() {
    var createdTime = reportData?.CreatedTime
    let date = createdTime ? new Date(createdTime).toLocaleDateString() : ''
    return date
  }
  const navigate = useNavigate()

  const { loading: isProfileLoading, data } = useQuery(GET_USER_PROFILE_INFO, {
    onError: (error) => {
      console.error(error)
    },
  })

  const handleGoToDashboard = () => {
    if (data && !data?.me.hasBeenWelcomed) {
      return navigate('/debrief-checkpoint')
    }
    return navigate('/dashboard')
  }

  return (
    <Box
      textColor={'white'}
      fontSize="14px"
      bg={'linear-gradient(0deg, rgba(41,90,119,1) 0%, rgba(28,28,28,1) 98%)'}
      minH={'1800px'}
    >
      {isLoggedIn ? (
        <>
          <HStack
            zIndex={'10'}
            position={'fixed'}
            w="full"
            bg="brand.blue"
            justifyContent="space-around"
          >
            <Box p={'24px 100px 24px 100px'}>
              <Text color="white">
                After reviewing your results, navigate to your personalized dashboard to embark on
                your next mission
              </Text>
            </Box>
            <HStack>
              {isProfileLoading || !data ? (
                <Spinner size={'sm'} p={'10px'} />
              ) : (
                <Button
                  fontWeight={'light'}
                  bg={'white'}
                  color={'black'}
                  border={'1px solid #B3B3B3'}
                  p={'10px 12px 10px 12px'}
                  onClick={handleGoToDashboard}
                >
                  Go to dashboard
                </Button>
              )}
              <LogOutButton />
            </HStack>
          </HStack>
          <Box p={'40px'} />{' '}
        </>
      ) : null}
      <Box bgImage={grainOverlay} position={'absolute'} zIndex={1} height={'100%'} w={'100%'} />
      <Box m="auto" maxW={'950px'} position="relative">
        <Image
          src={sun}
          position={'absolute'}
          zIndex={0}
          top={0}
          animation={`${fadeIn} 7s forwards`}
          alt=""
        />
        <Grid
          gridTemplateColumns={'repeat(2, 1fr)'}
          gridAutoFlow={'row dense'}
          gap="1"
          px={5}
          py={{ base: 0, md: 5 }}
          zIndex={3}
          position="relative"
        >
          <GridItem
            display="flex"
            flexDir={{ base: 'column', md: 'row' }}
            px={0}
            py={5}
            colSpan={2}
            color="white"
            justifyContent={'space-between'}
          >
            <Image
              zIndex={1}
              w="150px"
              h="40px"
              src="https://webmerge-public-files.s3-us-west-2.amazonaws.com/accounts/102349/ck/files/logo.png"
              alt={'Basta logo'}
            />
            <Box textAlign={'right'}>
              <Text>
                Prepared for: <span style={{ fontWeight: 'bold' }}>{reportData?.Name}</span>{' '}
              </Text>
              <Text>
                Created on: <span style={{ fontWeight: 'bold' }}>{convertDate()}</span>
              </Text>
            </Box>
          </GridItem>
        </Grid>
        {children}
      </Box>

      {/* <Image src={pinkCircles} top={0} transform={"translateY(150%)"} position={"absolute"} w={"150px"} />
      <Image
        src={greenCircles}
        top={0}
        transform={"translateY(110%)"}
        position={"absolute"}
        w={"300px"}
        right={0}
      /> */}

      <Image
        src={pinkCircles}
        top={0}
        transform={{ md: 'translateY(150%)', base: 'translateY(130%)' }}
        position={'absolute'}
        w={'150px'}
        alt=""
      />
      <Image
        src={greenCircles}
        top={0}
        transform={{ md: 'translateY(110%)', base: 'translateY(125%)' }}
        position={'absolute'}
        w={'300px'}
        right={0}
        alt=""
      />
    </Box>
  )
}
