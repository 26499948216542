import React from 'react'
import { Icon, Text, Stack } from '@chakra-ui/react'
import { FaArrowRightArrowLeft } from 'react-icons/fa6'

export default function CareerSwipeLogo() {
  return (
    <Stack mb={'30px'} direction={{ sm: 'row', base: 'column' }} align={'center'}>
      <Icon
        as={FaArrowRightArrowLeft}
        background={'white'}
        borderRadius={'50%'}
        color={'brand.navy'}
        width={'30px'}
        height={'30px'}
        p={'10px'}
      />
      <Text
        textTransform={'none'}
        fontFamily={'sans-serif'}
        color="white"
        fontSize={'3xl'}
        fontWeight="700"
      >
        Career Swipe
      </Text>
    </Stack>
  )
}
