const foundations = {
  colors: {
    brand: {
      100: '#00A3E1',
      200: '#94C456',
      300: '#D04D9D',
      400: '#FFC507',
      blue: '#2d70e7',
      darkBlue: '#2F80ed',
      navy: '#1A202C',
      yellow: '#d6bd72',
      lightBlue: '#4e6584',
      swamp: '#8b7c53',
      green: '#a4b96c',
      white: '#EAF2FD',
      gray: '#333947',
      lightGray: '#637381',
      neutral: '#CCCCCC',
      text: '#414651',
      purple: '#9B229B',
      orange: '#D14F28',
      hoverOrange: '#542010',
      surveyCardGray: '#4A4F5B',
    },
  },
  fonts: {
    body: `'Karla', 'sans-serif'`,
    heading: `'Intro', 'sans-serif'`,
    subHeading: `'Inter, 'sans-serif`,
  },
}

export default foundations
