import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Spinner,
} from '@chakra-ui/react'
import React, { FC, useContext, useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { AuthContext, logout } from 'src/utils/auth'
import Logo from './Logo'
import { useQuery } from '@apollo/client'
import { GET_USER_PROFILE_INFO } from 'src/utils/queries'

export const Header: FC = () => {
  const { updateToken, token } = useContext(AuthContext)
  const isLoggedIn = Boolean(token)
  const navigate = useNavigate()
  const [fullName, setFullName] = useState('')
  const [initials, setInitials] = useState('')
  const { data } = useQuery(GET_USER_PROFILE_INFO, {
    skip: !isLoggedIn,
    onCompleted: (data) => {
      if (data?.me) {
        setFullName(`${data?.me?.firstName} ${data?.me?.lastName}`)
        setInitials(`${data?.me?.firstName?.[0]}${data?.me?.lastName?.[0]}`)
      }
    },
    onError: (error) => {
      console.log('error on page header')
    }
  })

  const logUserOut = () => {
    logout()
    updateToken(null)
    navigate('/log-in')
  }
  const goToProfile = () => {
    navigate('/profile')
  }
  const goToSessions = () => {
    navigate('/sessions')
  }

  return (
    <HStack justify="space-between" w="full" px={3}>
      <Box py={4}>
        <Logo m={0} />
      </Box>
      {isLoggedIn ? (
        <Menu>
          <MenuButton as={Button} variant="none" p={0} m={0}>
            <HStack justifyContent={'end'} py="2" w="full">
              <HStack spacing={2} bg="#2a2e39" px="4" py="2" rounded="full" alignItems={'center'}>
                {data?.me?.firstName || data?.me?.lastName ? (
                  <>
                    <Flex
                      rounded={'full'}
                      w={'8'}
                      h={'8'}
                      display={{
                        base: 'none',
                        md: 'flex',
                      }}
                      alignItems={'center'}
                      justify="center"
                      bg={'brand.neutral'}
                    >
                      <Text
                        color="brand.text"
                        fontFamily="sans-serif"
                        fontWeight="bold"
                        textTransform="uppercase"
                        fontSize={'md'}
                        textAlign={'center'}
                      >
                        {initials}
                      </Text>
                    </Flex>
                    <Box>
                      <Text
                        color="brand.white"
                        fontFamily="sans-serif"
                        textTransform="capitalize"
                        fontSize={{
                          base: 'xs',
                          md: 'md',
                        }}
                      >
                        {fullName}
                      </Text>
                    </Box>
                    <Flex alignItems={'center'}>
                      <Icon as={FaChevronDown} color={'brand.white'} />
                    </Flex>
                  </>
                ) : (
                  <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="current"
                    size="md"
                  />
                )}
              </HStack>
            </HStack>
          </MenuButton>
          <MenuList>
            {' '}
            {data?.me?.responseId && (
              <>
                <MenuItem onClick={goToProfile}>Profile</MenuItem>
                <MenuItem onClick={goToSessions}>Sessions</MenuItem>
              </>
            )}
            <MenuItem onClick={logUserOut}>Logout</MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <></>
      )}
    </HStack>
  )
}
