import React, { useState, useContext } from 'react'
import { Text, Box, Input, Button, FormControl } from '@chakra-ui/react'
import { AuthContext } from 'src/utils/auth'
import { CareerSwipeContext } from 'src/utils/providers/useCareerSwipe'

const missionNumber = 2

export default function Two() {
  const { user } = useContext(AuthContext)
  const { setCareerSwipeStep, setError, submitCheckpoint } = useContext(CareerSwipeContext)
  const [answer, setAnswer] = useState('')
  const question = 'Enter one new job title you learned of'

  const handleChange = (e: any) => {
    let inputValue = e.target.value
    setAnswer(inputValue)
  }

  const submitAnswer = () => {
    if (!answer) {
      setError('Please enter a job title.')
    } else {
      setCareerSwipeStep(6)
    }
    submitCheckpoint({
      variables: {
        missionNumber: missionNumber,
        question: question,
        answer: answer,
        responseId: user?.responseId,
      },
    })
  }

  return (
    <>
      <Text
        textTransform={'none'}
        fontFamily={'sans-serif'}
        color="white"
        fontSize={'2xl'}
        fontWeight="700"
        mt={'30px'}
      >
        {question}
      </Text>

      <Box mt={'30px'} h={'425px'}>
        <FormControl as="fieldset">
          <Input
            value={answer}
            onChange={handleChange}
            placeholder="Type answer..."
            focusBorderColor="brand.purple"
          ></Input>
          <Button
            disabled={answer ? false : true}
            mt={'50px'}
            variant="solid"
            w={'111px'}
            bg={answer ? 'brand.blue' : 'brand.neutral'}
            color={'white'}
            title={'Next'}
            onClick={() => submitAnswer()}
          >
            Next
          </Button>
        </FormControl>
      </Box>
    </>
  )
}
