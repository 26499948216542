import { Box, Button, Center, Heading, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const NotFound = () => {
  const navigate = useNavigate()
  return (
    <Center>
      <Box
        py={3}
        display="flex"
        alignItems="center"
        justifyContent={{
          base: 'center',
          sm: 'start',
        }}
        flexShrink={1}
      >
        <VStack gap={3}>
          <Heading size="2xl" data-testid="welcome-title">
            404 - Page Not Found 😅
          </Heading>
          <Text>The page you are looking for does not exist.</Text>

          <Button
            w={'100%'}
            my={8}
            backgroundColor={'#45A1DC'}
            color={'white'}
            colorScheme="blue"
            data-testid="home-btn"
            onClick={() => navigate('/')}
          >
            Back to Home
          </Button>
        </VStack>
      </Box>
    </Center>
  )
}
