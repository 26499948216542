import React, { useContext, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { GET_USER_PROFILE_INFO } from 'src/utils/queries'

import Checkpoint from 'src/components/Checkpoint'
import CheckpointOne from 'src/components/RSVP/CheckpointOne'
import CheckpointTwo from 'src/components/RSVP/CheckpointTwo'
import { WelcomeContext } from 'src/utils/providers/useWelcome'

export default function Scheduler() {
  const { checkpointStep, error } = useContext(WelcomeContext)

  const navigate = useNavigate()

  const { loading: isProfileLoading, data } = useQuery(GET_USER_PROFILE_INFO, {
    onCompleted: (data) => {
      if (!data?.me?.responseId) {
        navigate('/survey/seekr/?email=' + encodeURIComponent(data?.me?.email??"" ))
    }},
    onError: (error) => {
      console.error(error)
    },
  })

  const displayMissionStep = () => {
    switch (checkpointStep) {
      case 1:
        return (
          <Checkpoint error={error}>
            <CheckpointOne />
          </Checkpoint>
        )
      case 2:
        return (
          <Checkpoint error={error}>
            <CheckpointTwo />
          </Checkpoint>
        )
      default:
        return (
          <Checkpoint error={error}>
            <CheckpointOne />
          </Checkpoint>
        )
    }
  }

  useEffect(() => {
    //don't allow users to visit if they have already been welcomed
    if (data?.me.hasBeenWelcomed) {
      navigate('/dashboard')
    }
  }, [data?.me.hasBeenWelcomed, data?.me?.responseId, navigate])

  return displayMissionStep()
}
