import React, { useState } from 'react'
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { GET_INSTANCES } from 'src/utils/queries'
import { Loading } from 'src/components'
import { Export } from 'src/pages/Exports'

type Instance = {
  name: string
}

export default function SelectInstanceModal({
  isOpen,
  onClose,
  sendToAirtable,
  selectedExport,
}: {
  isOpen: boolean
  onClose: () => void
  sendToAirtable: (ex: Export, instanceName: string) => void
  selectedExport: any
}) {
  const [selectedInstanceName, setSelectedInstanceName] = useState<string>('')
  const { data, loading } = useQuery(GET_INSTANCES)

  if (loading) {
    return <Loading />
  }

  const renderInstanceOptions = () => {
    const instances = data?.airtableData?.instances

    return (
      <ModalBody>
        <FormControl isRequired>
          <Select
            onChange={(e: any) => setSelectedInstanceName(e.target.value)}
            placeholder="Select Instance column value"
          >
            {instances?.map((instance: any, index: number) => {
              return (
                <option value={instance.name} key={index}>
                  {instance.name}
                </option>
              )
            })}
          </Select>
        </FormControl>
      </ModalBody>
    )
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="56rem">
          <ModalHeader>Set Instance Label</ModalHeader>
          <ModalCloseButton />

          {renderInstanceOptions()}

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() => sendToAirtable(selectedExport, selectedInstanceName)}
              variant="ghost"
            >
              Send Data
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
