import { Button, Center, Text } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function ThankYouModal() {
  const navigate = useNavigate()
  return (
    <Center flexDirection={'column'}>
      <Text
        textTransform={'none'}
        fontFamily={'sans-serif'}
        color="white"
        fontSize={'2xl'}
        fontWeight="700"
      >
        Thanks for playing!
      </Text>
      <Button
        variant="solid"
        bg={'brand.blue'}
        color={'white'}
        title={'RSVP'}
        mt={'20px'}
        onClick={() => navigate('/dashboard')}
      >
        Go to Dashboard
      </Button>
    </Center>
  )
}
