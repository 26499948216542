import React from 'react'
import { Grid, GridItem, Text, Image, keyframes } from '@chakra-ui/react'
import strengthArrow from 'src/assets/images/strength_arrow.svg'
import { alignmentStrengths } from 'src/utils/reportContent'

const spin = keyframes`
from { transform: rotate(0deg); }
to { transform: rotate(90deg); }
`

const spin2 = keyframes`
from { transform: rotate(90deg); }
to { transform: rotate(0deg); }
`
export default function StrengthsList(props: { strengthsList: string | undefined }): JSX.Element {
  const animation = `${spin} 1s linear forwards`
  const animation2 = `${spin2} 1s linear forwards`

  function getStrengths() {
    let A29_array = props?.strengthsList?.split(',')
    let keyword = ''
    // the data for the below strength is separated into two array values
    if (A29_array?.includes(' Changing priorities well')) {
      let adaptPart1Index = A29_array?.indexOf('Adapting to fast-paced')
      let adaptPart2Index = A29_array?.indexOf(' Changing priorities well')
      A29_array?.splice(adaptPart2Index, 1)
      A29_array?.splice(adaptPart1Index, 1)
      A29_array.push('Adapting to fast-paced, changing priorities well')
    }
    let map = A29_array?.map((strength, i) => {
      let formatted = strength.replace(/^\s/, '')

      switch (formatted) {
        case alignmentStrengths.strength1.strength:
          keyword = alignmentStrengths.strength1.keyword
          break
        case alignmentStrengths.strength2.strength:
          keyword = alignmentStrengths.strength2.keyword
          break
        case alignmentStrengths.strength3.strength:
          keyword = alignmentStrengths.strength3.keyword
          break
        case alignmentStrengths.strength4.strength:
          keyword = alignmentStrengths.strength4.keyword
          break
        case alignmentStrengths.strength5.strength:
          keyword = alignmentStrengths.strength5.keyword
          break
        case alignmentStrengths.strength6.strength:
          keyword = alignmentStrengths.strength6.keyword
          break
        case alignmentStrengths.strength7.strength:
          keyword = alignmentStrengths.strength7.keyword
          break
        case alignmentStrengths.strength8.strength:
          keyword = alignmentStrengths.strength8.keyword
          break
        case alignmentStrengths.strength9.strength:
          keyword = alignmentStrengths.strength9.keyword
          break
        case alignmentStrengths.strength10.strength:
          keyword = alignmentStrengths.strength10.keyword
          break
        case 'Adapting to fast-paced, changing priorities well':
          keyword = alignmentStrengths.strength11.keyword
          break
        case alignmentStrengths.strength12.strength:
          keyword = alignmentStrengths.strength12.keyword
          break
        case alignmentStrengths.strength13.strength:
          keyword = alignmentStrengths.strength13.keyword
          break
        case alignmentStrengths.strength14.strength:
          keyword = alignmentStrengths.strength14.keyword
          break
      }

      return (
        <Grid
          key={i}
          mb={5}
          gridTemplateColumns="repeat(3, 1fr)"
          fontSize={{ base: '14px', md: '16px' }}
          mx={{ base: 0, md: 10 }}
          px={{ base: 0, md: 2 }}
          pt={5}
          borderTop={'1px solid #CDC9C9'}
        >
          <GridItem alignSelf={'center'} colSpan={{ base: 3, md: 1 }} textAlign={'center'}>
            <Text>{strength}</Text>
          </GridItem>
          <GridItem
            colSpan={{ base: 3, md: 1 }}
            justifySelf="center"
            my={{ base: 3, md: 0 }}
            alignSelf="center"
          >
            <Image
              w={{ base: 'initial', md: '30px' }}
              mx={5}
              animation={{ base: animation, md: animation2 }}
              src={strengthArrow}
              alt={'Strength arrow'}
            />
          </GridItem>
          <GridItem alignSelf={'center'} colSpan={{ base: 3, md: 1 }} textAlign={'center'}>
            <Text>{keyword}</Text>
          </GridItem>
        </Grid>
      )
    })
    return map
  }
  return <>{getStrengths()}</>
}
