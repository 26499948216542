// https://chakra-ui.com/docs/theming/customize-theme
import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import foundations from './foundations'
import components from './components'

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

// @ts-ignore
const theme = extendTheme({
  config,
  components,
  ...foundations,
})

export default theme
