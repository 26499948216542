import React from 'react'
import { Text, Box, Image, Flex } from '@chakra-ui/react'
import { Data } from 'src/utils/types'
import alignmentCircle from 'src/assets/images/alignment_circle.svg'

export default function ExperienceTypes({ reportData }: Data) {
  const A26_array = reportData?.DMS3A26ImageFromA26ExperienceLink
    ? reportData?.DMS3A26ImageFromA26ExperienceLink?.split(',')
    : []

  function experienceImages() {
    let image = A26_array.map((url, i) => {
      if (url !== 'None') {
        return (
          <Image
            w={{ base: 'fill-available', md: '80%' }}
            my={2}
            mx={{ base: 5, md: 'auto' }}
            src={url}
            key={i}
            alt={'Experience'}
          />
        )
      }
    })
    return image
  }
  let splitText = reportData?.A26Text.split('!')
  let firstSentence = splitText?.shift()
  let newDescription = splitText?.join('.')
  return (
    <Box mr={5}>
      <Flex>
        <Text fontSize={20} fontWeight={'bold'} pr={2}>
          Explore roles that might fit
        </Text>

        <Image w="30px" src={alignmentCircle} alt={'Alignment circle icon'} />
      </Flex>
      <Text my={5}>{firstSentence}!</Text>

      {A26_array.length > 0 ? (
        <>
          <Text>
            These aren't the only roles you could pursue and they may not be aligned with your
            current interest, but you may have a leg up on other candidates for these.
          </Text>

          <Text my={5}>{newDescription}</Text>
          <Box>{experienceImages()}</Box>
        </>
      ) : (
        <></>
      )}
    </Box>
  )
}
