import { z } from 'zod'
import { readonly } from '@thinknimble/tn-models'
import { JobShape } from '../jobs'

export const JobActivityShape = {
  id: readonly(z.string()),
  job: z.string(),
  recommended: z.string(),
  interested: z.string(),
  status: z.string(),
  substatus: z.string(),
  dateApplied: z.string(),
  applied: z.boolean(),
  userRef: z.object({ firstName: z.string(), lastName: z.string(), email: z.string() }),
  jobRef: z.object(JobShape),
}
