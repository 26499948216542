import { z } from 'zod'
import { readonly } from '@thinknimble/tn-models'
import { OrganizationShape } from '../organizations'
import { LocationShape } from '../locations'

export const JobCreateShape = {
  title: z.string(),
  organization: z.string(),
}

export const JobShape = {
  id: readonly(z.string()),
  ...JobCreateShape,
  organizationRef: z.object(OrganizationShape),
  locationRef: z.object(LocationShape),
}
