import {
  Box,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  SimpleGrid,
  Center,
  Container,
  Stack,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  VStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Checkbox,
  CheckboxGroup,
  Tag,
  TagCloseButton,
  TagLabel,
  Flex,
  Icon,
  Divider,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { PiMagnifyingGlassLight } from 'react-icons/pi'
import { IoIosRocket, IoMdThumbsUp } from 'react-icons/io'
import { CiBookmark } from 'react-icons/ci'
import { Header, Loading } from 'src/components'
import JobModal from 'src/components/JobBoard/JobModal'
import JobCard from 'src/components/JobBoard/JobCard'
import { useListJobs } from 'src/services/jobs/hooks'
import { useListFunctions } from 'src/services/functions/hooks'
import { useListIndustries } from 'src/services/industries/hooks'
import ApplicationsTab from 'src/components/JobBoard/ApplicationsTab'
import ToApplyTab from 'src/components/JobBoard/ToApplyTab'
import { Pagination } from '@thinknimble/tn-models'
import { JobProps } from 'src/utils/types'

interface IndustryFunctionProps {
  id: string
  title: string
}

export default function JobBoard() {
  const maxPageSize = 9
  const [tabIndex, setTabIndex] = useState(0)
  const [pagesQuantity, setPagesQuantity] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState(new Pagination({ page: 1, size: maxPageSize }))
  const [searchInput, setSearchInput] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false)
  const [isIndustryOpen, setIsIndustryOpen] = useState(false)
  const [isFunctionOpen, setIsFunctionOpen] = useState(false)
  const [selectedJobIndustries, setSelectedJobIndustries] = useState<string[]>([])
  const [selectedJobFunctions, setSelectedJobFunctions] = useState<string[]>([])
  const [selectedJob, setSelectedJob] = useState<JobProps>({
    title: '',
    organizationRef: { title: '', industry: '', activePartner: false, logo: '' },
    applicationLink: '',
    description: '',
    closeDate: '',
    datePosted: '',
    locationRef: { title: '' },
    function: '',
    active: '',
    jobType: '',
    startDate: '',
  })

  const toggleFilters = (setFunc: any, value: boolean) => {
    setFunc(!value)
  }

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  const {
    data: allJobsData,
    refetch,
    isLoading,
  } = useListJobs({
    industries: selectedJobIndustries?.map((industry) => JSON.parse(industry).id).join(),
    jobFunctions: selectedJobFunctions
      ?.map((functionStrObj) => JSON.parse(functionStrObj).id)
      .join(),
    search: searchInput,
    pagination,
  })

  const { data: jobIndustries, isLoading: jobIndustriesLoading } = useListIndustries()
  const { data: jobFunctions, isLoading: jobFunctionsLoading } = useListFunctions()

  const toggleOpenJobModal = (job: JobProps) => {
    setSelectedJob(job)
    setIsOpen(true)
  }

  const checkOffIndustryFilters = (values: string[]) => {
    setSelectedJobIndustries(values)
  }
  const checkOffFunctionFilters = (values: string[]) => {
    setSelectedJobFunctions(values)
  }

  const handleSearchInputChange = (e: any) => {
    let inputValue = e.target.value
    setSearchInput(inputValue)
  }

  const handleRemoveFilter = (item: string, valuesList: string[], setStateFunc: any) => {
    setStateFunc(valuesList.filter((value) => value !== item))
  }

  const handleChangePage = (page: number) => {
    setCurrentPage(page)
    setPagination(new Pagination({ page: page, size: maxPageSize }))
  }

  useEffect(() => {
    refetch()
  }, [searchInput, selectedJobIndustries, selectedJobFunctions, refetch])

  useEffect(() => {
    if (allJobsData?.results?.length) {
      const pagesTotal = Math.ceil(allJobsData?.count / maxPageSize)
      setPagesQuantity(pagesTotal)
    } else {
      setPagesQuantity(1)
    }
  }, [allJobsData])

  const PaginationButtons = () => {
    return (
      <HStack>
        {Array.from({ length: pagesQuantity }, (v, k) => k + 1).map((page, i) => (
          <Button
            w={'18px'}
            borderRadius={'20px'}
            color={currentPage === page ? 'white' : 'black'}
            backgroundColor={currentPage === page ? 'brand.darkBlue' : 'white'}
            _hover={{ background: 'brand.blue', opacity: '70%' }}
            key={i}
            onClick={() => handleChangePage(page)}
          >
            {page}
          </Button>
        ))}
      </HStack>
    )
  }

  const FilterPopover = ({
    menuItems,
    children,
    isOpen,
    onOpen,
    onClose,
    placement,
    selectedItems,
    setSelectedItems,
    itemsLoading,
  }: {
    menuItems: any
    children: any
    isOpen: boolean
    onOpen: any
    onClose: any
    placement: any
    selectedItems: string[]
    setSelectedItems: any
    itemsLoading: boolean
  }) => {
    return (
      <Popover
        placement={placement}
        modifiers={[
          { name: 'preventOverflow', options: { padding: 0 } },
          { name: 'flip', enabled: false },
        ]}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        isLazy
        lazyBehavior="keepMounted"
      >
        <HStack>
          <PopoverTrigger>{children}</PopoverTrigger>
        </HStack>

        <PopoverContent>
          <PopoverBody bgColor={'white'} borderRadius={'6px'}>
            <Box
              pr={'10px'}
              overflowY={'scroll'}
              sx={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                  borderRadius: '2px',
                  backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: `rgba(0, 0, 0, 0.05)`,
                },
              }}
              h={'400px'}
            >
              <CheckboxGroup value={selectedItems} onChange={setSelectedItems}>
                {itemsLoading ? (
                  <Loading />
                ) : (
                  <Stack mt={1} spacing={1}>
                    {menuItems?.results.map((item: IndustryFunctionProps, i: number) => (
                      <Checkbox
                        key={i}
                        value={JSON.stringify(item)}
                        display="flex"
                        flexDirection="row-reverse"
                        justifyContent="space-between"
                        h={'40px'}
                        textTransform={'none'}
                        borderColor={'#DFE4EA'}
                        lineHeight={'17px'}
                        onFocus={(e) => e.preventDefault()}
                        onChange={(e) => e.stopPropagation()}
                      >
                        <span>
                          <Text fontWeight="400" fontFamily={'Inter'} color="black" fontSize="14px">
                            {item?.title}
                          </Text>
                        </span>
                      </Checkbox>
                    ))}{' '}
                  </Stack>
                )}
              </CheckboxGroup>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  }

  const Filters = () => {
    return (
      <Center
        my={'24px'}
        border={'1px solid #DFE4EA'}
        justifyContent={'space-between'}
        padding={'16px 24px 16px 24px'}
        bgColor={'rgba(29, 36, 51, 0.03)'}
        borderRadius={'8px'}
        flexWrap={'wrap'}
      >
        <HStack>
          <FilterPopover
            menuItems={jobIndustries}
            isOpen={isIndustryOpen}
            onOpen={toggleFilters}
            onClose={toggleFilters}
            placement="bottom-end"
            selectedItems={selectedJobIndustries}
            setSelectedItems={checkOffIndustryFilters}
            itemsLoading={jobIndustriesLoading}
          >
            <Button
              minW="141px"
              h={'56px'}
              color={'#1D2433'}
              border="1px solid #DFE4EA"
              borderRadius={'100px'}
              backgroundColor={'white'}
              fontWeight={'400'}
              placeholder="Industry"
              rightIcon={
                isIndustryOpen ? (
                  <Icon as={RiArrowDropUpLine} boxSize={8} />
                ) : (
                  <>
                    {selectedJobIndustries?.length > 0 && (
                      <Flex
                        w={'25px'}
                        h={'25px'}
                        alignSelf={'center'}
                        borderRadius={'50%'}
                        color={'white'}
                        backgroundColor={'black'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Text alignSelf={'center'} color={'white'} backgroundColor={'black'}>
                          {selectedJobIndustries?.length}
                        </Text>
                      </Flex>
                    )}
                    <Icon as={RiArrowDropDownLine} boxSize={8} />
                  </>
                )
              }
              onClick={() => toggleFilters(setIsIndustryOpen, isIndustryOpen)}
            >
              Industry
            </Button>
          </FilterPopover>

          <FilterPopover
            menuItems={jobFunctions}
            onOpen={toggleFilters}
            onClose={toggleFilters}
            isOpen={isFunctionOpen}
            placement="bottom-start"
            selectedItems={selectedJobFunctions}
            setSelectedItems={checkOffFunctionFilters}
            itemsLoading={jobFunctionsLoading}
          >
            <Button
              minW="141px"
              h={'56px'}
              color={'#1D2433'}
              border="1px solid #DFE4EA"
              borderRadius={'100px'}
              backgroundColor={'white'}
              placeholder="Function"
              fontWeight={'400'}
              rightIcon={
                isFunctionOpen ? (
                  <Icon as={RiArrowDropUpLine} boxSize={8} />
                ) : (
                  <>
                    {selectedJobFunctions?.length > 0 && (
                      <Flex
                        w={'25px'}
                        h={'25px'}
                        alignSelf={'center'}
                        borderRadius={'50%'}
                        color={'white'}
                        backgroundColor={'black'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Text alignSelf={'center'} color={'white'} backgroundColor={'black'}>
                          {selectedJobFunctions?.length}
                        </Text>
                      </Flex>
                    )}
                    <Icon as={RiArrowDropDownLine} boxSize={8} />
                  </>
                )
              }
              onClick={() => toggleFilters(setIsFunctionOpen, isFunctionOpen)}
            >
              Function
            </Button>
          </FilterPopover>
        </HStack>
        <HStack>
          <InputGroup h="full">
            <Input
              placeholder="Search Jobs..."
              autoFocus
              bgColor={'white'}
              _placeholder={{ opacity: 1, color: '#111928' }}
              h={'56px'}
              w="294px"
              p={'16px 24px 16px 24px'}
              borderRadius={'100px'}
              border={'1px solid #DFE4EA'}
              value={searchInput}
              onChange={handleSearchInputChange}
            />
            <InputRightElement h="full" right={2}>
              <PiMagnifyingGlassLight color={'black'} />
            </InputRightElement>
          </InputGroup>
        </HStack>
      </Center>
    )
  }

  const FilterTags = () => {
    return (
      <Flex mb={'10px'} flexWrap={'wrap'} gap={'10px'}>
        {selectedJobFunctions?.map((jobFunction: string, i: number) => (
          <Tag
            key={i}
            size={'lg'}
            borderRadius="full"
            variant="solid"
            color={'#1D2433'}
            border="1px solid #111928"
            backgroundColor={'white'}
          >
            <TagLabel>{JSON.parse(jobFunction)?.title}</TagLabel>
            <TagCloseButton
              onClick={() =>
                handleRemoveFilter(jobFunction, selectedJobFunctions, setSelectedJobFunctions)
              }
            />
          </Tag>
        ))}
        {selectedJobIndustries?.map((industry: string, i: number) => (
          <Tag
            key={i}
            size={'lg'}
            borderRadius="full"
            variant="solid"
            color={'#1D2433'}
            border="1px solid #111928"
            backgroundColor={'white'}
          >
            <TagLabel>{JSON.parse(industry)?.title}</TagLabel>
            <TagCloseButton
              onClick={() =>
                handleRemoveFilter(industry, selectedJobIndustries, setSelectedJobIndustries)
              }
            />
          </Tag>
        ))}
      </Flex>
    )
  }

  return (
    <Box height={'100dvh'}>
      <JobModal isOpen={isOpen} job={selectedJob} setIsOpen={setIsOpen} />
      <VStack>
        <Header />
        <Box px={3} w="full">
          <Container
            width={{ base: '100%', md: '75%' }}
            px={{ base: '10px', md: '30px' }}
            maxW="8xl"
            py={6}
          >
            <VStack flexGrow={1} spacing={4} w="full" alignItems="start">
              <Stack direction={{ base: 'column', md: 'row' }} w="full">
                <Box alignItems="start" w="full" justifyContent="space-evenly" my={'70px'}>
                  <Text fontSize={'lg'}>Welcome back!</Text>
                  <Text
                    textTransform={'none'}
                    fontFamily={'sans-serif'}
                    color="white"
                    fontSize={'4xl'}
                    fontWeight="700"
                  >
                    Job & Application Board
                  </Text>
                </Box>
              </Stack>
            </VStack>
          </Container>
        </Box>
      </VStack>

      <Center flexGrow={1} bg="white" py={'30px'} flexDirection={'column'}>
        <Tabs
          px={{ base: 0, md: '50px' }}
          size="lg"
          color={'#11192890'}
          w={{ base: '100%', md: '75%' }}
          isLazy
          index={tabIndex}
          onChange={handleTabsChange}
        >
          <TabList>
            <Tab
              px={'40px'}
              _selected={{ color: 'black' }}
              display="flex"
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Box mr={'5px'}>
                <IoIosRocket />
              </Box>{' '}
              All Jobs
            </Tab>
            <Tab
              px={'40px'}
              _selected={{ color: 'black' }}
              display="flex"
              flexDirection={{ base: 'column', md: 'row' }}
            >
              {' '}
              <Box mr={'5px'}>
                <CiBookmark />
              </Box>{' '}
              To Apply
            </Tab>
            <Tab
              px={'40px'}
              _selected={{ color: 'black' }}
              display="flex"
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Box mr={'5px'}>
                <IoMdThumbsUp />{' '}
              </Box>
              Applications
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel minH={'649px'}>
              <Filters />
              <FilterTags />
              {selectedJobFunctions.length || selectedJobIndustries.length || searchInput.length ? (
                <Text fontFamily={'Inter'} color="#111928" fontSize={'14px'} fontWeight="500">
                  {' '}
                  Search Results{' '}
                </Text>
              ) : (
                <Text fontFamily={'Inter'} color="#111928" fontSize={'14px'} fontWeight="500">
                  All Jobs
                </Text>
              )}
              <Divider
                w={'47px'}
                border={'2px solid #2F80ED'}
                borderRadius={'2px'}
                mt={'5px'}
                mb={'20px'}
              />

              <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3 }}
                spacingX={{ md: '25px', lg: '25px' }}
                spacingY={'30px'}
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  allJobsData?.results?.map((job: any, i: number) => (
                    <JobCard job={job} key={i} toggleOpenJobModal={toggleOpenJobModal} />
                  ))
                )}
              </SimpleGrid>
              <Center my={'30px'}>
                <PaginationButtons />
              </Center>
            </TabPanel>
            <TabPanel minH={'649px'} alignContent={'center'}>
              <ToApplyTab
                handleTabsChange={handleTabsChange}
                toggleOpenJobModal={toggleOpenJobModal}
              />
            </TabPanel>

            <TabPanel minH={'649px'} alignContent={'center'}>
              <ApplicationsTab
                handleTabsChange={handleTabsChange}
                toggleOpenJobModal={toggleOpenJobModal}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Center>
    </Box>
  )
}
