import React, { useState } from 'react'
import { Box, Grid, GridItem } from '@chakra-ui/react'
import { useParams } from 'react-router'
import { GET_ABOUT_YOU_DATA } from 'src/utils/queries'
import { useQuery } from '@apollo/client'
import { ReportData } from 'src/utils/types'
import { Intro, Layout, AboutYou, NavBar, MiniDashboard } from 'src/components'

export default function Reports() {
  let { responseId } = useParams()
  const [reportData, setReportData] = useState<ReportData>()
  const [error, setError] = useState(false)
  const { loading } = useQuery(GET_ABOUT_YOU_DATA, {
    variables: { responseId: responseId },

    onCompleted: (data: any) => {
      setReportData(data.reportData)
    },
    onError: (error: any) => {
      console.error(error)
      setError(true)
    },
  })

  return (
    <Layout reportData={reportData}>
      <Box m="auto" position="relative" zIndex={3}>
        <Intro />
        {error ? <p>We were unable to retrieve your data. Please contact support.</p> :
        <Grid
        templateColumns={{ base: '1fr', md: '1fr 3fr 1fr' }}
        templateRows={{ base: 'auto auto 1fr', md: '1fr' }}
        columnGap={'10px'}
        >
          <GridItem rowStart={1} rowEnd={2} ml={2}>
            <NavBar reportData={reportData} responseId={responseId} page="about you" />
          </GridItem>
          <GridItem colStart={{ base: 1, md: 2 }} colEnd={3} rowStart={{ base: 2, md: 1 }} mx={2}>
            <AboutYou reportData={reportData} loading={loading} />
          </GridItem>
          <GridItem rowStart={{ base: 0, md: 1 }} rowEnd={1} ml={2}>
            <MiniDashboard reportData={reportData} />
          </GridItem>
        </Grid>
        }
      </Box>
    </Layout>
  )
}
