import debrief from 'src/assets/images/sessions/debrief.png'
import how_networking from 'src/assets/images/sessions/how_networking.png'
import internship from 'src/assets/images/sessions/internship.png'
import linkedin from 'src/assets/images/sessions/linkedin.png'
import resumes from 'src/assets/images/sessions/resumes.png'
import search_strategy from 'src/assets/images/sessions/search_strategy.png'
import talk_networking from 'src/assets/images/sessions/talk_networking.png'

export function reportColorSwitch(section: string) {
  if (section === 'Alignment') {
    return 'brand.200'
  } else if (section === 'Interviewing & Advancing') {
    return 'brand.400'
  }
}

export function formatTime(dateString: string) {
  const dateObj = new Date(dateString)
  const hours = dateObj.getHours()
  const minutes = dateObj.getMinutes()

  let formattedHours = hours % 12
  formattedHours = formattedHours === 0 ? 12 : formattedHours // midnight
  const period = hours < 12 ? 'am' : 'pm'
  let localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let timeZone = ''
  if (localTimeZone === 'America/Los_Angeles') {
    timeZone = 'PST'
  } else if (localTimeZone === 'America/New_York') {
    timeZone = 'EST'
  } else if (localTimeZone === 'America/Chicago') {
    timeZone = 'CST'
  } else if (localTimeZone === 'America/Denver') {
    timeZone = 'MST'
  } else {
    timeZone = 'local'
  }
  const formattedTime = `${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')}${period} ${timeZone}`
  return formattedTime
}

export function getDayWithOrdinal(day: number) {
  if (day > 3 && day < 21) return `${day}th`
  switch (day % 10) {
    case 1:
      return `${day}st`
    case 2:
      return `${day}nd`
    case 3:
      return `${day}rd`
    default:
      return `${day}th`
  }
}

export function formatDate(dateString: string, property: string) {
  const dateObj = new Date(dateString)

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const monthsOfYear = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const dayOfWeek = daysOfWeek[dateObj.getDay()]
  const month = monthsOfYear[dateObj.getMonth()]
  const day = dateObj.getDate()
  const year = dateObj.getFullYear()

  const formattedDay = getDayWithOrdinal(day)
  if (property === 'ordinal') return `${dayOfWeek}, ${month} ${formattedDay}`
  if (property === 'year') return `${formattedDay} ${month}, ${year}`
  return `${formattedDay} ${month}, ${year}`
}

export const milestoneDescriptions = {
  Clarity:
    'A job seeker with Clarity feels confident in finding jobs that interest them. They know about a wide variety of jobs and can explain their interests.',
  Alignment:
    "Alignment is the key to discovering the job that's perfect for you. It's all about finding that sweet spot where your skills, interests, and aspirations meet.",
  Search:
    'A job seeker with Search is strategic in applying to jobs regularly. They tailor their resume and cover letter, and track their progress in the job search.',
  Advance: 'A job seeker with Advance has regular interviews and gets beyond the first round.',
}

export const displaySessionPhoto = (courseName: string) => {
  switch (courseName) {
    case 'Seekr Debrief':
      return debrief
    case 'Interviewing 101':
      return talk_networking
    case 'Build Your PRO-file':
      return internship
    case 'Talking the Talk in Networking Settings':
      return talk_networking
    case 'Level Up Your LinkedIn':
      return linkedin
    case 'Strengths Spotlight':
      return how_networking
    case 'The Who and How of Networking':
      return how_networking
    case 'Results-Oriented Resumes':
      return resumes
    case 'Internship Search Strategy':
      return internship
    case 'Job Search Strategy':
      return search_strategy
    case 'Cover Letters':
      return resumes
    default:
      return debrief
  }
}
