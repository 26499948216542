import {
  Box,
  BoxProps,
  Grid,
  GridItem,
  Stack,
  useCheckbox,
  useCheckboxGroup,
  UseCheckboxProps,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from '@chakra-ui/react'
import React, { PropsWithChildren, ReactNode, Fragment } from 'react'

interface SurveyListCardProps extends UseRadioProps {
  children: React.ReactNode
  containerProps?: BoxProps
}
interface SurveyListMultipleCardProps extends UseCheckboxProps {
  children: React.ReactNode
}

interface SurveyListProps {
  /**
   * The name of the radio group
   */
  name: string
  options: { value: string; label: string }[]
  dash?: boolean
}

const SurveyOptionCard = (props: {
  children: React.ReactNode
  inputProps: any
  checkboxProps: any
  extendBoxProps?: BoxProps
}) => {
  return (
    <Box
      as="label"
      backgroundColor="#FFFFFF08"
      borderColor="brand.surveyCardGray"
      rounded="lg"
      {...props.extendBoxProps}
    >
      <input {...props.inputProps} />
      <Box
        {...props.checkboxProps}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        display="flex"
        alignItems="center"
        justifyContent="center"
        h={'full'}
        _checked={{
          bg: 'brand.blue',
          color: 'white',
          borderColor: 'brand.blue',
        }}
        _hover={{
          color: 'white',
          borderColor: 'brand.blue',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        _disabled={{
          opacity: 0.5,
        }}
        px={5}
        py={3}
        dangerouslySetInnerHTML={{
          __html: props.children,
        }}
      />
    </Box>
  )
}

const RadioCard = (props: PropsWithChildren<SurveyListCardProps>) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()
  return (
    <SurveyOptionCard
      checkboxProps={checkbox}
      inputProps={input}
      extendBoxProps={props.containerProps}
    >
      {props.children}
    </SurveyOptionCard>
  )
}

const CheckboxCard = (props: PropsWithChildren<SurveyListMultipleCardProps>) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()
  return (
    <SurveyOptionCard checkboxProps={checkbox} inputProps={input}>
      {props.children}
    </SurveyOptionCard>
  )
}

export function SurveyListSingleOption({
  name,
  options,
  onChange,
  layout = 'column',
  value,
}: SurveyListProps & {
  layout?: 'column' | 'row' | 'grid'
  onChange?: (value: string) => void
  value?: string
}) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    value,
  })

  const group = getRootProps()

  const BaseComponent =
    layout === 'grid'
      ? ({ children }: { children: ReactNode }) => (
          <Grid  templateColumns="repeat(2,1fr)" gap={2} w="full" {...group}>
            {children}
          </Grid>
        )
      : ({ children }: { children: ReactNode }) => (
          <Stack
            direction={layout}
            {...(layout === 'row' ? { flexWrap: 'wrap', gap: 1 } : {})}
            w="full"
            {...group}
          >
            {children}
          </Stack>
        )
  const Wrapper =
    layout === 'grid'
      ? ({ children }: { children: ReactNode }) => (
          <GridItem backgroundColor="#FFFFFF08" borderColor="brand.surveyCardGray" rounded="lg">
            {children}
          </GridItem>
        )
      : Fragment

  return (
    <BaseComponent data-testid="question-type-option-select">
      {options.map((option, i) => {
        const radio = getRadioProps({ value: option.value })
        return (
          <Wrapper key={i}>
            <RadioCard
              {...radio}
              containerProps={{
                //ensure all have the same width
                flexGrow: 1,
                flexBasis: 0,
              }}
            >
              {option.label}
            </RadioCard>
          </Wrapper>
        )
      })}
    </BaseComponent>
  )
}

export function SurveyListMultipleOption({
  options,
  dash,
  layout = 'column',
  optionsCapAmount,
  columnsAmount,
  onChange,
  value,
}: SurveyListProps & {
  optionsCapAmount?: number
  columnsAmount?: number
  layout?: 'column' | 'row' | 'grid'
  /**
   * Fire an onchange event, with only values
   */
  onChange?: (value: string[]) => void
  value?: string[]
}) {
  const { getCheckboxProps, value: currentlySelected } = useCheckboxGroup({
    onChange,
    value: value ?? [],
  })

  const BaseComponent =
    layout === 'grid'
      ? ({ children }: { children: ReactNode }) => (
          <Grid
            templateColumns={{
              base: '1fr',
              md: `repeat(${columnsAmount ? columnsAmount : '2'},1fr)`,
            }}
            gap={2}
          >
            {children}
          </Grid>
        )
      : ({ children }: { children: ReactNode }) => (
          <Stack
            direction={layout}
            {...(layout === 'row' ? { flexWrap: 'wrap', gap: 1 } : {})}
            w="full"
          >
            {children}
          </Stack>
        )
  const Wrapper =
    layout === 'grid'
      ? ({ children }: { children: ReactNode }) => (
          <GridItem backgroundColor="#FFFFFF08" borderColor="brand.surveyCardGray" rounded="lg">
            {children}
          </GridItem>
        )
      : ({ children }: { children: ReactNode }) => <>{children}</>

  return (
    <BaseComponent data-testid="question-type-multi-option-select">
      {options.map((option, i) => {
        const checkBox = getCheckboxProps({ value: option.value })
        return (
          <Wrapper key={option.value as string}>
            <CheckboxCard
              key={i}
              {...checkBox}
              isDisabled={
                currentlySelected.length === optionsCapAmount &&
                !currentlySelected.includes(option.value as string)
              }
            >
              {option.label}
            </CheckboxCard>
          </Wrapper>
        )
      })}
    </BaseComponent>
  )
}
