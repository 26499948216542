import { Icon, Modal, ModalBody, ModalContent, ModalOverlay, Text, VStack } from '@chakra-ui/react'
import React, { useContext } from 'react'
import Intro from './Intro'
import SelectStrengthGrid from './SelectStrengthGrid'
import { FaCrown } from 'react-icons/fa'
import { MatchScoreContext } from 'src/utils/providers/useMatchScore'

export default function SelectStrengthsModal() {
  const { toggleCloseModal, isStrengthsModalOpen, strengthsSelectStep } =
    useContext(MatchScoreContext)

  return (
    <Modal
      scrollBehavior="outside"
      isOpen={isStrengthsModalOpen}
      onClose={toggleCloseModal}
      size={'full'}
    >
      {' '}
      <ModalOverlay />
      <ModalContent display={'flex'} py={'30px'}>
        <ModalBody display="flex" justifyContent="center" alignItems="center" p={0}>
          <VStack width={'100%'}>
            <Icon
              as={FaCrown}
              background={'white'}
              borderRadius={'50%'}
              color={'brand.navy'}
              width={'30px'}
              height={'30px'}
              p={'10px'}
            />
            <Text
              textTransform={'none'}
              textAlign={'center'}
              fontFamily={'sans-serif'}
              color="white"
              fontSize={'3xl'}
              fontWeight="700"
              mb={'100px'}
            >
              Select Your Strengths
            </Text>

            {strengthsSelectStep === 0 ? <Intro /> : <SelectStrengthGrid />}
          </VStack>
        </ModalBody>
      </ModalContent>{' '}
    </Modal>
  )
}
