import React, { useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { GET_USER_PROFILE_INFO } from 'src/utils/queries'

import RSVP from 'src/components/RSVP'

export default function Scheduler() {
  const navigate = useNavigate()

  const { loading: isProfileLoading, data } = useQuery(GET_USER_PROFILE_INFO, {
    onError: (error) => {
      console.error(error)
    },
  })

  useEffect(() => {
    //don't allow users to visit if they have already been welcomed
    if (data?.me.hasBeenWelcomed) {
      navigate('/dashboard')
    }
  }, [data?.me.hasBeenWelcomed, data?.me?.responseId, navigate])

  return <RSVP />
}
