import { Flex, FormControl } from '@chakra-ui/react'
import React, { useState, ChangeEvent } from 'react'
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import { institutions } from 'src/utils/us_institutions'
const institutionsList = institutions
interface Props {
  value: string
  handleResponseValue: (value: string) => void
}
function Autocomplete({ value, handleResponseValue }: Props) {
  const [options, setOptions] = useState([{ institution: '' }])
  const [isLoading, setIsLoading] = useState(false)
  const filterResults = (term: any) => {
    const lowerCaseTerm = term?.toLowerCase()

    return new Promise((r) => setTimeout(r, 2000)).then(() =>
      institutionsList.filter(
        (item) => item.institution.toLowerCase().indexOf(lowerCaseTerm) !== -1,
      ),
    )
  }
  const onChangeInputHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true)

    filterResults(evt.target.value).then((results) => {
      setOptions(results)
      setIsLoading(false)
    })
  }

  return (
    <Flex align="center" w="full">
      <FormControl w="60">
        <AutoComplete
          isLoading={isLoading}
          openOnFocus
          onSelectOption={(item) => {
            handleResponseValue(item?.item.value)
          }}
          value={value}
        >
          <AutoCompleteInput
            onChange={onChangeInputHandler}
            placeholder="Enter text here"
            variant="filled"
          />
          <AutoCompleteList>
            {options.map((item: any, cid: number) => {
              return (
                <AutoCompleteItem
                  key={`option-${cid}`}
                  value={item.institution}
                  textTransform="capitalize"
                >
                  {item.institution}
                </AutoCompleteItem>
              )
            })}
          </AutoCompleteList>
        </AutoComplete>
      </FormControl>
    </Flex>
  )
}

export default Autocomplete
