import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Center,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
} from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { GET_EXPORT_STATUSES } from 'src/utils/queries'
import { Loading } from 'src/components'

interface Props {
  isOpen: boolean
  onClose: () => void
  selectedSurvey: any
}

interface statusUpdate {
  datetimeCreated: string
  surveyId: string
  templateName: string
}

export default function ExportStatusModal({ isOpen, onClose, selectedSurvey }: Props) {
  const [exportLogs, setExportlogs] = useState<statusUpdate[]>([])

  const { loading } = useQuery(GET_EXPORT_STATUSES, {
    variables: {
      surveyId: selectedSurvey?.surveyId,
    },
    onCompleted: (data) => {
      setExportlogs(data.me.exportStatusUpdate)
    },
    onError: (error) => {
      console.error(error)
    },
  })

  const renderTemplateOptions = (loading: boolean) => {
    if (loading) {
      return (
        <Center mt={100}>
          <Loading />
        </Center>
      )
    }

    return (
      <>
        <ModalBody maxW="56rem">
          <Center mt={25}>
            {exportLogs?.length === 0 ? (
              <Box> No Exports yet</Box>
            ) : (
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Export Status</Th>
                      <Th>Template</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {exportLogs?.map((Log: any, index: number) => {
                      return (
                        <Tr key={index}>
                          <Td>{new Date(`${Log.datetimeCreated}`).toUTCString()}</Td>
                          <Td>{Log.status}</Td>
                          <Td>{Log.templateName}</Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Center>
        </ModalBody>
      </>
    )
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="56rem">
          <ModalHeader>Export History</ModalHeader>
          <ModalCloseButton />

          {renderTemplateOptions(loading)}
        </ModalContent>
      </Modal>
    </>
  )
}
