import gql from 'graphql-tag'

// Auth mutations
export const LOG_IN = gql`
  mutation logIn($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      user {
        isStaff
        responseId
        atUserRecordId
        hasBeenWelcomed
        email
        id
        program {
          programType
        }
      }
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(token: $token) {
      token
      payload
      refreshExpiresIn
    }
  }
`

export const VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`

export const GENERATE_RESET_TOKEN = gql`
  mutation generateResetToken($email: String!) {
    generateResetToken(email: $email) {
      success
      errors
      token
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $email: String!
    $password: String!
    $rePassword: String!
    $token: String!
  ) {
    resetPassword(email: $email, password: $password, rePassword: $rePassword, token: $token) {
      success
    }
  }
`

export const SIGN_UP = gql`
  mutation signUp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $programId: String
  ) {
    signUp(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      programId: $programId
    ) {
      success
      errors
      user {
        isStaff
      }
    }
  }
`

export const EXPORT_RESPONSES = gql`
  mutation requestExport($exportData: ExportRequestInput!) {
    requestExport(exportData: $exportData) {
      result {
        percentComplete
        status
        continuationToken
        progressId
      }
      responseMeta {
        requestId
        httpStatus
        notice
      }
    }
  }
`

export const SEND_TO_SHEETS = gql`
  mutation sendResponsesToSheets(
    $surveyId: String!
    $templateName: String!
    $progressId: String!
    $exportName: String!
  ) {
    sendSheet(
      surveyId: $surveyId
      templateName: $templateName
      progressId: $progressId
      exportName: $exportName
    ) {
      success
    }
  }
`

export const SEND_TO_AIRTABLE = gql`
  mutation sendResponsesToAirtable($logId: String!, $instanceName: String!) {
    sendToAirtable(logId: $logId, instanceName: $instanceName) {
      success
    }
  }
`

export const SEND_TO_AIRTABLE_DW = gql`
  mutation sendResponsesToAirtableDW($gsheetUrl: String!, $instanceName: String!) {
    sendToAirtableDw(gsheetUrl: $gsheetUrl, instanceName: $instanceName) {
      success
    }
  }
`

export const CALCULATE_SURVEY_RESULTS = gql`
  mutation calculateSurveyResults($exportData: ExportRequestInput!) {
    calculateSurveyResults(exportData: $exportData) {
      success
    }
  }
`

export const SEND_TO_MONGO_AND_EMAIL = gql`
  mutation sendResponsesToMongoAndEmail($prodTest: Boolean!, $responseId: String!) {
    sendResponsesToMongoAndEmail(prodTest: $prodTest, responseId: $responseId) {
      success
    }
  }
`

export const RSVP_FOR_SESSION = gql`
  mutation rsvpForSession($sessionId: String!, $isDebrief: Boolean) {
    rsvpForSession(sessionId: $sessionId, isDebrief: $isDebrief) {
      success
      user {
        id
        hasBeenWelcomed
      }
    }
  }
`

export const CANCEL_RSVP_FOR_SESSION = gql`
  mutation rsvpForSession($recordId: String!) {
    cancelRsvpForSession(recordId: $recordId) {
      success
    }
  }
`

export const PARSE_JOB_DESCRIPTION = gql`
  mutation jobParse($jobDescription: String!) {
    jobParse(jobDescription: $jobDescription) {
      success
      errors
      jobFunction
      jobIndustry
    }
  }
`

export const GET_MATCH_SCORE = gql`
  mutation matchScore($jobDescription: String!, $responseId: String!, $jobTitle: String!) {
    matchScore(jobDescription: $jobDescription, responseId: $responseId, jobTitle: $jobTitle) {
      success
      errors
      resultsString
      finalMatchScore
      studentTopDriver
      jobMatchResultId
      studentInterest
      jobFunction
      jobIndustry
    }
  }
`

export const SUBMIT_TEST_USER_STRENGTHS = gql`
  mutation submitTestUserStrengths($responseId: String!, $strengths: String!) {
    submitTestUserStrengths(responseId: $responseId, strengths: $strengths) {
      success
      errors
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      success
      errors
    }
  }
`

export const SUBMIT_MISSION_ANSWER = gql`
  mutation SubmitMissionAnswer(
    $missionNumber: Int!
    $question: String!
    $answer: String!
    $responseId: String!
  ) {
    submitMissionAnswer(
      missionNumber: $missionNumber
      question: $question
      answer: $answer
      responseId: $responseId
    ) {
      success
      errors
    }
  }
`

export const SUBMIT_CHECKPOINT_ANSWER = gql`
  mutation SubmitCheckpointAnswer(
    $missionNumber: Int!
    $question: String!
    $answer: String!
    $responseId: String!
  ) {
    submitCheckpoint(
      missionNumber: $missionNumber
      question: $question
      answer: $answer
      responseId: $responseId
    ) {
      success
      errors
    }
  }
`

export const RUN_MATCH_TEST = gql`
  mutation runMatchTest($gSheetName: String!) {
    runMatchTest(gSheetName: $gSheetName) {
      success
    }
  }
`

export const SUBMIT_SURVEY_RESPONSE = gql`
  mutation submitSurveyResponse(
    $email: String!
    $surveyName: String!
    $surveyResults: SurveyResponseArgType!
  ) {
    submitSurveyResponse(email: $email, surveyName: $surveyName, surveyResults: $surveyResults) {
      success
      errors
    }
  }
`

export const SUBMIT_RESPONSE_AND_NEXT_QUESTION = gql`
  mutation submitSurveyResponseAndNext($email: String!, $questionId: ID!, $responseValue: String) {
    submitSurveyResponseAndNext(
      email: $email
      questionId: $questionId
      responseValue: $responseValue
    ) {
      progress
      success
      errors
      nextResponse
      nextQuestion {
        id
        question
        isFirst
        isLast
        isCover
        cleanQuestion
        questionType
        questionDisplay
        exportKey
        options {
          cleanDisplay
          id
          value
          order
          display
          showAdditionalOpenText
        }
        responses {
          id
          value
        }
        logic {
          id
          question {
            id
          }
          responseValue
          logicalOperator
          nextQuestion {
            id
          }
        }
      }
    }
  }
`

export const RETRIEVE_PREVIOUS_QUESTION = gql`
  mutation retrievePreviousQuestion($email: String!, $questionId: ID!) {
    retrievePreviousQuestion(email: $email, questionId: $questionId) {
      progress
      success
      errors
      previousQuestion {
        id
        question
        isFirst
        isLast
        isCover
        cleanQuestion
        questionType
        questionDisplay
        exportKey
        options {
          cleanDisplay
          id
          value
          order
          display
          showAdditionalOpenText
        }
        responses {
          id
          value
        }
        logic {
          id
          question {
            id
          }
          responseValue
          logicalOperator
          nextQuestion {
            id
          }
        }
      }
      previousResponse
    }
  }
`

export const RETRIEVE_SURVEY_POSITION = gql`
  mutation retrieveSurveyPosition($email: String!) {
    retrieveCurrentSurveyPosition(email: $email) {
      progress
      currentSection {
        id
        title
        order
      }
      currentQuestion {
        id
        question
        isFirst
        isLast
        isCover
        cleanQuestion
        questionType
        questionDisplay
        exportKey
        options {
          cleanDisplay
          id
          value
          order
          display
          showAdditionalOpenText
        }
        responses {
          id
          value
        }
        logic {
          id
          question {
            id
          }
          responseValue
          logicalOperator
          nextQuestion {
            id
          }
        }
      }
      userId
      isStaff
      currentResponse
      program
      surveyId
    }
  }
`

export const MATCH_SCORE_FEEDBACK = gql`
  mutation matchScoreFeedback(
    $agreeLevel: Int!
    $comments: String!
    $jobMatchId: String!
    $nextStep: String!
  ) {
    matchScoreFeedback(
      agreeLevel: $agreeLevel
      comments: $comments
      jobMatchId: $jobMatchId
      nextStep: $nextStep
    ) {
      success
      errors
    }
  }
`

export const ADD_SESSIONS_NOTIFICATION = gql`
  mutation addSessionsNotification($courseId: String!, $userId: String!) {
    addSessionsNotification(courseId: $courseId, userId: $userId) {
      success
      errors
    }
  }
`

export const SUBMIT_SURVEY_FEEDBACK = gql`
  mutation submitSurveyFeedback($responseId: String!, $feedbackText: String!) {
    submitSurveyFeedback(responseId: $responseId, feedbackText: $feedbackText) {
      success
      errors
    }
  }
`
