import React, { FC } from 'react'
import { Box, Flex, Heading, Image, Text, Stack, StackProps } from '@chakra-ui/react'

import { ReportData } from 'src/utils/types'

import creativityDriverTile from 'src/assets/images/driver-tiles/driver_tile_creativity.svg'
import expertiseDriverTile from 'src/assets/images/driver-tiles/driver_tile_expertise.svg'
import incomeDriverTile from 'src/assets/images/driver-tiles/driver_tile_income.svg'
import meaningDriverTile from 'src/assets/images/driver-tiles/driver_tile_meaning.svg'
import relationshipDriverTile from 'src/assets/images/driver-tiles/driver_tile_relationship.svg'
import statusDriverTile from 'src/assets/images/driver-tiles/driver_tile_status.svg'

type Driver = {
  rank: number
  title: string
  icon: string
}
type DriverArray = Driver[]

export const TopDrivers: FC<{ reportData: ReportData | undefined }> = ({ reportData }) => {
  const rankNumber = (rank: string | undefined) => {
    return rank ? parseInt(rank) : 0
  }
  let drivers: DriverArray = [
    {
      rank: rankNumber(reportData?.A5Creativity),
      title: 'Creativity',
      icon: creativityDriverTile,
    },
    {
      rank: rankNumber(reportData?.A5Expertise),
      title: 'Expertise',
      icon: expertiseDriverTile,
    },
    {
      rank: rankNumber(reportData?.A5Income),
      title: 'Income',
      icon: incomeDriverTile,
    },
    {
      rank: rankNumber(reportData?.A5Meaning),
      title: 'Meaning',
      icon: meaningDriverTile,
    },
    {
      rank: rankNumber(reportData?.A5Relationships),
      title: 'Relationships',
      icon: relationshipDriverTile,
    },
    {
      rank: rankNumber(reportData?.A5Status),
      title: 'Status',
      icon: statusDriverTile,
    },
  ]
  let sortedDrivers = drivers.sort((a: Driver, b: Driver) => a.rank - b.rank)
  let topDrivers = sortedDrivers
    .reverse()
    .slice(0, 3)
    .map((driver: Driver) => {
      return (
        <Flex
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'flex-end'}
          key={driver.title}
        >
          <Image
            src={driver.icon}
            alt={driver.title + ' driver tile icon'}
            maxW={'unset'}
            mb={1}
            h="45px"
            w="47px"
          />
          <Text fontWeight={'bold'} fontSize={12}>
            {driver.title}
          </Text>
        </Flex>
      )
    })
  return <>{topDrivers}</>
}

function convertToEnergyStyle(energyScore: string | undefined) {
  switch (energyScore) {
    case '0':
    case '1':
    case '2':
      return 'Strong Introvert'
    case '3':
    case '4':
      return 'Slight Introvert'
    case '5':
    case '6':
      return 'Ambivert'
    case '7':
    case '8':
      return 'Slight Extrovert'
    case '9':
    case '10':
      return 'Strong Extrovert'
    default:
      return 'Ambivert'
  }
}

function convertToStructureStyle(predictability: string | undefined) {
  switch (predictability) {
    case '1':
      return 'Highly Flexible'
    case '2':
      return 'Flexible'
    case '3':
      return 'Balanced'
    case '4':
      return 'Organized'
    case '5':
      return 'Highly Organized'
    default:
      return 'Balanced'
  }
}
export const EnergyScore: FC<{
  score: string | undefined
  stackContainerProps?: StackProps
}> = ({
  score,
  stackContainerProps = { direction: 'column', spacing: 3, h: 'full', justifyContent: 'center' },
}) => {
  return (
    <Stack {...stackContainerProps}>
      <Heading letterSpacing={'2px'} fontSize={12} textTransform={'uppercase'}>
        Energy Style
      </Heading>
      <Text fontWeight={'bold'} fontSize={12}>
        {score ? convertToEnergyStyle(score) : '--'}
      </Text>
    </Stack>
  )
}

export const StructureStyle: FC<{ score?: string; stackContainerProps?: StackProps }> = ({
  score,
  stackContainerProps = { direction: 'column', spacing: 3, h: 'full', justifyContent: 'center' },
}) => {
  return (
    <Stack {...stackContainerProps}>
      <Heading letterSpacing={'2px'} fontSize={12} textTransform={'uppercase'}>
        Structure Style
      </Heading>
      <Text fontWeight={'bold'} fontSize={12}>
        {score ? convertToStructureStyle(score) : '--'}
      </Text>
    </Stack>
  )
}

export default function MiniDashboard(props: { reportData: ReportData | undefined }) {
  return (
    <Flex
      border={'none'}
      bg={'rgba(0, 0, 0, 0.24)'}
      px={[2, 5]}
      py={[2, 5]}
      backdropFilter={'blur(15px)'}
      boxShadow={'0px 4px 4px rgba(28, 28, 0.13)'}
      mt={{ base: 0, md: 10 }}
      mb={5}
      flexDirection={['row', 'column']}
      align={'flex-start'}
      justify={'space-around'}
    >
      <Flex flexDirection={'column'} alignItems={'flex-start'} justify={'center'} mb={[0, 3]}>
        <Heading mb={2} letterSpacing={'2px'} fontSize={12} textTransform={'uppercase'}>
          Your Milestone
        </Heading>
        <Image
          src={props.reportData?.DMS3AttachmentsFromMilestoneLink}
          alt={'Your milestone timeline overview'}
          maxW={'unset'}
          h="209px"
          mr={props.reportData?.DMS3AttachmentsFromMilestoneLink ? '-7.5rem' : 0}
          mb={3}
        />
      </Flex>
      <Flex flexDirection={'column'} align={'flex-start'} justify={'center'}>
        <Box mb={4}>
          <Heading mb={[2, 4]} letterSpacing={'2px'} fontSize={12} textTransform={'uppercase'}>
            Top Drivers
          </Heading>
          <Flex gap={['10px', '20px']} mb={[1, 3]}>
            <TopDrivers reportData={props.reportData} />
          </Flex>
        </Box>
        {/* TODO: would be nice to have these refactored to the extracted components but couldn't actually test this mini dashboard to see how it would look without all the margins. */}
        <Box mb={4}>
          <Heading mb={[2, 4]} letterSpacing={'2px'} fontSize={12} textTransform={'uppercase'}>
            Energy Style
          </Heading>
          <Text fontWeight={'bold'} fontSize={12} mb={[1, 3]}>
            {props.reportData?.EnergyScore
              ? convertToEnergyStyle(props.reportData?.EnergyScore)
              : '--'}
          </Text>
        </Box>
        <Box mb={4}>
          <Heading mb={[2, 4]} letterSpacing={'2px'} fontSize={12} textTransform={'uppercase'}>
            Structure Style
          </Heading>
          <Text fontWeight={'bold'} fontSize={12} mb={[1, 3]}>
            {props.reportData?.PredictabilityScore
              ? convertToStructureStyle(props.reportData?.PredictabilityScore)
              : '--'}
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}
