import {
  Heading,
  Grid,
  GridItem,
  Input,
  Button,
  useDisclosure,
  Text,
  Spinner,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { SEND_TO_AIRTABLE_DW } from 'src/utils/mutations'
import { DwProps } from './CalculateSheet'

export default function SendToAT({ setSuccess, setError }: DwProps) {
  const [gSheetLink, setGSheetLink] = useState('')
  const [instanceName, setInstanceName] = useState('')
  const { onClose } = useDisclosure()

  const [sendMutationDW, { loading }] = useMutation(SEND_TO_AIRTABLE_DW, {
    onCompleted: (data) => {
      setSuccess(true)
    },
    onError: (error) => {
      setError(error.message)
    },
  })

  const sendToAirtableDW = (gsheetUrl: string, instanceName: string) => {
    sendMutationDW({
      variables: {
        gsheetUrl,
        instanceName,
      },
    })
    onClose()
  }
  return (
    <div>
      <Heading>Send to Airtable</Heading>
      <Text mb={5}>Use this function after calculating a sheet to send the data to Airtable.</Text>
      <Grid mb={10} templateColumns="repeat(5, 1fr)" gap={10}>
        <GridItem>
          <Input
            htmlSize={20}
            width="auto"
            placeholder="google sheet link"
            size="sm"
            onChange={(e) => setGSheetLink(e.target.value)}
          />
        </GridItem>
        <GridItem>
          <Input
            htmlSize={20}
            width="auto"
            placeholder="instance name"
            size="sm"
            onChange={(e) => setInstanceName(e.target.value)}
          />
        </GridItem>
        <GridItem>
          {loading ? (
            <Spinner />
          ) : (
            <Button colorScheme="blue" onClick={() => sendToAirtableDW(gSheetLink, instanceName)}>
              Send to Data Warehouse
            </Button>
          )}
        </GridItem>
      </Grid>
    </div>
  )
}
