import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Center,
  Heading,
  Flex,
  Link,
  TableContainer,
  Table,
  Text,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Alert,
  AlertIcon,
  CloseButton,
  useDisclosure,
  Grid,
  GridItem,
  Checkbox,
} from '@chakra-ui/react'
import { GET_EXPORTS } from 'src/utils/queries'
import { SEND_TO_AIRTABLE, SEND_TO_MONGO_AND_EMAIL } from 'src/utils/mutations'
import { Loading, SelectInstanceModal } from 'src/components'
import { Input } from '@chakra-ui/react'

type User = {
  id: string
  firstName: string
  lastName: string
}

export type Export = {
  id: string
  surveyId: string
  outputFileName: string
  spreadsheetLink: string
  status: string
  datetimeCreated: string
  templateName: string
}

export default function Exports() {
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<any>()
  const [selectedExport, setSelectedExport] = useState<Export>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [prodTest, setProdTest] = useState(false)
  const [responseId, setResponseId] = useState('')

  const { data, loading } = useQuery(GET_EXPORTS, {
    onError: (error) => setError(error),
  })
  const [sendMutation] = useMutation(SEND_TO_AIRTABLE, {
    onCompleted: (data) => {
      setSuccess(data.sendToAirtable.success)
    },
  })

  const [sendToMongoAndEmail] = useMutation(SEND_TO_MONGO_AND_EMAIL, {
    onCompleted: (data) => {
      setSuccess(data.sendResponsesToMongoAndEmail.success)
    },
  })

  const sendToAirtable = (ex: Export, instanceName: string) => {
    sendMutation({
      variables: {
        logId: ex.id,
        instanceName,
      },
    })
    onClose()
  }

  const submitMongoAndEmail = (prodTest: boolean, responseId: string) => {
    sendToMongoAndEmail({
      variables: {
        prodTest,
        responseId,
      },
    })
  }

  const openSelectInstanceModal = (ex: Export) => {
    setSelectedExport(ex)
    onOpen()
  }

  if (loading) return <Loading />

  const renderExportsTable = () => {
    return (
      <>
        <TableContainer mt={20}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Export File Name</Th>
                <Th>Template Name</Th>
                <Th>Date</Th>

                <Th>Send to Airtable</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.responseExports?.map((ex: Export, index: number) => (
                <Tr key={index}>
                  <Td>
                    <Link color="blue" href={ex.spreadsheetLink}>
                      {ex.outputFileName}
                    </Link>
                  </Td>
                  <Td>{ex.templateName}</Td>
                  <Td>{new Date(ex.datetimeCreated).toLocaleString('en-US')}</Td>
                  <Td>
                    <Button colorScheme="blue" onClick={(e: any) => openSelectInstanceModal(ex)}>
                      Send to Airtable
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </>
    )
  }

  const renderSuccess = () => {
    return (
      <Alert status="success">
        <AlertIcon />
        <Text>Data successfully sent!</Text>
        <CloseButton
          alignSelf="flex-end"
          position="relative"
          right={-1}
          top={-1}
          onClick={() => setSuccess(false)}
        />
      </Alert>
    )
  }

  const renderError = (error: any) => {
    return (
      <Alert status="error">
        <AlertIcon />
        <Text>{error.message}</Text>
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={() => setError(false)}
        />
      </Alert>
    )
  }

  return (
    <>
      <Center mt={100} ml={5}>
        <Flex direction="column">
          <Heading mb={10}>Individual Send to Mongo and Email Student</Heading>
          <Grid mb={10} templateColumns="repeat(5, 1fr)" gap={10}>
            <GridItem>
              <Checkbox width="auto" onChange={(e) => setProdTest(!prodTest)}>
                Prod test?
              </Checkbox>
            </GridItem>
            <GridItem>
              <Input
                htmlSize={20}
                width="auto"
                placeholder="Response ID"
                size="sm"
                onChange={(e) => setResponseId(e.target.value)}
              />
            </GridItem>
            <GridItem>
              <Button colorScheme="blue" onClick={() => submitMongoAndEmail(prodTest, responseId)}>
                Send to Mongo & Email
              </Button>
            </GridItem>
          </Grid>

          <Heading>Previous Exports</Heading>
          {success && renderSuccess()}
          {error && renderError(error)}
          <Box>{renderExportsTable()}</Box>
        </Flex>
      </Center>
      <SelectInstanceModal
        isOpen={isOpen}
        onClose={onClose}
        sendToAirtable={sendToAirtable}
        selectedExport={selectedExport}
      />
    </>
  )
}
