import { useQuery } from '@apollo/client'
import { Box, Container, Text, SimpleGrid } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { GET_COURSES } from 'src/utils/queries'
import Loading from '../Loading'
import RSVPModal from './RSVPModal'
import SessionCard from './SessionCard'
import { surveyPrograms } from 'src/utils/constants'
import { CourseProps } from 'src/utils/types'

export default function AllSessions({ path, program }: any) {
  const [courses, setCourses] = useState<CourseProps[]>([])
  const [selectedCourse, setSelectedCourse] = useState<CourseProps>({
    id: '',
    name: '',
    imageUrl: '',
    shortDescription: '',
    courseRecordId: '',
  })
  const [isOpen, setIsOpen] = useState(false)
  const [warning, setWarning] = useState('')
  const [pathCourses, setPathCourses] = useState<CourseProps[]>([])
  const [nonPathCourses, setNonPathCourses] = useState<CourseProps[]>([])
  const toggleOpenRsvpModal = (course: CourseProps) => {
    setWarning('')
    setSelectedCourse(course)
    setIsOpen(true)
  }

  const { loading } = useQuery(GET_COURSES, {
    onCompleted: (data: any) => {
      setCourses(data.courseData)
    },
    onError: (error: any) => {
      console.error(error)
    },
  })

  useEffect(() => {
    if (program?.toLowerCase() !== surveyPrograms.discovery) {
      setNonPathCourses(courses)
    } else if (path?.courses && courses.length > 0) {
      let nonPathCourses = courses?.filter((course: any) => !path.courses?.includes(course.id))
      let filteredPathCourses = path?.courses?.map((id: any) =>
        courses?.find((course) => course.id === id),
      )
      setPathCourses(filteredPathCourses)
      setNonPathCourses(nonPathCourses)
    }
  }, [path, courses, program])

  return (
    <Container maxW="7xl" flexGrow={1} position="relative" textColor="brand.text" py={4}>
      <RSVPModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        course={selectedCourse}
        warning={warning}
        setWarning={setWarning}
      />
      <Box pb={10}>
        <Text
          mb={'20px'}
          lineHeight={'29.05px'}
          fontWeight={'700'}
          fontSize={'xl'}
          fontFamily={'Inter'}
        >
          All Sessions
        </Text>
        <Box>
          {loading ? (
            <Loading />
          ) : (
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              spacingX={{ md: '25px', lg: '25px' }}
              spacingY={'50px'}
            >
              {pathCourses?.map((course, i) => (
                <SessionCard
                  course={course}
                  i={i}
                  key={i}
                  toggleOpenRsvpModal={toggleOpenRsvpModal}
                  recommended={true}
                />
              ))}
              {nonPathCourses?.map((course, i) => (
                <SessionCard
                  course={course}
                  i={i}
                  key={i}
                  toggleOpenRsvpModal={toggleOpenRsvpModal}
                />
              ))}
            </SimpleGrid>
          )}
        </Box>
      </Box>
    </Container>
  )
}
