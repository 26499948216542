import React from 'react'
import { Text, HStack, VStack } from '@chakra-ui/react'

export default function Aside(props: { header: string; body: string }): JSX.Element {
  return (
    <>
      <HStack borderStyle="solid" borderColor={'brand.200'} borderWidth="1px" my={5} p={2}>
        <VStack align={'left'}>
          <Text>
            {props.header}- {props.body}
          </Text>
        </VStack>
      </HStack>
    </>
  )
}
