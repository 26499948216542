import { createApi } from '@thinknimble/tn-models'
import { axiosInstance } from '../axios-instance'
import { JobCreateShape, JobShape } from './models'
import { z } from 'zod'

export const jobApi = createApi({
  client: axiosInstance,
  baseUri: 'employment/jobs/',
  models: {
    entity: JobShape,
    create: JobCreateShape,
    extraFilters: {
      organization__industry: z.string(),
      function: z.string(),
      search: z.string(),
    },
  },
})
