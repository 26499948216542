import React, { ReactNode, useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Navigate, Outlet, useParams, useLocation } from 'react-router-dom'
import { AuthContext } from 'src/utils/auth'
import { GET_USER_PROFILE_INFO } from 'src/utils/queries'
import Loading from '../Loading'

interface RouteProps {
  children: ReactNode
}

export const NonAdminRoute = () => {
  const { user } = useContext(AuthContext)
  let { responseId } = useParams()
  return user?.responseId !== responseId ? (
    <Navigate to={`/view-report/${user?.responseId}`} />
  ) : (
    <Outlet />
  )
}

interface LoggedInSurveyRouteProps {
  element: React.ComponentType<any>
  [key: string]: any // This allows for any additional props
}

export const LoggedInSurveyRoute: React.FC<LoggedInSurveyRouteProps> = ({
  element: Component,
  ...rest
}) => {
  const { user } = useContext(AuthContext)
  return user ? (
    <Navigate to={`/survey/seekr/?email=${encodeURIComponent(user?.email)}`} />
  ) : (
    <Component {...rest} />
  )
}

export const NotLoggedinInOnlyRoute: React.FC<RouteProps> = ({ children }) => {
  const { token } = useContext(AuthContext)
  if (!token) {
    return <>{children}</>
  }
  return <Navigate to="/dashboard" />
}

export const AdminRoute: React.FC<RouteProps> = ({ children }) => {
  const { token, setUser, setToken } = useContext(AuthContext)
  const {
    loading: isProfileLoading,
    error,
    data,
  } = useQuery(GET_USER_PROFILE_INFO, {
    skip: !token,
    fetchPolicy: 'network-only',
  })
  useEffect(() => {
    if (data && data.me) {
      setUser(data.me)
    }
  }, [data, setUser])

  if (isProfileLoading) {
    return <Loading />
  }

  if (error) {
    console.error(error)
    setToken(null)
    setUser(null)
    return <Navigate to="/log-in" />
  }

  if (!data?.me && !token) {
    return <Navigate to="/log-in" />
  }

  if (token && data?.me?.isStaff) {
    return <>{children}</>
  }

  return <Navigate to="/log-in" />
}

export const AuthedRoute: React.FC<RouteProps> = ({ children }) => {
  const { token, setUser, setToken } = useContext(AuthContext)
  const location = useLocation()
  const {
    loading: isProfileLoading,
    error,
    data,
  } = useQuery(GET_USER_PROFILE_INFO, {
    skip: !token,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data && data.me) {
      setUser(data.me)
    }
  }, [data, setUser])

  if (isProfileLoading) {
    return <Loading />
  }

  if (error) {
    console.error(error)
    setToken(null)
    setUser(null)
    return <Navigate to="/log-in" />
  }

  if (!data?.me && !token) {
    return <Navigate to="/log-in" />
  }

  if (token && data?.me?.isStaff) {
    return <Navigate to="/staff/surveys" />
  }

  if (token && !data?.me?.responseId) {
    return <Navigate to={`/survey/seeker?email=${data?.me?.email}`} />
  }
  const welcomeRe = new RegExp('welcome')
  if (token && !data?.me?.hasBeenWelcomed) {
    if (!location.pathname.match(welcomeRe)) {
      return <Navigate to="/welcome" />
    }
  } else if (token && data?.me?.hasBeenWelcomed && location.pathname.match(welcomeRe)) {
    return <Navigate to="/dashboard" />
  }

  return <>{children}</>
}
