import { useQuery } from '@tanstack/react-query'
import { functionApi } from './api'

export const useListFunctions = () => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['functions'],
    queryFn: async () => {
      return await functionApi.list()
    },
    enabled: true,
  })
  return { data, refetch, isLoading }
}
