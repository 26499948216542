import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import { useQuery } from '@apollo/client'
import { GET_TEMPLATES } from 'src/utils/queries'
import { Template } from 'src/pages/Templates'
import { ExportInput } from 'src/pages/Surveys'
import { Loading } from 'src/components'

interface Props {
  isOpen: boolean
  onClose: () => void
  startExport: (data: ExportInput) => void
}

export default function TemplateSelectModal({ isOpen, onClose, startExport }: Props) {
  const [templates, setTemplates] = useState<Template[]>([])
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>('')
  const [fileName, setFileName] = useState<string>('')
  const [responseId, setResponseId] = useState<string>('')

  const { loading } = useQuery(GET_TEMPLATES, {
    onCompleted: (data) => {
      setTemplates(data.sheetsData.templates)
    },
    onError: (error) => {
      console.error(error)
    },
  })

  const renderTemplateOptions = (loading: boolean) => {
    if (loading) {
      return <Loading />
    }

    return (
      <>
        <ModalBody>
          <Select
            onChange={(e: any) => setSelectedTemplateName(e.target.value)}
            placeholder="Select a spreadsheet template"
          >
            {templates?.map((template: any, index: number) => {
              if (template.name === 'TN (Diagnostic v1.5 External) TEMPLATE') {
                return (
                  <option value={template.name} key={index}>
                    {template.name}
                  </option>
                )
              }
            })}
          </Select>

          <FormControl mt={5}>
            <FormLabel>Filename</FormLabel>
            <Input
              type="text"
              placeholder="Exported file name"
              onChange={(e: any) => setFileName(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Response ID (optional)</FormLabel>
            <Input
              type="text"
              placeholder="ResponseID"
              onChange={(e: any) => setResponseId(e.target.value)}
            />
          </FormControl>
        </ModalBody>
      </>
    )
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Available Templates</ModalHeader>
          <ModalCloseButton />

          {renderTemplateOptions(loading)}

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() =>
                startExport({
                  templateName: selectedTemplateName,
                  fileName: fileName,
                  responseId: responseId,
                })
              }
              variant="ghost"
            >
              Start Export
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
