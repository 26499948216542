import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'

export const useGetEmailFromParams = () => {
  const [searchParams] = useSearchParams()
  return useMemo(() => searchParams.get('email'), [searchParams])
}

export const useGetValueFromParams = (value: string) => {
  const [searchParams] = useSearchParams()
  return useMemo(() => searchParams.get(value), [searchParams])
}
