import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { SUBMIT_CHECKPOINT_ANSWER } from 'src/utils/mutations'

interface RSVPContextProps {
  hasRSVPed: any
  setRSVPStatus: (user: any) => void
  checkpointStep: any
  setCheckpointStep: (step: any) => void
  error: any
  setError: (step: any) => void
  submitCheckpoint: (step: any) => void
}

export const WelcomeContext = React.createContext<RSVPContextProps>({
  hasRSVPed: null,
  setRSVPStatus: (status: any) => {},
  checkpointStep: null,
  setCheckpointStep: (step: any) => {},
  error: null,
  setError: (msg: any) => {},
  submitCheckpoint: () => {},
})

export function RSVPProvider({ children }: { children: any }) {
  const [hasRSVPed, setHasRSVPed] = useState(false)
  const [checkpointStep, setCheckpointStep] = useState(1)
  const [error, setError] = useState('')
  const setRSVPStatus = (status: boolean) => {
    setHasRSVPed(status)
  }
  const [submitCheckpoint] = useMutation(SUBMIT_CHECKPOINT_ANSWER, {
    onCompleted: (data) => {},
    onError: (error) => {
      console.error(error)
      setError('Unable to submit your answer right now.')
    },
  })

  return (
    <WelcomeContext.Provider
      value={{
        hasRSVPed,
        setRSVPStatus,
        checkpointStep,
        setCheckpointStep,
        error,
        setError,
        submitCheckpoint,
      }}
    >
      {children}
    </WelcomeContext.Provider>
  )
}
