import React, { useContext } from 'react'
import { Button, Icon, Flex, Box } from '@chakra-ui/react'
import { RiLogoutBoxRFill } from 'react-icons/ri'
import { Outlet, useNavigate } from 'react-router-dom'
import { AuthContext, logout } from 'src/utils/auth'

export default function Home() {
  const { updateToken } = useContext(AuthContext)
  const navigate = useNavigate()

  const logUserOut = () => {
    logout()
    updateToken(null)
    navigate('/log-in')
  }

  return (
    <Box>
      <Flex my={20} direction="row" justify="space-evenly">
        <Button alignSelf={'end'} onClick={() => logUserOut()}>
          <Icon as={RiLogoutBoxRFill} mr={2} />
          Sign Out
        </Button>
        <Button variant="link" onClick={() => navigate('/surveys')}>
          Request an export
        </Button>
        <Button variant="link" onClick={() => navigate('/exports')}>
          Previous Exports
        </Button>
        <Button variant="link" onClick={() => navigate('/data-warehouse')}>
          Data Warehouse
        </Button>
      </Flex>
      <Outlet />
    </Box>
  )
}
