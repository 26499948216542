import { useQuery } from '@apollo/client'
import { Box, Button, Heading, HStack, Image, Stack, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import welcomeImg from 'src/assets/images/welcome-image.png'
import { TopDrivers } from 'src/components/ReportComponents/MiniDashboard'
import { GET_NEXT_STEPS_DATA } from 'src/utils/queries'
import { AuthContext } from 'src/utils/auth'
import { Header, Loading } from 'src/components'

export const Welcome = () => {
  const { user: data } = useContext(AuthContext)
  const navigate = useNavigate()

  const { loading: isReportLoading, data: report } = useQuery(GET_NEXT_STEPS_DATA, {
    variables: { responseId: data?.responseId },
    skip: !data?.responseId,
    onError: (error: any) => {
      console.error(error)
    },
  })


  const onNavigateToReport = () => {
    navigate(`/view-report/${data?.responseId}`)
  }

  return (
    <Box h="100dvh">
      <Header />
      <Stack
        direction={{
          base: 'column',
          sm: 'row',
        }}
        w="full"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={{
            base: 'center',
            sm: 'start',
          }}
          flexShrink={1}
        >
          <Image
            src={welcomeImg}
            objectFit={{
              base: 'cover',
              md: 'contain',
            }}
            h={'full'}
            // w="full"
          />
        </Box>
        <Box pl={'5%'}>
          <VStack
            gap={10}
            h="full"
            alignItems="start"
            justifyContent="center"
            pr={'10'}
            pl={{
              base: 10,
              sm: 0,
            }}
            flexGrow={1}
            style={{
              marginInlineStart: 0,
            }}
          >
            <VStack gap={3} justifyItems="start" alignItems="start">
              <Heading size="2xl" data-testid="welcome-title">
                Welcome {data.firstName} 👋
              </Heading>
              <Text>Congratulations on completing the survey!</Text>
            </VStack>
            <VStack w="full" gap={3} alignItems="start">
              <Heading size="lg">Top Drivers</Heading>
              <HStack spacing={2} justify={'center'}>
                {isReportLoading ? <Loading /> : <TopDrivers reportData={report?.reportData} />}
              </HStack>
            </VStack>

            {data.cursor?.reportGenerated ? (
              <VStack>
                <Text>
                  Now, let's dive into your results to unveil your energy style, structure style,
                  and milestone. It's time to kick off your next mission!
                </Text>
                <HStack gap={3} justifyItems="start" w="full">
                  <Button variant="solid" bg="brand.blue" onClick={onNavigateToReport}>
                    Review Results
                  </Button>
                </HStack>
              </VStack>
            ) : (
              <VStack>
                <Text>
                  Your results are being processed. You will receive an email when your results are
                  ready.
                </Text>
              </VStack>
            )}
          </VStack>
        </Box>
      </Stack>
    </Box>
  )
}
