import {
  Text,
  Badge,
  Image,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Stack,
} from '@chakra-ui/react'
import React from 'react'
import { CourseProps } from 'src/utils/types'

interface SessionCardProps {
  course: CourseProps
  i: number
  recommended?: boolean
  toggleOpenRsvpModal: (course: CourseProps) => void
}
export default function SessionCard({
  course,
  i,
  toggleOpenRsvpModal,
  recommended,
}: SessionCardProps) {
  return (
    <Card key={i} maxW="sm" minH="lg" backgroundColor={'white'} textColor="brand.text">
      <Image h={'150px'} src={course?.imageUrl} objectFit="cover" />
      {recommended ? (
        <Badge
          variant={'outline'}
          color="brand.blue"
          colorScheme="blue"
          width="55%"
          textAlign={'center'}
          fontSize={14}
          mt={3}
          ml={5}
          borderRadius={20}
          py={1}
        >
          Recommended Session #{i + 1}
        </Badge>
      ) : (
        <></>
      )}
      <CardBody px={'30px'}>
        <Stack mt="6" spacing="2">
          <Text fontFamily={'Inter'} fontWeight={'600'} fontSize={'xl'} mb={'15px'}>
            {course?.name}
          </Text>
          <Text fontFamily={'Inter'} fontWeight={'500'} color={'brand.lightGray'} fontSize={'14px'}>
            {course?.shortDescription}
          </Text>
        </Stack>
      </CardBody>
      <Divider
        backgroundColor={'#DFE4EA'}
        orientation="horizontal"
        variant={'solid'}
        m={'auto'}
        mt={'50px'}
        w={'90%'}
      />
      <CardFooter>
        <Button
          border={'1px solid #2d70e7'}
          variant="solid"
          _hover={{ bg: 'brand.white' }}
          w={'100%'}
          bg={'white'}
          color={'brand.blue'}
          title={'View Times'}
          fontWeight={500}
          onClick={() => toggleOpenRsvpModal(course)}
        >
          View Times
        </Button>
      </CardFooter>
    </Card>
  )
}
