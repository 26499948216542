import React from 'react'
import { Text, Box, Image, Flex } from '@chakra-ui/react'
import { Data } from 'src/utils/types'
import searchIcon from 'src/assets/images/search_icon.svg'
import searchLine from 'src/assets/images/search_line.svg'
export default function SearchStrategy({ reportData }: Data) {
  return (
    <Box pr={5}>
      <Flex>
        <Text fontSize={20} fontWeight={'bold'} pr={2}>
          Develop a search strategy
        </Text>
        <Image w="30px" src={searchIcon} alt={'Search icon'} />
      </Flex>
      <Text mt={3}>
        Basta has identified these keys to an effective internship/job search. Once you are ready to
        actively apply to opportunities, be sure to follow the guidance below.
      </Text>
      <Flex mt={10} justifyContent={'space-between'}>
        <Box display="block">
          <Text fontSize={18} fontWeight="bold" w="250px">
            Identifying Opportunities
          </Text>
        </Box>
        <Image src={searchLine} alt={'Search line'} style={{ transform: 'scale(1.5, 1)' }} />
      </Flex>
      <Text my={3}>
        Take a <span style={{ fontWeight: 'bold' }}>Systematic Approach</span>{' '}
      </Text>
      <Text>{reportData?.S7}</Text>
      <Text mt={5} mb={3}>
        Use a <span style={{ fontWeight: 'bold' }}>Variety of Sources</span>{' '}
      </Text>
      <Text>{reportData?.S10}</Text>
      <Flex mt={10} justifyContent={'space-between'}>
        <Box display="block">
          <Text fontSize={18} fontWeight="bold" w="250px">
            Increasing Your Odds
          </Text>
        </Box>

        <Image src={searchLine} alt={'Search line'} style={{ transform: 'scale(1.5, 1)' }} />
      </Flex>
      <Text my={3}>
        The <span style={{ fontWeight: 'bold' }}>Quality of your Application</span>{' '}
      </Text>
      <Text>{reportData?.S14}</Text>
      <Text my={3}>
        Using Your
        <span style={{ fontWeight: 'bold' }}> Network</span>{' '}
      </Text>
      <Text>{reportData?.S17}</Text>
      <Flex mt={10} justifyContent={'space-between'}>
        <Box display="block">
          <Text fontSize={18} fontWeight="bold" w="250px">
            Implementing Consistently
          </Text>
        </Box>
        <Image src={searchLine} alt={'Search line'} style={{ transform: 'scale(1.5, 1)' }} />
      </Flex>
      <Text my={3} fontWeight="bold">
        Tracking Progress
      </Text>
      <Text>{reportData?.S25}</Text>
      <Text my={3} fontWeight="bold">
        Application Volume
      </Text>
      <Text>{reportData?.ApplicationVolumeTextFromApplicationVolume}</Text>
    </Box>
  )
}
