import React from 'react'
import { Heading, GridItem, Text, Image, Grid, Flex } from '@chakra-ui/react'
import { interviewingContent } from 'src/utils/reportContent'
import { Data } from 'src/utils/types'
import interviewIcon from 'src/assets/images/interview_icon.svg'
import interviewLine from 'src/assets/images/interview_line.svg'

export default function Interviews({ reportData }: Data) {
  return (
    <>
      <Flex>
        <Text fontSize={20} fontWeight={'bold'} pr={2}>
          Ace your interviews
        </Text>
        <Image w="30px" src={interviewIcon} alt={'Interview icon'} />
      </Flex>
      <Text mt={5}>{reportData?.I2}</Text>
      <Grid
        gridTemplateColumns={'repeat(3, 1fr)'}
        gridAutoFlow={'row dense'}
        height="100%"
        gap="1"
        px={{ base: 5, md: 0 }}
        pt={5}
        mb={10}
        id="Advance"
      >
        <GridItem colSpan={{ base: 3, md: 3 }}>
          <Grid
            gridTemplateColumns={'20px repeat(2, 1fr)'}
            gridAutoFlow={'row '}
            height="100%"
            gap="1"
          >
            <GridItem colStart={2} colSpan={2}>
              <Text fontWeight={'bold'}>STEP 1</Text>
              <Heading size="md">Research</Heading>
            </GridItem>
            <GridItem alignSelf="end" w="fit-content" colSpan={1} rowSpan={1}>
              <Image src={interviewLine} alt={'Interview line'} />
            </GridItem>
            <GridItem colSpan={2}>
              <Text mb={5}>{interviewingContent.research}</Text>
              <Text fontWeight={'bold'}>STEP 2</Text>
            </GridItem>
            <GridItem colStart={2} colSpan={2}>
              <Heading size="md">Prepare</Heading>
            </GridItem>
            <GridItem alignSelf="end" w="fit-content" colSpan={1} rowSpan={1}>
              <Image src={interviewLine} alt={'Interview line'} />
            </GridItem>
            <GridItem colSpan={2}>
              <Text mb={5}>{interviewingContent.prepare}</Text>
              <Text fontWeight={'bold'}>STEP 3</Text>
            </GridItem>
            <GridItem colStart={2} colSpan={2}>
              <Heading size="md">Practice</Heading>
            </GridItem>
            <GridItem alignSelf="end" w="fit-content" colSpan={1} rowSpan={1}>
              <Image src={interviewLine} alt={'Interview line'} />
            </GridItem>
            <GridItem colSpan={2}>
              <Text mb={5}>{interviewingContent.practice}</Text>
              <Text fontWeight={'bold'}>STEP 4</Text>
            </GridItem>
            <GridItem colStart={2} colSpan={2}>
              <Heading size="md">Follow up</Heading>
              <Text>{interviewingContent.followUp}</Text>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </>
  )
}
