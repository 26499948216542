import React, { useState } from 'react'

interface MatchScoreContextProps {
  strengthsSelectStep: any
  setStrengthsSelectStep: (step: any) => void
  error: any
  setError: (msg: any) => void
  isStrengthsModalOpen: any
  setIsStrengthsModalOpen: (bool: boolean) => void
  toggleCloseModal: () => void
  responseId: any
  setResponseId: (id: string) => void
  strengthsError: any
  setStrengthsError: (err: string) => void
  success: any
  setSuccess: (bool: boolean) => void
}

export const MatchScoreContext = React.createContext<MatchScoreContextProps>({
  strengthsSelectStep: null,
  setStrengthsSelectStep: (step: any) => {},
  error: null,
  setError: (msg: any) => {},
  isStrengthsModalOpen: null,
  setIsStrengthsModalOpen: (bool: boolean) => {},
  toggleCloseModal: () => {},
  responseId: null,
  setResponseId: (id: string) => {},
  strengthsError: null,
  setStrengthsError: (err: string) => {},
  success: null,
  setSuccess: (bool: boolean) => {},
})

export function MatchScoreProvider({ children }: { children: any }) {
  const [strengthsSelectStep, setStrengthsSelectStep] = useState(0)
  const [error, setError] = useState('')
  const [isStrengthsModalOpen, setIsStrengthsModalOpen] = useState(false)
  const [responseId, setResponseId] = useState('')
  const [strengthsError, setStrengthsError] = useState('')
  const [success, setSuccess] = useState(false)

  const toggleCloseModal = () => {
    setIsStrengthsModalOpen(false)
  }

  return (
    <MatchScoreContext.Provider
      value={{
        strengthsSelectStep,
        setStrengthsSelectStep,
        error,
        setError,
        isStrengthsModalOpen,
        setIsStrengthsModalOpen,
        toggleCloseModal,
        responseId,
        setResponseId,
        strengthsError,
        setStrengthsError,
        success,
        setSuccess,
      }}
    >
      {children}
    </MatchScoreContext.Provider>
  )
}
