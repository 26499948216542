import { Flex, Heading } from '@chakra-ui/react'
import React from 'react'
import AutoExport from './AutoExport'
import { Survey } from '../../pages/Surveys'
import BulkExport from './CalculateSheet'
import SendToAT from './SendToAT'

export interface ExportLog {
  id: string
  surveyId: string
  outputFileName: string
  spreadsheetLink: string
  status: string
  templateName: string
  datetimeCreated: string
}

export interface DWProps {
  surveys: Survey[]
  setError: (...arg: any) => void
  setSuccess: (...arg: any) => void
}

export default function DataWarehouseExport({ surveys, setError, setSuccess }: DWProps) {
  return (
    <Flex direction="column">
      <Heading mb={10}>Data Warehouse Actions</Heading>
      <BulkExport setError={setError} setSuccess={setSuccess} />
      <AutoExport setError={setError} setSuccess={setSuccess} surveys={surveys} />
      <SendToAT setError={setError} setSuccess={setSuccess} />
    </Flex>
  )
}
