import React from 'react'
import { Box, Accordion, Flex, SkeletonCircle, Text, Image } from '@chakra-ui/react'
import { ReportData } from 'src/utils/types'
import FileFolderAccordion from '../FileFolderAccordion'
import Drivers from './Drivers'
import Preferences from './Preferences'
import Qualifications from './Qualifications'
import { MilestoneSection } from 'src/components'
import alignmentCircle from 'src/assets/images/alignment_circle.svg'

export default function AboutYou(props: {
  responseId?: string | undefined
  reportData: ReportData | undefined
  loading: any
}) {
  function showConflict() {
    if (props.reportData?.A33) {
      return (
        <FileFolderAccordion title="Conflict">
          <Box>
            <Flex pb={5}>
              <Text fontSize={20} fontWeight={'bold'} pr={2}>
                Conflict
              </Text>

              <Image w="30px" src={alignmentCircle} alt={'Alignment circle icon'} />
            </Flex>
            <Text fontSize={'18px'} display={'inline'} fontWeight={'bold'}>
              Feedback from a Basta coach:{' '}
            </Text>
            <Text display={'inline'}> "{props.reportData?.A33}"</Text>
          </Box>
        </FileFolderAccordion>
      )
    }
  }

  function getInterestDescription() {
    if (props.reportData?.InterestPrimary) {
      const description =
        props.reportData?.C2Part1FromUserProfile +
        props.reportData?.InterestPrimary +
        props.reportData?.C2Part2FromUserProfile +
        props.reportData?.InterestPrimary +
        props.reportData?.C2Part3FromUserProfile
      return description
    } else {
      return props.reportData?.C2
    }
  }

  return (
    <Box mt={5}>
      {props.loading ? (
        <Flex justifyContent={'center'}>
          <SkeletonCircle size="10" startColor="brand.300" endColor="brand.200" />
        </Flex>
      ) : (
        <Accordion defaultIndex={0}>
          <FileFolderAccordion title="Awareness">
            <MilestoneSection
              title="Awareness"
              description={getInterestDescription()}
              milestoneImage={props.reportData?.DMS3AttachmentsFromMilestoneLink}
            />
          </FileFolderAccordion>
          {showConflict()}
          <FileFolderAccordion title="Major, GPA, and Strengths">
            <Qualifications reportData={props.reportData} />
          </FileFolderAccordion>
          <FileFolderAccordion title="Drivers">
            <Drivers reportData={props.reportData} />
          </FileFolderAccordion>
          <FileFolderAccordion title="Energy Style, Structure, and Salary">
            <Preferences reportData={props.reportData} />
          </FileFolderAccordion>
        </Accordion>
      )}
    </Box>
  )
}
