
import { Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { SeekrSurvey } from 'src/pages/Seekr/SeekrSurvey'

import Loading from '../../components/Loading'
import { useGetEmailFromParams } from '../../components/Survey/hooks'
import { useQuery } from '@apollo/client'
import { RETRIEVE_SURVEY_POSITION } from 'src/utils/queries'

export const CheckUserCurrentSurveyStatus = () => {
  const navigate = useNavigate()
  const [emailError, setEmailError] = useState('')
  const email = useGetEmailFromParams()


  const { loading: isCursorLoading, error: cursorError, data, refetch } = useQuery(RETRIEVE_SURVEY_POSITION, {
    variables: {
      email: email?.toLowerCase(),
    },
    skip: !email,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
  
    if (cursorError) {
      alert('The current logged in user does not have a survey associated with their account')
      navigate('/dashboard')
    }

    if (data) {
      if (
        data.retrieveCurrentSurveyPosition?.isComplete &&
        !data.retrieveCurrentSurveyPosition?.isStaff
      ) {
        // alert('You have already completed this survey and cannot re-take it.');
        navigate('/survey/complete/')
      }

    }
  }, [data, cursorError, email]);


  if (isCursorLoading) {
    return (
      <Box as="main" w="100vw" h="100vh" display="flex" justifyContent="center" alignItems="center">
        <Loading />
      </Box>
    )
  }

  if (!email || emailError) {
    return <Navigate to="/survey/begin" />
  }


  return (
    <Routes>
      <Route
        index
        element={
          <SeekrSurvey
            isAdmin={data?.retrieveCurrentSurveyPosition.isStaff}
            surveyId={data?.retrieveCurrentSurveyPosition.surveyId}
            cursor={data?.retrieveCurrentSurveyPosition}
          />
        }
      />
    </Routes>
  )
}
