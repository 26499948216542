import { useMutation, useQuery } from '@apollo/client'
import { Box, Button, Center, Container, Flex, Text } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import SeekrSurveyLogo from 'src/components/Survey/SeekrSurveyLogo'
import { Header } from 'src/components'
import TextInput from 'src/components/Survey/ui/TextInput'
import { RETRIEVE_SURVEY_POSITION } from 'src/utils/queries'
import { SurveyContext } from 'src/utils/providers/useSurvey'
import { set, string } from 'zod'

const NO_USER_MESSAGE = 'User matching query does not exist.'

const serverToUserErrorDisplay: Record<string, string> = {
  [NO_USER_MESSAGE]: 'There is no user with the given email',
}

export function BeginSurvey() {
  const [error, setError] = useState<string | null>(null)
  const [email, setEmail] = useState<string | null>(null)
  const navigate = useNavigate()
  const { setProgress } = useContext(SurveyContext)

  //TODO: improve this since it will do two requests, here and in the next route. The ideal thing is that these remain different routes but we lift up the state to a global store which can be accessed by all these.
  const [check, setCheck] = useState(false)
  const {
    loading: isCursorLoading,
    error: cursorError,
    data,
    refetch
  } = useQuery(RETRIEVE_SURVEY_POSITION, {
    variables: {
      email: email?.toLowerCase(),
    },
    skip: !check,
  })

  useEffect(() => {
    if (cursorError) {
      const parsedError = serverToUserErrorDisplay[cursorError.message] ?? cursorError.message
      setError(parsedError)
      setCheck(false)
      return
    }

    if (data) {
    
      if (
        data.retrieveCurrentSurveyPosition?.currentQuestion?.options?.length === 0 &&
        data.retrieveCurrentSurveyPosition?.progress === 1.1 && // to do: change to 1.0 once survey order is permanently changed
        !data.retrieveCurrentSurveyPosition?.isStaff
      ) {
        console.log('error')
        setError('You have already submitted a response for this survey.')
      } else {
        console.log('no error')
        setProgress(data.retrieveCurrentSurveyPosition?.progress)
        navigate({
          pathname: '/survey/seekr',
          search: createSearchParams({ email: email ?? '' }).toString(),
        })
      }
      setCheck(false)
    }
  }, [data, cursorError, email, navigate, setProgress, setError, setCheck])

  const onBeginSurvey = () => {
    const parsed = string().email().safeParse(email)
    if (!parsed.success) {
      setError('Invalid email')
      return
    }
    setCheck(true)
  }

  return (
    <>
      <Header />
      <Flex direction={'column'} width={'100vw'} minH={'80vh'} align={'center'} justify={'center'}>
        <SeekrSurveyLogo />
        <Center textAlign={'center'} maxW={'525px'}>
          <Flex
            as="form"
            direction={'column'}
            justify="center"
            align="center"
            onSubmit={(e) => {
              e.preventDefault()
              onBeginSurvey()
            }}
          >
            <Container>
              <Text color={'#FFFFFF'}>
                Enter the email associated with your account to continue
              </Text>
            </Container>

            <Box py={2} w="full">
              <TextInput
                value={email ?? ''}
                onChange={(e) => {
                  setError(null)
                  setEmail(e.target.value)
                }}
                data-testid="email-input"
                placeholder="Enter email address..."
              />
            </Box>
            {error && (
              <Text data-testid="begin-error-msg" alignSelf={'flex-start'} mt={'10px'} color="red">
                {error}
              </Text>
            )}

            <Button
              w={'500px'}
              variant="solid"
              bg={'brand.blue'}
              color={'white'}
              title={'Begin Seekr Survey'}
              mt={'10px'}
              onClick={onBeginSurvey}
              isDisabled={!email || Boolean(error)}
              data-testid="begin-survey-btn"
              isLoading={isCursorLoading}
              disabled={isCursorLoading}
            >
              Begin Seekr Survey
            </Button>
          </Flex>
        </Center>
      </Flex>
    </>
  )
}
