import React, { useRef, useState } from 'react'
import {
  Box,
  Accordion,
  Text,
  Image,
  Grid,
  GridItem,
  Flex,
  SimpleGrid,
  Center,
} from '@chakra-ui/react'
import arrowVertical from 'src/assets/images/arrowVertical.svg'
import arrowHorizontal from 'src/assets/images/arrowHorizontal.svg'
import FileFolderAccordion from '../FileFolderAccordion'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import { Data, ReportData } from 'src/utils/types'
import { GET_SOCIAL_CAPITAL_DATA } from 'src/utils/queries'

const networkTitles = [
  'Managers',
  'Recruiters',
  'Coworkers',
  'Professors',
  'Alumni',
  'Classmates',
  'Family',
  'Neighbors',
  'Friends',
]

export default function SocialCapital({ reportData }: Data) {
  let { responseId } = useParams()
  const [socialCapitalData, setSocialCapitalData] = useState<ReportData>()
  const { loading } = useQuery(GET_SOCIAL_CAPITAL_DATA, {
    variables: { responseId: responseId },
    onCompleted: (data: any) => {
      setSocialCapitalData(data.reportData)
    },
    onError: (error: any) => {
      console.error(error)
    },
  })

  const accordElem = useRef(null as any)
  function scrollToAccordion() {
    accordElem?.current?.scrollIntoView({ behavior: 'smooth' })
  }
  return (
    <Box mt={5}>
      <Accordion ref={accordElem} onChange={scrollToAccordion} defaultIndex={0}>
        <FileFolderAccordion title="Who is in my network?" page="Social Capital">
          <Flex mb={'20px'}>
            <Text fontSize={20} fontWeight={'bold'} pr={2}>
              Who is in my network?
            </Text>
          </Flex>

          <Text>{socialCapitalData?.CompositionText}</Text>
          <Grid mt={'10%'} mb={'150px'} templateColumns={'100%'} templateRows={'270px'}>
            <Grid
              justifyItems={'center'}
              gridArea={'1 / 1 / 2 / 2'}
              mt={'40px'}
              mb={'10px'}
              ml={'15%'}
              rowGap={'105px'}
              columnGap={8}
              templateColumns={'repeat(3, 22%)'}
              templateRows={'repeat(3, 25px)'}
            >
              {networkTitles?.map((title, i) =>
                socialCapitalData?.ConnectionList?.replace(/\s*,\s*/g, ',')
                  .split(',')
                  .find((item) => item.includes(title)) ? (
                  <Flex
                    p={'8px'}
                    w={'fit-content'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    color="#F6C645DB"
                    borderRadius="md"
                    borderWidth={'1px'}
                    borderColor={'#7E76DDCC'}
                    backgroundColor={'rgba(126, 118, 221, 0.2)'}
                  >
                    <Text>{title}</Text>
                  </Flex>
                ) : (
                  <Flex
                    p={'8px'}
                    w={'fit-content'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    color="rgba(126, 118, 221, 0.6)"
                    borderRadius="md"
                    borderWidth={'1px'}
                    borderColor={' rgba(126, 118, 221, 0.6)'}
                  >
                    <Text>{title}</Text>
                  </Flex>
                ),
              )}
            </Grid>

            <Grid gridArea={'1 / 1 / 2 / 2'} templateRows={'390px'}>
              <Grid gridArea={'1 / 1 / 2 / 2'}>
                <Grid
                  whiteSpace={'nowrap'}
                  alignItems={'center'}
                  templateColumns={'50px auto 50px'}
                >
                  <GridItem>
                    <Text>SENIOR</Text>
                  </GridItem>
                  <Center>
                    <Image w={'90%'} src={arrowHorizontal} />
                  </Center>
                  <GridItem>
                    <Text>PEER</Text>
                  </GridItem>
                </Grid>
              </Grid>
              <Grid
                justifyItems={'center'}
                templateRows={'50px 330px 100px'}
                gridArea={'1 / 1 / 2 / 2'}
              >
                <GridItem>
                  <Text>PROFESSIONAL</Text>
                </GridItem>
                <GridItem>
                  <Image transform={'scale(1, 1.2) '} src={arrowVertical} />
                </GridItem>
                <GridItem>
                  <Text>PERSONAL</Text>
                </GridItem>
              </Grid>

              <SimpleGrid gridArea={'1 / 1 / 2 / 2'} spacingY={'0'} columns={2}>
                <Flex
                  role="group"
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}
                  _hover={{
                    backgroundColor: '#7E76DD99',
                  }}
                  height="195px"
                >
                  <Box
                    position={'relative'}
                    top={'70px'}
                    right={'10px'}
                    display={'none'}
                    _groupHover={{
                      display: 'block',
                    }}
                    color={'black'}
                    w={'200px'}
                    height={'75px'}
                    backgroundColor={'#F6C645BF'}
                  >
                    <Text>This part of your network is most useful in a first job search.</Text>
                  </Box>
                </Flex>
                <Flex
                  role="group"
                  justifyContent={'flex-end'}
                  alignItems={'flex-start'}
                  _hover={{
                    backgroundColor: '#7E76DD99',
                  }}
                  height="195px"
                >
                  <Box
                    position={'relative'}
                    top={'70px'}
                    left={'10px'}
                    display={'none'}
                    _groupHover={{
                      display: 'block',
                    }}
                    color={'black'}
                    w={'200px'}
                    backgroundColor={'#F6C645BF'}
                  >
                    <Text>
                      This part of your network will be most useful in future job searches.
                    </Text>
                  </Box>
                </Flex>
                <Flex
                  role="group"
                  justifyContent={'flex-start'}
                  alignItems={'flex-end'}
                  _hover={{
                    backgroundColor: '#7E76DD99',
                  }}
                  height="195px"
                >
                  <Box
                    position={'relative'}
                    top={'40px'}
                    right={'10px'}
                    display={'none'}
                    _groupHover={{
                      display: 'block',
                    }}
                    color={'black'}
                    w={'200px'}
                    backgroundColor={'#F6C645BF'}
                  >
                    <Text>
                      This part of your network is most useful in providing wisdom for touch
                      decisions.
                    </Text>
                  </Box>
                </Flex>
                <Flex
                  role="group"
                  justifyContent={'flex-end'}
                  alignItems={'flex-end'}
                  _hover={{
                    backgroundColor: '#7E76DD99',
                  }}
                  height="195px"
                >
                  <Box
                    position={'relative'}
                    top={'30px'}
                    left={'10px'}
                    display={'none'}
                    _groupHover={{
                      display: 'block',
                    }}
                    color={'black'}
                    backgroundColor={'#F6C645BF'}
                  >
                    <Text>
                      This part of your network is most useful in keeping work life balance in the
                      job.
                    </Text>
                  </Box>
                </Flex>
              </SimpleGrid>
            </Grid>
          </Grid>

          <Text textAlign={{ base: 'left', md: 'center' }} fontWeight="bold" mt={5}>
            CLICK ON THE SECTIONS BELOW TO LEARN MORE
          </Text>
        </FileFolderAccordion>
        <FileFolderAccordion title="How do I activate my network?" page="Social Capital">
          <Flex mb={'20px'}>
            <Text fontSize={20} fontWeight={'bold'} pr={2}>
              How do I activate my network?
            </Text>
          </Flex>
          <Text mb={'5'}>{socialCapitalData?.ActivatingNetworkText}</Text>
        </FileFolderAccordion>
        <FileFolderAccordion title="How do I expand my network?" page="Social Capital">
          <Flex mb={'20px'}>
            <Text fontSize={20} fontWeight={'bold'} pr={2}>
              How do I expand my network?
            </Text>
          </Flex>
          <Text mb={'5'}>{socialCapitalData?.BuildingNetworkText}</Text>
        </FileFolderAccordion>
      </Accordion>
    </Box>
  )
}
