import { createApi } from '@thinknimble/tn-models'
import { axiosInstance } from '../axios-instance'
import { JobActivityShape } from './models'
import { z } from 'zod'

export const jobActivityApi = createApi({
  client: axiosInstance,
  baseUri: 'employment/job-activity/',
  models: {
    entity: JobActivityShape,
    extraFilters: {
      dateApplied__isnull: z.boolean(),
      interested: z.boolean(),
      job__active: z.boolean(),
    },
  },
})
