export const milestoneDescriptions = {
  Clarity:
    'A job seeker with Clarity feels confident in finding jobs that interest them. They know about a wide variety of jobs and can explain their interests.',
  Alignment:
    "Alignment is the key to discovering the job that's perfect for you. It's all about finding that sweet spot where your skills, interests, and aspirations meet.",
  Search:
    'A job seeker with Search is strategic in applying to jobs regularly. They tailor their resume and cover letter, and track their progress in the job search.',
  Advance: 'A job seeker with Advance has regular interviews and gets beyond the first round.',
}

export const jobDescriptions = [
  {
    name: 'Customer Success Representative',
    description:
      'A customer success representative serves as the primary point of contact for a customer throughout their use of a company’s product or service.',
    qualifications: [
      'Interest in customer service and training',
      'Experience in service roles like cashier or barista',
      'Classes in Communications or Business',
      'Strong relationship building skills',
    ],
  },
  {
    name: 'Operations Associate',
    description:
      'An operations associate is responsible for supporting a team with administrative tasks and logistics to ensure that daily activities happen smoothly and efficiently.',
    qualifications: [
      'Interest in organizing virtual or in-person events',
      'Interest in tracking and organizing data',
      'Classes in Communications or Business',
      'Strong problem solving skills',
    ],
  },
  {
    name: 'Software Engineer',
    description:
      'A software engineer is responsible for designing, developing, and maintaining software applications.',
    qualifications: [
      'Classes in Computer Science',
      'Interest in any programming language e.g. Python, Javascript, C++ or Java',
      'At least one internship before graduation',
      'Strong analytical skills',
    ],
  },
  {
    name: 'Data Analyst',
    description:
      'A data analyst is responsible for interpreting large amounts of data to help organizations make informed business decisions.',
    qualifications: [
      'Classes in Statistics or Business',
      'Interest in Excel, Tableau, PowerBI',
      'Interest in SQL, R, or Python',
      'Strong attention to detail',
    ],
  },
  {
    name: 'Business Analyst',
    description:
      'A business analyst is responsible for analyzing an organization’s operations and systems in order to find ways to improve efficiency.',
    qualifications: [
      'Interest in Excel or Google Sheets',
      'Interest in Tableau or PowerBI',
      'Classes in Business or Economics',
      'Strong analytical skills',
    ],
  },
  {
    name: 'Social Marketing Coordinator',
    description:
      'A social marketing coordinator is responsible for supporting an organization in developing their brand and increasing an audience’s awareness of the organization’s products and services.',
    qualifications: [
      'Classes in Communications',
      'Interest in Canva, Photoshop, or Illustrator',
      'Interest in managing social media accounts',
      'Strong writing skills',
    ],
  },
  {
    name: 'Lab Research Technician',
    description:
      'A lab research technician is responsible for supporting a research group in conducting experiments in a laboratory setting.',
    qualifications: [
      'Classes in Biology or Chemistry',
      'Interest in any campus research lab',
      'Interest in lab techniques and experimental design',
      'Strong critical thinking skills',
    ],
  },
  {
    name: 'Accounting Associate',
    description:
      'An accounting associate is responsible for ensuring accurate financial management and maintaining a complete and accurate general ledger.',
    qualifications: [
      'Interest in budgeting or financial planning',
      'Interest in Excel, Quickbooks, or similar software',
      'Classes in Business, Economics, or Accounting',
      'Strong attention to detail',
    ],
  },
  {
    name: 'Editorial Assistant',
    description:
      'An editorial assistant provides support to an editorial team in publishing books, magazines, or other written materials.',
    qualifications: [
      'Classes in Communications',
      'Interest in Canva, Photoshop, or Illustrator',
      'Interest in managing social media accounts',
      'Strong writing skills',
    ],
  },
  {
    name: 'Community Organizer',
    description:
      'A community organizer works to bring people together to address issues affecting a neighborhood or community and advocate for solutions. ',
    qualifications: [
      'Interest in election or legislative campaigns',
      'Interest in working with underserved populations',
      'Classes in Political Science or Economics',
      'Strong relationship building skills',
    ],
  },
]

export const eduStatusOptions = [
  'Graduate - I have my degree',
  'Soon to Graduate - I will graduate in less than a year',
  'About One Year Left - I am in my second to last year',
  'Early College - I have 2 or more years ahead before I graduate',
]

export const activityLevelOptions = [
  'Active - I am currently looking for a job or internship',
  'Preparing - I expect to star my job or internship search in the next few months',
  'Not Prioritizing Yet - I am planning to focus on my job search much later',
]

export const deviceOptions = [
  'Laptop or desktop computer',
  'Tablet (e.g., iPad, Kindle Fire)',
  'Mobile device (e.g., iPhone, Galaxy)',
]

export const surveyPrograms = {
  discovery: 'discovery',
  fellowship: 'fellowship',
  demo: 'demo',
}
